import { useState, useCallback, useContext } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../context/useUserContext'

export const useEventManager = () => {
   const { user } = useContext(UserContext)
   const [events, setEvents] = useState([])
   const [isEventVisible, setIsEventVisible] = useState(true)
   const [editingEvent, setEditingEvent] = useState(null)
   const [isEventModalOpen, setIsEventModalOpen] = useState(false)

   const fetchEvents = useCallback(async () => {
      if (!user) {
         console.error('User is null, unable to fetch events')
         return
      }

      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}eventRoutes/${user.id}`
         )
         setEvents(response.data.events)
      } catch (error) {
         toast.error('Error fetching events.')
         console.error('Fetch events error:', error)
      }
   }, [user])

   const handleEditEvent = (eventId) => {
      const event = events.find((e) => e._id === eventId)
      if (event) {
         setEditingEvent(event)
         setIsEventModalOpen(true)
      } else {
         console.error('Event not found:', eventId)
      }
   }

   const handleDeleteEvent = async (eventId) => {
      if (!user) {
         console.error('User is null, unable to delete event')
         return
      }

      if (window.confirm('Are you sure you want to delete this event?')) {
         try {
            await axios.delete(
               `${process.env.REACT_APP_API_URL}eventRoutes/${eventId}`,
               {
                  headers: {
                     Authorization: `Bearer ${user.accessToken}`,
                  },
               }
            )
            fetchEvents()
            toast.success('Event successfully deleted.')
         } catch (error) {
            toast.error('Failed to delete event.')
            console.error('Delete event error:', error)
         }
      }
   }

   const handleSaveEvent = async (formData) => {
      if (!user) {
         console.error('User is null, unable to save event')
         return
      }

      if (!validateEventData(formData)) {
         toast.error('Please fill all required fields before submitting.')
         return
      }

      try {
         const method = formData.get('_id') ? 'put' : 'post'
         const url = formData.get('_id')
            ? `${process.env.REACT_APP_API_URL}/eventRoutes/${formData.get(
                 '_id'
              )}`
            : `${process.env.REACT_APP_API_URL}/eventRoutes/`

         await axios[method](url, formData, {
            headers: {
               Authorization: `Bearer ${user.accessToken}`,
               // Let the browser set the Content-Type header
            },
         })
         fetchEvents()
         toast.success(
            `Event ${formData.get('_id') ? 'updated' : 'created'} successfully.`
         )
         setIsEventModalOpen(false)
         setEditingEvent(null)
      } catch (error) {
         toast.error(
            `Failed to ${formData.get('_id') ? 'update' : 'create'} event.`
         )
         console.error('Save event error:', error)
      }
   }

   const validateEventData = (formData) => {
      return formData.get('name') && formData.get('date')
   }

   const toggleEventVisibility = async () => {
      if (!user) {
         console.error('User is null, unable to toggle event visibility')
         return
      }

      try {
         const newVisibility = !isEventVisible
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`,
            {
               contentVisibility: { events: newVisibility },
            }
         )
         setIsEventVisible(newVisibility)
         toast.success('Event visibility updated successfully.')
      } catch (error) {
         toast.error('Failed to update event visibility.')
         console.error('Toggle event visibility error:', error)
      }
   }

   return {
      events,
      isEventVisible,
      editingEvent,
      isEventModalOpen,
      fetchEvents,
      handleEditEvent,
      handleDeleteEvent,
      handleSaveEvent,
      toggleEventVisibility,
      setIsEventModalOpen,
      setEditingEvent,
   }
}
