import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'

function DisposableIncomeInput({ onSetIncome }) {
   const [income, setIncome] = useState('')

   const handleIncomeChange = (e) => {
      const value = e.target.value

      // Validate input to prevent negative numbers
      if (value >= 0) {
         setIncome(value)
         if (onSetIncome) {
            onSetIncome(parseFloat(value)) // Make sure the value is numeric
         }
      } else {
         alert('Income cannot be negative!')
      }
   }

   return (
      <div>
         <h3>Dinheiro Disponível:</h3>
         <InputText
            type="number"
            value={income}
            placeholder="Preencha o valor (€)"
            onChange={handleIncomeChange}
         />
      </div>
   )
}

export default DisposableIncomeInput
