import css from './Banner.module.css'
import React from 'react'

function Banner() {
   return (
      <header className={css.banner}>
         <div className={css.bannerFadeBottom} />
      </header>
   )
}

export default Banner
