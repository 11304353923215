// DebtUtils.js

// Function to rank debts based on the selected method (Snowball or Avalanche)
export const rankDebts = (debts, method) => {
   const sortedDebts = JSON.parse(JSON.stringify(debts))

   if (method === 'avalanche') {
      sortedDebts.sort((a, b) => (b.interestRate || 0) - (a.interestRate || 0))
   } else {
      sortedDebts.sort(
         (a, b) => (a.remainingBalance || 0) - (b.remainingBalance || 0)
      )
   }
   return sortedDebts
}

// Function to simulate amortization using only `disposableIncome`
export const simulateAmortization = (debts, disposableIncome = 0) => {
   const debtsClone = JSON.parse(JSON.stringify(debts))
   let months = 0
   let totalInterestPaid = 0
   let monthlyBreakdown = []
   let fractionalMonthAdjustment = 0 // New variable to track any fractional month needed

   // Track any additional budget freed up after debts are paid off
   let freedUpMonthlyPayments = 0

   const paymentDetailsPerDebt = debtsClone.map((debt) => ({
      id: debt.id,
      name: debt.name,
      totalPrincipalPaid: 0,
      totalInterestPaid: 0,
      totalPayments: 0,
      originalBalance: debt.originalBalance || debt.amount || 0,
      balance: debt.balance || debt.remainingBalance || debt.amount || 0,
      interestRate: debt.interestRate || 0,
      progress: 0,
      monthsToPayOff: 0,
      monthlyPayment: debt.payment || 0, // Keep track of each debt's monthly payment
   }))

   const maxMonths = 1500

   // Simulation loop
   while (debtsClone.some((debt) => debt.balance > 0) && months < maxMonths) {
      months++
      let monthDetail = []

      // Sort debts by strategy (smallest balance first for Snowball)
      debtsClone.sort((a, b) => a.balance - b.balance)

      // Keep track of any remaining disposable income not used for minimum payments
      let remainingDisposableIncome = disposableIncome + freedUpMonthlyPayments

      // Apply minimum payments for each debt
      debtsClone.forEach((debt, index) => {
         if (debt.balance > 0) {
            const monthlyInterest =
               (debt.balance * debt.interestRate) / 100 / 12
            const minimumPayment = debt.payment || 0
            let payment = minimumPayment

            if (remainingDisposableIncome > 0) {
               let usedIncome = Math.min(
                  remainingDisposableIncome,
                  debt.balance + monthlyInterest - minimumPayment
               )
               payment += usedIncome
               remainingDisposableIncome -= usedIncome
               console.log(
                  `Debt: ${debt.name}, After applying disposable income: Payment=${payment}, Used Income=${usedIncome}, Remaining=${remainingDisposableIncome}`
               )
            }

            const actualPayment = Math.min(
               payment,
               debt.balance + monthlyInterest
            )
            const principalPayment = Math.max(
               actualPayment - monthlyInterest,
               0
            )

            // Deduct the principal from the balance without rounding too early
            if (principalPayment > 0) {
               debt.balance -= principalPayment
               totalInterestPaid += monthlyInterest

               paymentDetailsPerDebt[index].totalPrincipalPaid +=
                  principalPayment
               paymentDetailsPerDebt[index].totalInterestPaid += monthlyInterest
               paymentDetailsPerDebt[index].totalPayments += actualPayment
               paymentDetailsPerDebt[index].balance = debt.balance

               if (
                  debt.balance <= 0 &&
                  paymentDetailsPerDebt[index].monthsToPayOff === 0
               ) {
                  paymentDetailsPerDebt[index].monthsToPayOff = months
                  freedUpMonthlyPayments +=
                     paymentDetailsPerDebt[index].monthlyPayment
               }
               monthDetail.push({
                  name: debt.name,
                  interestPaid: monthlyInterest.toFixed(2),
                  principalPaid: principalPayment.toFixed(2),
                  balance: debt.balance.toFixed(2),
               })
            }
         }
      })

      monthlyBreakdown.push({
         month: months,
         details: monthDetail,
      })
   }

   // Final step to calculate fractional month adjustment if needed
   debtsClone.forEach((debt) => {
      if (debt.balance > 0 && debt.payment > 0) {
         // Calculate the fractional part of the month required to pay off the remaining balance
         fractionalMonthAdjustment = debt.balance / debt.payment
      }
   })

   return {
      simulatedDebts: debtsClone,
      paymentDetailsPerDebt,
      months: Math.min(months + fractionalMonthAdjustment, maxMonths),
      totalInterestPaid,
      monthlyBreakdown,
   }
}

// Function to simulate amortization using only minimum payments
export const simulateMinimumPaymentAmortization = (debts) => {
   const debtsClone = JSON.parse(JSON.stringify(debts))
   let months = 0
   let totalInterestPaid = 0
   let monthlyBreakdown = []

   const paymentDetailsPerDebt = debtsClone.map((debt) => ({
      id: debt.id,
      name: debt.name,
      totalPrincipalPaid: 0,
      totalInterestPaid: 0,
      totalPayments: 0,
      originalBalance: debt.originalBalance || debt.amount || 0,
      balance: debt.balance || debt.remainingBalance || debt.amount || 0,
      interestRate: debt.interestRate || 0,
      progress: 0,
      normalMonthsToPayOff: 0, // Initialize for normal months calculation
   }))

   const maxMonths = 1200

   while (debtsClone.some((debt) => debt.balance > 0) && months < maxMonths) {
      months++
      let monthDetail = []

      debtsClone.forEach((debt, index) => {
         if (debt.balance > 0) {
            const monthlyInterest =
               (debt.balance * debt.interestRate) / 100 / 12
            const minimumPayment = debt.payment || 0

            if (monthlyInterest > minimumPayment) {
               throw new Error(
                  `Error: Minimum payment is less than the interest for debt ${debt.name}. This will result in an ever-increasing balance.`
               )
            }

            const payment = Math.min(
               minimumPayment,
               debt.balance + monthlyInterest
            )
            const principalPayment = Math.max(payment - monthlyInterest, 0)

            if (principalPayment >= debt.balance) {
               debt.balance = 0
            } else {
               debt.balance -= principalPayment
            }

            totalInterestPaid += monthlyInterest

            paymentDetailsPerDebt[index].totalPrincipalPaid += principalPayment
            paymentDetailsPerDebt[index].totalInterestPaid += monthlyInterest
            paymentDetailsPerDebt[index].totalPayments += payment
            paymentDetailsPerDebt[index].balance = debt.balance

            if (
               debt.balance <= 0 &&
               paymentDetailsPerDebt[index].normalMonthsToPayOff === 0
            ) {
               paymentDetailsPerDebt[index].normalMonthsToPayOff = months
            }

            monthDetail.push({
               name: debt.name,
               interestPaid: monthlyInterest.toFixed(2),
               principalPaid: principalPayment.toFixed(2),
               balance: debt.balance.toFixed(2),
            })
         }
      })

      monthlyBreakdown.push({
         month: months,
         details: monthDetail,
      })
   }

   return {
      simulatedDebts: debtsClone,
      paymentDetailsPerDebt,
      months: Math.min(months, maxMonths),
      totalInterestPaid,
      monthlyBreakdown,
   }
}
