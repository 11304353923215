import React, { useState, useMemo, useEffect, useContext } from 'react'
import { Line, Bar } from 'react-chartjs-2'
import { Row, Col } from 'reactstrap'
import { Button } from 'primereact/button'
import { NavLink } from 'react-router-dom'
import IncomeInputGroup from './components/incomeFormComponents/IncomeInputGroup'
import IncomeComparison from './components/incomeFormComponents/IncomeComparison'
import styles from './IncomeForm.module.css'
import { useIncome } from '../../../../../../../context/IncomeContext'
import {
   getBarChartOptions,
   getLineChartOptions,
} from './components/incomeFormComponents/chartConfig'
import MonthTabs from './components/incomeFormComponents/MonthTabs'
import axios from 'axios'
import { UserContext } from '../../../../../../../context/useUserContext'

const months = [
   'Janeiro',
   'Fevereiro',
   'Março',
   'Abril',
   'Maio',
   'Junho',
   'Julho',
   'Agosto',
   'Setembro',
   'Outubro',
   'Novembro',
   'Dezembro',
]

function IncomeForm() {
   const {
      incomes,
      categories,
      notes,
      handleSave,
      handleDelete,
      handleCategoryChange,
      setNotes,
      submissionMessage,
      errorMessage,
      isEditing,
      setErrorMessage,
      setIncomes,
      handleChange,
   } = useIncome()

   const { user } = useContext(UserContext)
   const userId = user.id
   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())

   useEffect(() => {
      const fetchCategories = async () => {
         try {
            const { data } = await axios.get(
               `${process.env.REACT_APP_API_URL}users/${userId}/income-categories`
            )
            if (data && data.length > 0) {
               handleCategoryChange(data) // Populate categories with fetched data
            }
         } catch (error) {
            console.error('Failed to fetch categories:', error)
         }
      }

      fetchCategories()
   }, [userId, handleCategoryChange])

   const parseIncome = (value) =>
      parseFloat(value?.toString().replace(',', '.')) || 0

   const incomeKeys = [
      'primary',
      'secondary',
      'cashback',
      'referralBonuses',
      'interest',
      'dividends',
      'healthReimbursements',
      'giftsReceived',
      'saleOfUsedItems',
      'rentalIncome',
      'royalties',
      'otherIncome',
   ]

   const incomeLabels = {
      primary: 'Salário',
      secondary: 'Side Hustle',
      cashback: 'Cashback',
      referralBonuses: 'Bónus de referrals',
      interest: 'Juros',
      dividends: 'Dividendos',
      healthReimbursements: 'Reembolsos saúde',
      giftsReceived: 'Presentes recebidos',
      saleOfUsedItems: 'Venda artigos usados',
      rentalIncome: 'Rendas de imóveis',
      royalties: 'Royalties',
      otherIncome: 'Outros rendimentos',
   }

   const filteredIncomeKeys = incomeKeys.filter((key) =>
      incomes.some((income) => parseIncome(income?.[key]) > 0)
   )

   const totalIncome = useMemo(() => {
      return incomes.reduce((total, income) => {
         if (!income) return total
         return (
            total +
            incomeKeys.reduce((monthTotal, key) => {
               const value = parseIncome(income[key])
               return monthTotal + (isNaN(value) ? 0 : value)
            }, 0)
         )
      }, 0)
   }, [incomes])

   const averageMonthlyIncome = useMemo(() => totalIncome / 12, [totalIncome])

   const totalIncomeForSelectedMonth = useMemo(() => {
      const selectedIncome = incomes[selectedMonth] || {}
      return incomeKeys.reduce((total, key) => {
         const value = parseIncome(selectedIncome[key])
         return total + (isNaN(value) ? 0 : value)
      }, 0)
   }, [incomes, selectedMonth])

   const lineChartData = useMemo(
      () => ({
         labels: months.slice(0, new Date().getMonth() + 1),
         datasets: [
            {
               label: 'Total Income Trend',
               data: incomes.map((income) => {
                  if (!income) return 0
                  return filteredIncomeKeys.reduce((total, key) => {
                     const value = parseIncome(income[key])
                     return total + (isNaN(value) ? 0 : value)
                  }, 0)
               }),
               fill: false,
               borderColor: '#36A2EB',
               tension: 0.1,
            },
            ...filteredIncomeKeys.map((key) => ({
               label: incomeLabels[key],
               data: incomes.map((income) => parseIncome(income?.[key]) || 0),
               fill: false,
               borderColor:
                  '#' + Math.floor(Math.random() * 16777215).toString(16),
               tension: 0.1,
            })),
         ],
      }),
      [incomes]
   )

   const barChartData = useMemo(
      () => ({
         labels: categories.map((category) => category.name),
         datasets: [
            {
               label: 'Income Breakdown',
               data: categories.map(
                  (category) => incomes[selectedMonth]?.[category.id] || 0
               ),
               backgroundColor: [
                  '#FF6384',
                  '#36A2EB',
                  '#FFCE56',
                  '#4BC0C0',
                  '#9966FF',
                  '#FF9F40',
               ],
               borderColor: 'white',
               borderWidth: 1,
            },
         ],
      }),
      [categories, selectedMonth, incomes]
   )

   return (
      <>
         <h2>Preenche os teus Rendimentos</h2>
         <MonthTabs
            currentMonth={new Date().getMonth()}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            months={months}
         />

         <Row style={{ display: 'flex' }}>
            <Col
               xs={6}
               style={{
                  marginTop: '20px',
                  background: '#333',
                  color: '#fff',
                  borderRadius: '15px',
                  padding: '20px',
               }}
            >
               {incomes.length > 0 && (
                  <IncomeComparison
                     incomes={incomes}
                     months={months}
                     selectedMonth={selectedMonth}
                  />
               )}
            </Col>
            <Col xs={1}></Col>
            <Col
               xs={5}
               style={{
                  marginTop: '20px',
                  background: '#333',
                  color: '#fff',
                  borderRadius: '15px',
                  padding: '20px',
               }}
            >
               <div
                  style={{
                     padding: '20px',
                     backgroundColor: '#333',
                     borderRadius: '10px',
                     color: '#fff',
                  }}
               >
                  <h3
                     style={{
                        borderBottom: '2px solid #ccc',
                        paddingBottom: '10px',
                     }}
                  >
                     Resumo dos Rendimentos Anuais
                  </h3>
                  <p style={{ marginTop: '10px' }}>
                     <strong>Total Rendimentos Anual:</strong> €
                     {totalIncome.toFixed(2)}
                  </p>
                  <p>
                     <strong>Média Mensal:</strong> €
                     {averageMonthlyIncome.toFixed(2)}
                  </p>
                  <p>
                     <strong>Total Rendimentos Mês Selecionado:</strong> €
                     {totalIncomeForSelectedMonth.toFixed(2)}
                  </p>
                  <Button
                     onClick={() => handleDelete(selectedMonth)}
                     label="Limpar registo"
                     className="p-button-danger"
                     size="small"
                     style={{ marginTop: '10px' }}
                  />
               </div>
            </Col>
         </Row>
         {submissionMessage && (
            <p className={styles.submissionMessage}>{submissionMessage}</p>
         )}
         {errorMessage && (
            <p style={{ color: 'red', marginBottom: '20px' }}>{errorMessage}</p>
         )}

         <div
            style={{
               display: 'flex',
               marginTop: '10px',
               marginBottom: '10px',
            }}
         >
            <NavLink
               to="/dashboard/regular/financialHealth/budget/income"
               style={({ isActive }) => ({
                  padding: '10px 15px',
                  borderRadius: '5px',
                  textDecoration: 'none',
                  color: isActive ? 'black' : 'white',
                  backgroundColor: isActive ? 'white' : 'transparent',
                  borderBottom: isActive ? '2px solid white' : 'none',
                  marginRight: '10px',
               })}
            >
               Rendimentos
            </NavLink>

            <NavLink
               to="/dashboard/regular/financialHealth/budget/income/categories"
               style={({ isActive }) => ({
                  padding: '10px 15px',
                  borderRadius: '5px',
                  textDecoration: 'none',
                  color: isActive ? 'black' : 'white',
                  backgroundColor: isActive ? 'white' : 'transparent',
                  borderBottom: isActive ? '2px solid white' : 'none',
                  marginRight: '10px',
               })}
            >
               Gerir Categorias
            </NavLink>
         </div>
         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: '1', marginRight: '20px' }}>
               {categories.length > 0
                  ? categories.map((category) => (
                       <IncomeInputGroup
                          key={category.id}
                          label={category.name}
                          name={category.id}
                          value={incomes[selectedMonth]?.[category.id] || ''}
                          onChange={(e) => handleChange(e, selectedMonth)}
                       />
                    ))
                  : incomeKeys.map((key) => (
                       <IncomeInputGroup
                          key={key}
                          label={incomeLabels[key]}
                          name={key}
                          value={incomes[selectedMonth]?.[key] || ''}
                          onChange={(e) => handleChange(e, selectedMonth)}
                       />
                    ))}
            </div>
            <div
               style={{
                  flex: '2',
                  display: 'flex',
                  flexDirection: 'column',
               }}
            >
               <div style={{ marginBottom: '20px' }}>
                  <h3 style={{ color: 'white' }}>Tendência dos Rendimentos</h3>
                  <div style={{ width: '100%', height: '400px' }}>
                     <Line
                        data={lineChartData}
                        options={getLineChartOptions({
                           maintainAspectRatio: false,
                           aspectRatio: 2,
                        })}
                     />
                  </div>
               </div>
               <div>
                  <h3 style={{ color: 'white' }}>
                     Discriminação dos Rendimentos
                  </h3>
                  <div style={{ width: '100%', height: '400px' }}>
                     <Bar
                        data={barChartData}
                        options={getBarChartOptions({
                           maintainAspectRatio: false,
                           aspectRatio: 2,
                        })}
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className={styles.notesSection}>
            <h3>Notas para o Mês</h3>
            <textarea
               value={notes[selectedMonth]}
               onChange={(e) => {
                  const updatedNotes = [...notes]
                  updatedNotes[selectedMonth] = e.target.value
                  setNotes(updatedNotes)
               }}
               rows={6}
               placeholder="Adicione notas sobre os rendimentos deste mês..."
               style={{
                  width: '20%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  boxSizing: 'border-box',
                  fontSize: '1rem',
               }}
            />
         </div>

         <Button
            onClick={() => handleSave(selectedMonth)}
            size="small"
            style={{ marginTop: '10px' }}
         >
            {isEditing ? 'Update' : 'Save'}
         </Button>
         {isEditing && (
            <Button
               size="small"
               onClick={() => handleDelete(selectedMonth)}
               style={{ marginTop: '10px' }}
            >
               Delete
            </Button>
         )}
      </>
   )
}

export default IncomeForm
