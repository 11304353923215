import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import css from './MyEditor.module.css'
import axios from 'axios'

function MyEditor({ editorState, onContentChange }) {
   const handleEditorChange = (newEditorState) => {
      onContentChange(newEditorState)
   }
   const uploadImageCallBack = (file) => {
      return new Promise((resolve, reject) => {
         const formData = new FormData()
         formData.append('image', file)

         // prettier-ignore
         axios.post(`${process.env.REACT_APP_API_URL}content/api/upload`, formData)
             .then((response) => {
         
                 resolve({ data: { link: response.data.url } })
             
        })
        .catch((error) => {
          reject(error)
        })
      })
   }

   return (
      <Editor
         editorState={editorState}
         onEditorStateChange={handleEditorChange}
         wrapperClassName="wrapper-class"
         editorClassName={css.editor}
         toolbarClassName="toolbar-class"
         toolbar={{
            image: {
               uploadCallback: uploadImageCallBack,
               alt: { present: true, mandatory: false },
            },
         }}
      />
   )
}
export default MyEditor
