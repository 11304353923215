import React from 'react'
import { NavLink } from 'react-router-dom'

const ExpensesNav = () => {
   return (
      <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
         <NavLink
            to="/dashboard/regular/financialHealth/budget/expenses/visualizarDespesas"
            style={({ isActive }) => ({
               padding: '10px 15px',
               borderRadius: '5px',
               textDecoration: 'none',
               color: isActive ? 'black' : 'white',
               backgroundColor: isActive ? 'white' : 'transparent',
               borderBottom: isActive ? '2px solid white' : 'none',
               marginRight: '10px',
            })}
         >
            Visualizar Despesas
         </NavLink>
         <NavLink
            to="/dashboard/regular/financialHealth/budget/expenses"
            style={({ isActive }) => ({
               padding: '10px 15px',
               borderRadius: '5px',
               textDecoration: 'none',
               color: isActive ? 'black' : 'white',
               backgroundColor: isActive ? 'white' : 'transparent',
               borderBottom: isActive ? '2px solid white' : 'none',
               marginRight: '10px',
            })}
         >
            Listar Despesas
         </NavLink>

         <NavLink
            to="/dashboard/regular/financialHealth/budget/expenses/categories"
            style={({ isActive }) => ({
               padding: '10px 15px',
               borderRadius: '5px',
               textDecoration: 'none',
               color: isActive ? 'black' : 'white',
               backgroundColor: isActive ? 'white' : 'transparent',
               borderBottom: isActive ? '2px solid white' : 'none',
               marginRight: '10px',
            })}
         >
            Gerir Categorias de Despesas
         </NavLink>

         <NavLink
            to="/dashboard/regular/financialHealth/budget/expenses/summary"
            style={({ isActive }) => ({
               padding: '10px 15px',
               borderRadius: '5px',
               textDecoration: 'none',
               color: isActive ? 'black' : 'white',
               backgroundColor: isActive ? 'white' : 'transparent',
               borderBottom: isActive ? '2px solid white' : 'none',
               marginRight: '10px',
            })}
         >
            Resumo de Despesas
         </NavLink>

         {/* <NavLink
            to="/dashboard/regular/financialHealth/budget/expenses/simuladorDespesas"
            style={({ isActive }) => ({
               padding: '10px 15px',
               borderRadius: '5px',
               textDecoration: 'none',
               color: isActive ? 'black' : 'white',
               backgroundColor: isActive ? 'white' : 'transparent',
               borderBottom: isActive ? '2px solid white' : 'none',
               marginRight: '10px',
            })}
         >
            Simulador de Despesas
         </NavLink> */}
      </div>
   )
}

export default ExpensesNav
