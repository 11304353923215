import React, { useContext } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../context/useUserContext'
import { handleError } from '../../../../../utils/handleError'

const ArticleVisibilityToggle = ({ isVisible, setIsVisible }) => {
   const { user } = useContext(UserContext)

   const toggleVisibility = async () => {
      const newVisibility = !isVisible
      try {
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`,
            { contentVisibility: { articles: newVisibility } },
            { headers: { Authorization: `Bearer ${user.accessToken}` } }
         )
         setIsVisible(newVisibility)
         toast.success('Visibility of articles has been successfully updated!')
      } catch (error) {
         handleError(error)
         toast.error('Failed to update article visibility.')
         setIsVisible(isVisible)
      }
   }

   return (
      <div>
         <label>
            Show Articles:
            <input
               type="checkbox"
               checked={isVisible}
               onChange={toggleVisibility}
            />
         </label>
      </div>
   )
}

export default ArticleVisibilityToggle
