import React from 'react'
import css from '../CoursesPage.module.css'
import EventCard from './EventCard'

const EventList = ({ events, handleEventClick }) => {
   if (!events || events.length === 0) {
      return <div className={css.emptyState}>Nenhum evento disponível.</div>
   }

   return (
      <div className={css.courseContainer}>
         {events.map((event) => (
            <EventCard
               key={event._id}
               event={event}
               onClick={() => handleEventClick(event)}
            />
         ))}
      </div>
   )
}

export default EventList
