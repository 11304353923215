import React from 'react'
import css from '../CoursesPage.module.css'

const EventCard = ({ event, onClick }) => {
   // Format the event date and time
   const formattedDate = new Date(event.date).toLocaleDateString('pt-PT', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
   })

   const formattedStartTime = new Date(`1970-01-01T${event.startTime}:00Z`)
      .toLocaleTimeString('pt-PT', {
         hour: '2-digit',
         minute: '2-digit',
      })
      .replace(':00', '')

   return (
      <div className={css.courseCard} onClick={onClick}>
         {/* Event Banner */}
         <img
            className={css.coursePoster}
            src={event.bannerImage || '/placeholder-image.png'}
            alt={`Banner of ${event.name}`}
         />

         {/* Event Details */}
         <div className={css.courseDetails}>
            <div className={css.courseName}>{event.name}</div>
            <p className={css.eventDate}>
               {formattedDate} às {formattedStartTime}
            </p>
            <p className={css.eventTopic}>
               <strong>Tópico:</strong> {event.topic}
            </p>
         </div>
      </div>
   )
}

export default React.memo(EventCard)
