// MonthTabs.js
import React from 'react'
import styles from '../../IncomeForm.module.css'

const MonthTabs = ({
   currentMonth,
   selectedMonth,
   setSelectedMonth,
   months,
}) => {
   return (
      <div className={styles.monthTabs}>
         {months.slice(0, currentMonth + 1).map((month, i) => (
            <div
               key={i}
               className={selectedMonth === i ? styles.activeTab : styles.tab}
               onClick={() => setSelectedMonth(i)}
            >
               {month}
            </div>
         ))}
      </div>
   )
}

export default MonthTabs
