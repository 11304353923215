import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../context/useUserContext'
import { toast } from 'react-toastify'
import api from '../../../../../utils/api'
import AnnouncementItem from './AnnouncementItem'
import AnnouncementCreator from './AnnouncementCreator'
import { Col } from 'reactstrap'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import css from './AnnouncementStyles.module.css'
import { Dialog } from 'primereact/dialog'
import AnnouncementVisibilityToggle from './AnnouncementVisibilityToggle'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import './DialogStyles.css'

const AnnouncementManager = () => {
   const { user, refreshAccessToken } = useContext(UserContext)
   const [announcements, setAnnouncements] = useState([])
   const [isVisible, setIsVisible] = useState(true)
   const [editDialog, setEditDialog] = useState(false)
   const [currentAnnouncement, setCurrentAnnouncement] = useState(null)

   const fetchAnnouncements = async () => {
      try {
         const response = await api.get(`/announcementRoutes/${user.id}`)
         setAnnouncements(response.data)
      } catch (error) {
         if (error.response?.status === 401) {
            const tokenRefreshed = await refreshAccessToken()
            if (tokenRefreshed) fetchAnnouncements()
         } else {
            toast.error('Erro ao carregar anúncios.')
         }
      }
   }

   const handleEdit = (announcement) => {
      setCurrentAnnouncement({ ...announcement })
      setEditDialog(true)
   }

   const saveEditedAnnouncement = async () => {
      try {
         await api.put(`/announcementRoutes/${currentAnnouncement._id}`, {
            text: currentAnnouncement.text,
         })
         toast.success('Anúncio atualizado com sucesso!')
         fetchAnnouncements()
         setEditDialog(false)
      } catch (error) {
         toast.error('Erro ao atualizar o anúncio.')
      }
   }

   const deleteAnnouncement = async (id) => {
      if (!window.confirm('Tem a certeza que quer apagar este anúncio?')) return
      try {
         await api.delete(`/announcementRoutes/${id}`)
         toast.success('Anúncio apagado com sucesso!')
         fetchAnnouncements()
      } catch (error) {
         toast.error('Erro ao apagar o anúncio.')
      }
   }

   useEffect(() => {
      if (user) fetchAnnouncements()
   }, [user])

   return (
      <div className={css.managerContainer}>
         <Col xs={7}>
            <h2 className={css.title}>Gerir Anúncios</h2>
            <AnnouncementVisibilityToggle
               isVisible={isVisible}
               setIsVisible={setIsVisible}
            />
            <h3 className={css.emDestaque}>Em Destaque</h3>
            <div className={css.cardContainer}>
               {announcements.map((announcement, index) => (
                  <AnnouncementItem
                     key={announcement._id || index} // Use _id se disponível, caso contrário, use o índice como fallback
                     announcement={announcement}
                     onEdit={handleEdit}
                     onDelete={() => deleteAnnouncement(announcement._id)}
                  />
               ))}
            </div>
         </Col>

         <Col>
            <h2 className={css.title}>Criar Anúncios</h2>
            <AnnouncementCreator
               fetchAnnouncements={fetchAnnouncements}
               setAnnouncements={setAnnouncements}
               announcements={announcements}
            />
         </Col>
         <Dialog
            header="Editar Anúncio"
            visible={editDialog}
            className={css.dialog}
            onHide={() => setEditDialog(false)}
            dismissableMask
         >
            <div className={css.dialogContent}>
               <Col>
                  <InputTextarea
                     value={currentAnnouncement?.text}
                     onChange={(e) =>
                        setCurrentAnnouncement((prev) => ({
                           ...prev,
                           text: e.target.value,
                        }))
                     }
                     rows={5}
                     cols={30}
                     className={css.textArea}
                  />
               </Col>
               <Col>
                  <Button
                     label="Guardar"
                     icon="pi pi-check"
                     onClick={saveEditedAnnouncement}
                     className="btnEditar"
                  />
               </Col>
            </div>
         </Dialog>
      </div>
   )
}

export default AnnouncementManager
