import React, { useState } from 'react'
import BrokerModal from './BrokerModal'
import './ShowBrokers.css' // Usaremos estilos globais

const BrokerCard = ({ broker }) => {
   const [visible, setVisible] = useState(false)

   const toggleModal = () => {
      setVisible(!visible)
   }

   return (
      <>
         <div onClick={toggleModal} className="broker-card">
            {broker.logo && (
               <img
                  src={broker.logo}
                  alt={broker.name}
                  className="broker-logo"
               />
            )}
            <h3 className="broker-name">{broker.nome}</h3>
         </div>
         <BrokerModal
            visible={visible}
            onModalClose={toggleModal}
            broker={broker}
         />
      </>
   )
}

export default BrokerCard
