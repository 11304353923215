import React from 'react'
import { Line } from 'react-chartjs-2'
import PropTypes from 'prop-types'

const NetWorthChartComponent = ({ data }) => {
   const chartData = {
      labels: data.map((entry) => entry.month),
      datasets: [
         {
            label: 'Net Worth',
            data: data.map((entry) => entry.netWorth),
            borderColor: '#4BC0C0',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
         },
      ],
   }

   const chartOptions = {
      plugins: {
         legend: {
            display: true,
            labels: {
               color: 'white', // Change legend label color to white
            },
         },
      },
      scales: {
         x: {
            ticks: {
               color: 'white', // Change x-axis tick color to white
            },
         },
         y: {
            beginAtZero: true,
            ticks: {
               color: 'white', // Change y-axis tick color to white
               callback: function (value) {
                  return `€${value.toLocaleString()}`
               },
            },
         },
      },
   }

   return (
      <div>
         <h3>Evolução de Networth</h3>
         <Line data={chartData} options={chartOptions} />
      </div>
   )
}

// Define PropTypes for the component
NetWorthChartComponent.propTypes = {
   data: PropTypes.arrayOf(
      PropTypes.shape({
         month: PropTypes.string.isRequired,
         netWorth: PropTypes.number.isRequired,
      })
   ).isRequired,
}

export default NetWorthChartComponent
