import React from 'react'
import { Col } from 'reactstrap'
import css from '../Playlist.module.css'
import axios from 'axios'
import { UserContext } from '../../../../../../context/useUserContext'

const PlaylistVisibilityToggle = ({ isVisible, setIsVisible }) => {
   const { user } = React.useContext(UserContext)
   const toggleVisibility = async () => {
      const newVisibility = !isVisible
      try {
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`,
            { contentVisibility: { playlists: newVisibility } },
            { headers: { Authorization: `Bearer ${user.accessToken}` } }
         )
         setIsVisible(newVisibility) // Atualizar somente após sucesso
      } catch (error) {
         console.error('Erro ao alternar visibilidade:', error)
      }
   }

   return (
      <div className={css.visibilityToggleContainer}>
         <Col sm={9} md={6}>
            Mostrar Playlist:
         </Col>
         <Col md={1}>
            <input
               type="checkbox"
               className={css.checkbox}
               checked={isVisible}
               onChange={toggleVisibility}
            />
         </Col>
      </div>
   )
}

export default PlaylistVisibilityToggle
