import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

const InvestmentBarChart = ({ accumulationSchedule }) => {
   const years = accumulationSchedule.map((item) => item.year)
   const contributions = accumulationSchedule.map((item) => item.contributions)
   const interest = accumulationSchedule.map((item) => item.interest)
   const balance = accumulationSchedule.map((item) => item.balance)

   const data = {
      labels: years,
      datasets: [
         {
            label: 'Total Contribuições',
            backgroundColor: '#FFCE56',
            data: contributions,
         },
         {
            label: 'Juros',
            backgroundColor: '#FF6384',
            data: interest,
         },
         {
            label: 'Valor Total',
            backgroundColor: '#36A2EB',
            data: balance,
         },
      ],
   }

   const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
         legend: {
            position: 'bottom',
            labels: {
               boxWidth: 10,
               fontSize: 10,
               color: '#FFFFFF', // Set legend text color to white
            },
         },
         title: {
            display: false,
         },
         datalabels: {
            display: false, // Explicitly disable data labels
         },
      },
      scales: {
         x: {
            title: {
               display: true,
               text: 'Anos',
               font: {
                  size: 15,
                  color: '#FFFFFF', // Set x-axis title color to white
               },
               color: '#FFFFFF', // Set x-axis title color to white
            },
            ticks: {
               font: {
                  size: 15,
               },
               color: '#FFFFFF', // Set x-axis labels color to white
            },
         },
         y: {
            title: {
               display: true,
               text: 'Valor',
               font: {
                  size: 15,
                  color: '#FFFFFF', // Set y-axis title color to white
               },
               color: '#FFFFFF', // Set y-axis title color to white
            },
            ticks: {
               font: {
                  size: 15,
               },
               color: '#FFFFFF', // Set y-axis labels color to white
            },
         },
      },
   }

   return (
      <div style={{ width: '700px', height: '450px' }}>
         <Bar data={data} options={options} />
      </div>
   )
}

export default InvestmentBarChart
