import React, { useContext, useMemo } from 'react'
import { Line, Pie } from 'react-chartjs-2'
import styles from './IncomeForm.module.css'
import { useIncome } from '../../../../../../../context/IncomeContext'
import { useExpenses } from '../../../../../../../context/ExpensesContext'
import { SavingsContext } from '../../../../../../../context/SavingsContext'
import { DebtsContext } from '../../../../../../../context/DebtsContext'
import { Col, Row } from 'reactstrap'
import ExpenseBreakdownChart from './components/resumoOrcamentoComponents/ExpenseBreakdownChart'
import CurrentMonthExpensePieChart from './components/resumoOrcamentoComponents/CurrentMonthExpensePieChart'
import BudgetVsActualChart from './components/resumoOrcamentoComponents/BudgetVsActualChart'
import NetWorthChartComponent from './components/resumoOrcamentoComponents/NetWorthChartComponent'

export default function ResumoOrcamento() {
   const { incomes } = useIncome()
   const { expenses } = useExpenses()
   const { savings } = useContext(SavingsContext)
   const { debts } = useContext(DebtsContext)
   const netWorthData = useMemo(
      () => [
         { month: 'jan.2024', netWorth: 12271 },
         { month: 'fev.2024', netWorth: 13273 },
         { month: 'mar.2024', netWorth: 13746 },
         { month: 'abr.2024', netWorth: 16057 },
         { month: 'mai.2024', netWorth: 16495 },
         { month: 'jun.2024', netWorth: 17572 },
         { month: 'jul.2024', netWorth: 18153 },
         { month: 'ago.2024', netWorth: 19518 },
         { month: 'set.2024', netWorth: 20493 },
         { month: 'out.2024', netWorth: 22369 },
         { month: 'nov.2024', netWorth: 24370 },
         { month: 'dez.2024', netWorth: 0 },
      ],
      []
   )
   const months = useMemo(
      () => [
         'jan.2024',
         'fev.2024',
         'mar.2024',
         'abr.2024',
         'mai.2024',
         'jun.2024',
         'jul.2024',
         'ago.2024',
         'set.2024',
         'out.2024',
         'nov.2024',
         'dez.2024',
      ],
      []
   )
   // Helper function to calculate total amount for each month
   const calculateMonthlyTotal = (data, getTotal) => {
      return data.map((monthData) => {
         if (!monthData) return 0
         return getTotal(monthData)
      })
   }

   // Extract total expenses for each month
   const expenseTotals = calculateMonthlyTotal(expenses, (monthData) => {
      if (!monthData.categories) return 0
      return monthData.categories.reduce((sum, category) => {
         return (
            sum +
            (category.subcategories?.reduce((subSum, subcategory) => {
               return subSum + (subcategory.amount || 0)
            }, 0) || 0)
         )
      }, 0)
   })

   /*************  ✨ Codeium Command ⭐  *************/
   /**
    * Calculates the total expenses for each category across all months.
    * Returns an object where the keys are the category names and the values are the total expenses.
    * @param {Array} expenses The array of monthly expenses data.
    * @returns {Object} An object with the total expenses for each category.
    */
   /******  75c9b6d5-014d-42d5-8b14-68148fff5845  *******/
   const calculateYearlyCategoryTotals = (expenses) => {
      const categoryTotals = {}

      expenses.forEach((monthData) => {
         // Check if monthData is valid and has categories
         if (!monthData || !monthData.categories) return

         monthData.categories.forEach((category) => {
            const categoryTotal =
               category.subcategories?.reduce(
                  (sum, subcategory) => sum + (subcategory.amount || 0),
                  0
               ) || 0

            if (categoryTotals[category.name]) {
               categoryTotals[category.name] += categoryTotal
            } else {
               categoryTotals[category.name] = categoryTotal
            }
         })
      })

      return categoryTotals
   }

   // Extract total debt repayment for each month
   const debtRepaymentTotals = calculateMonthlyTotal(debts, (monthData) => {
      if (!Array.isArray(monthData)) return 0
      return monthData.reduce((sum, debt) => {
         return (
            sum +
            debt.categories.reduce((catSum, category) => {
               // Add the 'payment' value if you want to see monthly payments
               return catSum + (category.payment || 0)
            }, 0)
         )
      }, 0)
   })
   const debtAmountTotals = calculateMonthlyTotal(debts, (monthData) => {
      if (!Array.isArray(monthData)) return 0
      return monthData.reduce((sum, debt) => {
         return (
            sum +
            debt.categories.reduce((catSum, category) => {
               // Add the 'remainingBalance' for the debt
               return catSum + (category.remainingBalance || 0)
            }, 0)
         )
      }, 0)
   })
   // Extract total savings for each month
   const savingsTotals = calculateMonthlyTotal(savings, (monthData) => {
      if (!monthData.categories) return 0
      return monthData.categories.reduce((sum, category) => {
         return sum + (category.savings || 0)
      }, 0)
   })

   // Extract total income for each month
   const incomeTotals = calculateMonthlyTotal(incomes, (monthData) => {
      return monthData?.totalIncome || 0
   })

   // Total amounts for the year
   const totalIncome = incomeTotals.reduce((sum, value) => sum + value, 0)
   const totalExpenses = expenseTotals.reduce((sum, value) => sum + value, 0)
   const totalSavings = savingsTotals.reduce((sum, value) => sum + value, 0)
   const totalDebtRepayment = debtRepaymentTotals.reduce(
      (sum, value) => sum + value,
      0
   )

   const netIncome = totalIncome - totalExpenses
   const savingsRate = totalIncome ? (totalSavings / totalIncome) * 100 : 0
   const debtToIncomeRatio = totalIncome
      ? (totalDebtRepayment / totalIncome) * 100
      : 0

   const expenseToIncomeRatio = totalIncome
      ? (totalExpenses / totalIncome) * 100
      : 0
   // Chart Data
   const chartData = useMemo(
      () => ({
         labels: months,
         datasets: [
            {
               label: 'Rendimentos',
               data: incomeTotals,
               borderColor: '#4BC0C0',
               backgroundColor: 'rgba(75, 192, 192, 0.2)',
               fill: true,
            },
            {
               label: 'Despesas',
               data: expenseTotals,
               borderColor: '#FF6384',
               backgroundColor: 'rgba(255, 99, 132, 0.2)',
               fill: true,
            },
            {
               label: 'Poupanças',
               data: savingsTotals,
               borderColor: '#36A2EB',
               backgroundColor: 'rgba(54, 162, 235, 0.2)',
               fill: true,
            },
            {
               label: 'Amortização de Dívida',
               data: debtRepaymentTotals,
               borderColor: '#FFCE56',
               backgroundColor: 'rgba(255, 206, 86, 0.2)',
               fill: true,
            },
            // {
            //    label: 'Saldo de Dívida Restante',
            //    data: debtAmountTotals,
            //    borderColor: '#8A2BE2',
            //    backgroundColor: 'rgba(138, 43, 226, 0.2)',
            //    fill: true,
            // },
         ],
      }),
      [
         incomeTotals,
         expenseTotals,
         savingsTotals,
         debtRepaymentTotals,
         debtAmountTotals,
         months,
      ]
   )

   const chartOptions = {
      plugins: {
         legend: { labels: { color: 'white' } },
      },
      scales: {
         x: { ticks: { color: 'white' } },
         y: { ticks: { color: 'white' } },
      },
   }
   // Calculate income growth rate (current month vs. previous month)
   const calculateGrowthRate = (data) => {
      const growthRates = []
      for (let i = 1; i < data.length; i++) {
         const growth =
            data[i - 1] !== 0
               ? ((data[i] - data[i - 1]) / data[i - 1]) * 100
               : 0
         growthRates.push(growth)
      }
      return growthRates
   }

   const incomeGrowthRates = calculateGrowthRate(incomeTotals)
   console.log('expenseTotals', expenseTotals)
   // Expense breakdown for the most recent month
   const lastMonthExpenses = expenses[expenses.length - 1]?.categories || []
   const yearlyCategoryTotals = calculateYearlyCategoryTotals(expenses)

   const expenseBreakdownData = {
      labels: Object.keys(yearlyCategoryTotals),
      datasets: [
         {
            data: Object.values(yearlyCategoryTotals),
            backgroundColor: [
               '#FF6384',
               '#36A2EB',
               '#FFCE56',
               '#4BC0C0',
               '#9966FF',
               '#FF9F40',
               '#FFCD56',
            ],
         },
      ],
   }
   const currentMonthExpenses = expenses[expenses.length - 1]?.categories || []

   const currentMonthExpenseData = {
      labels: currentMonthExpenses.map((cat) => cat.name),
      datasets: [
         {
            data: currentMonthExpenses.map((cat) =>
               cat.subcategories.reduce(
                  (sum, sub) => sum + (sub.amount || 0),
                  0
               )
            ),
            backgroundColor: [
               '#FF6384',
               '#36A2EB',
               '#FFCE56',
               '#4BC0C0',
               '#9966FF',
               '#FF9F40',
               '#FFCD56',
            ],
         },
      ],
   }

   // Extract monthly expense data for the new chart
   const calculateCurrentMonthExpenses = (monthData) => {
      if (!monthData || !monthData.categories) return {}
      const categoryTotals = {}

      monthData.categories.forEach((category) => {
         const categoryTotal = category.subcategories.reduce(
            (sum, subcategory) => sum + (subcategory.amount || 0),
            0
         )

         if (categoryTotals[category.name]) {
            categoryTotals[category.name] += categoryTotal
         } else {
            categoryTotals[category.name] = categoryTotal
         }
      })

      return categoryTotals
   }

   // Get the last month's expense data
   const currentMonthExpenseIncomeData =
      expenses.length > 0
         ? calculateCurrentMonthExpenses(expenses[expenses.length - 1])
         : {}

   // Format the data for the CurrentMonthExpensePieChart component
   const currentMonthExpenseIncomeChartData = {
      labels: Object.keys(currentMonthExpenseIncomeData),
      datasets: [
         {
            data: Object.values(currentMonthExpenseIncomeData),
            backgroundColor: [
               '#FF6384',
               '#36A2EB',
               '#FFCE56',
               '#4BC0C0',
               '#9966FF',
               '#FF9F40',
               '#FFCD56',
            ],
         },
      ],
   }

   return (
      <div className={styles.financialSummary}>
         <h2>Resumo Orçamento Anual</h2>
         <Row className={styles.chartSection}>
            <Col xs={4}>
               <div className={styles.overviewMetrics}>
                  <div className={styles.metricBox}>
                     <h3>Rendimento Líquido</h3>
                     <p>{netIncome.toFixed(2)} €</p>
                  </div>
                  <div className={styles.metricBox}>
                     <h3>Taxa de Poupança</h3>
                     <p>{savingsRate.toFixed(2)} %</p>
                  </div>
                  <div className={styles.metricBox}>
                     <h3>Rácio Despesas/Rendimento</h3>
                     <p>{expenseToIncomeRatio.toFixed(2)} %</p>
                  </div>
                  <div className={styles.metricBox}>
                     <h3>Rácio Dívida/Rendimento</h3>
                     <p>{debtToIncomeRatio.toFixed(2)} %</p>
                  </div>
                  <div className={styles.metricBox}>
                     <h3>Taxa de Crescimento Médio de Rendimento</h3>
                     <p>
                        {incomeGrowthRates.length > 0
                           ? (
                                incomeGrowthRates.reduce(
                                   (sum, val) => sum + val,
                                   0
                                ) / incomeGrowthRates.length
                             ).toFixed(2)
                           : 0}{' '}
                        %
                     </p>
                  </div>
               </div>
            </Col>

            <Col xs={4}>
               <BudgetVsActualChart
                  budgetData={[5000, 3000, 1000, 2000, 1500]} // Replace with actual budget data
                  actualData={[4500, 3200, 900, 2100, 1400]} // Replace with actual data
                  labels={[
                     'Rendimentos',
                     'Despesas',
                     'Poupanças',
                     'Investimentos',
                     'Amort. de dívida',
                  ]}
                  width={1800}
                  height={900}
               />
            </Col>
            <Col xs={4}>
               <NetWorthChartComponent data={netWorthData} />
            </Col>
         </Row>
         <Row className={styles.chartSection}>
            <Col xs={4}>
               <ExpenseBreakdownChart
                  data={expenseBreakdownData}
                  width={1800}
                  height={900}
               />
            </Col>

            <Col xs={4}>
               <h3>Visualização Gráfica</h3>
               <Line
                  data={chartData}
                  options={chartOptions}
                  width={1800}
                  height={900}
               />
            </Col>
            <Col xs={4}>
               <CurrentMonthExpensePieChart data={currentMonthExpenseData} />
            </Col>
         </Row>

         <Col xs={3}>
            <CurrentMonthExpensePieChart
               data={currentMonthExpenseIncomeChartData}
            />
         </Col>
         <table
            className={`${styles.monthlyBreakdown} ${styles.tableTextWhite}`}
         >
            <thead>
               <tr>
                  <th>Rubrica</th>
                  {months.map((month) => (
                     <th key={month}>{month}</th>
                  ))}
                  <th>Total 2024</th>
                  <th>Média mensal 2024</th>
               </tr>
            </thead>
            <tbody>
               {/* Rendimentos Row */}
               <tr>
                  <td>Rendimentos</td>
                  {incomeTotals.map((income, index) => (
                     <td key={index}>{income.toFixed(2)}</td>
                  ))}
                  <td>{totalIncome.toFixed(2)} €</td>
                  <td>{(totalIncome / 12).toFixed(2)} €</td>
               </tr>

               {/* Despesas Row */}
               <tr>
                  <td>Despesas</td>
                  {expenseTotals.map((expense, index) => (
                     <td key={index}>{expense.toFixed(2)}</td>
                  ))}
                  <td>{totalExpenses.toFixed(2)} €</td>
                  <td>{(totalExpenses / 12).toFixed(2)} €</td>
               </tr>

               {/* Poupanças Row */}
               <tr>
                  <td>Poupanças</td>
                  {savingsTotals.map((savings, index) => (
                     <td key={index}>{savings.toFixed(2)}</td>
                  ))}
                  <td>{totalSavings.toFixed(2)} €</td>
                  <td>{(totalSavings / 12).toFixed(2)} €</td>
               </tr>

               {/* Amortização de Dívida Row */}
               <tr>
                  <td>Amortização de Dívida</td>
                  {debtRepaymentTotals.map((debtRepayment, index) => (
                     <td key={index}>{debtRepayment.toFixed(2)}</td>
                  ))}
                  <td>{totalDebtRepayment.toFixed(2)} €</td>
                  <td>{(totalDebtRepayment / 12).toFixed(2)} €</td>
               </tr>

               {/* Saldo de Dívida Restante Row */}
               <tr>
                  <td>Saldo de Dívida Restante</td>
                  {debtAmountTotals.map((amount, index) => (
                     <td key={index}>{amount.toFixed(2)}</td>
                  ))}
                  <td>
                     {debtAmountTotals
                        .reduce((sum, val) => sum + val, 0)
                        .toFixed(2)}{' '}
                     €
                  </td>
                  <td>
                     {(
                        debtAmountTotals.reduce((sum, val) => sum + val, 0) / 12
                     ).toFixed(2)}{' '}
                     €
                  </td>
               </tr>
               <tr>
                  <td>Taxa de Crescimento de Rendimento</td>
                  <td>0.00 %</td>{' '}
                  {/* For the first month, where growth comparison doesn't exist */}
                  {incomeGrowthRates.map((growthRate, index) => (
                     <td key={index + 1}>{growthRate.toFixed(2)} %</td>
                  ))}
                  <td>-</td> {/* Placeholder for total */}
                  <td>-</td> {/* Placeholder for average */}
               </tr>
            </tbody>
         </table>

         <Row className={styles.chartSection}></Row>
      </div>
   )
}
