import React, { useState } from 'react'
import axios from 'axios'

const InvestimentosForm = ({ onInvestmentsChange }) => {
   const [rows, setRows] = useState([{ tipo: '', valor: '', data: '' }])

   const handleAddRow = () => {
      const newRows = [...rows, { tipo: '', valor: '', data: '' }]
      setRows(newRows)
      onInvestmentsChange(newRows)
   }

   const handleInputChange = (index, event) => {
      const { name, value } = event.target
      const updatedRows = rows.map((row, i) =>
         i === index ? { ...row, [name]: value } : row
      )
      setRows(updatedRows)
      onInvestmentsChange(updatedRows)
   }

   const saveInvestments = async () => {
      try {
         await axios.post(`${process.env.REACT_APP_API_URL}/investments`, rows)
         alert('Investments saved successfully')
      } catch (error) {
         console.error('Failed to save investments', error)
         alert('Failed to save investments')
      }
   }

   return (
      <div>
         <h3>Investimentos</h3>
         <table>
            <thead>
               <tr>
                  <th>Tipo</th>
                  <th>Valor</th>
                  <th>Data</th>
               </tr>
            </thead>
            <tbody>
               {rows.map((row, index) => (
                  <tr key={index}>
                     <td>
                        <input
                           type="text"
                           name="tipo"
                           value={row.tipo}
                           onChange={(e) => handleInputChange(index, e)}
                        />
                     </td>
                     <td>
                        <input
                           type="number"
                           name="valor"
                           value={row.valor}
                           onChange={(e) => handleInputChange(index, e)}
                        />
                     </td>
                     <td>
                        <input
                           type="date"
                           name="data"
                           value={row.data}
                           onChange={(e) => handleInputChange(index, e)}
                        />
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
         <button onClick={handleAddRow}>Adicionar Investimento</button>
         <button onClick={saveInvestments}>Guardar Investimentos</button>
      </div>
   )
}

export default InvestimentosForm
