import React from 'react'
import { InputText, Dropdown, Button } from 'primereact'

import css from './PlaylistForm.module.css'
import { usePlaylist } from '../../../../../../context/PlaylistContext'

const PlaylistForm = () => {
   const {
      playlistName,
      setPlaylistName,
      newVideoLink,
      setNewVideoLink,
      videoLinks,
      handleAddVideo,
      selectedTopic,
      setSelectedTopic,
      topicOptions,
      handleCreatePlaylist,
      clearForm,
      hasMinimumVideos,
   } = usePlaylist()

   const videoCountMessage = () => {
      const isComplete = videoLinks.length >= 3 // Condição para verde
      const message =
         videoLinks.length >= 3
            ? `${videoLinks.length}/3 vídeos suficientes para criar a playlist!`
            : `${videoLinks.length}/3 vídeos necessários para criar a playlist`
      return (
         <div
            className={`${css.videoCountMessage} ${
               isComplete ? 'complete' : ''
            }`}
            style={{
               color: isComplete ? '#81c784' : '#ff6f61', // Estilo inline
            }}
         >
            {message}
         </div>
      )
   }

   return (
      <div className={css.playlistFormContainer}>
         <div className={css.subtituloResultado}>Criar Playlist</div>
         <InputText
            value={playlistName}
            onChange={(e) => setPlaylistName(e.target.value)}
            placeholder="Nome da Playlist"
            className={css.inputText}
         />
         <Dropdown
            value={selectedTopic}
            options={topicOptions}
            onChange={(e) => setSelectedTopic(e.value)}
            placeholder="Selecionar Tema"
            className={css.dropdown}
         />
         <InputText
            value={newVideoLink}
            onChange={(e) => setNewVideoLink(e.target.value)}
            placeholder="Adicionar Link de Vídeo"
            className={css.inputText}
         />
         {videoCountMessage()}
         <Button
            label="Adicionar Vídeo"
            onClick={handleAddVideo}
            className={css.buttonPlaylist}
         />
         <Button
            label="Criar Playlist"
            onClick={handleCreatePlaylist}
            disabled={!hasMinimumVideos}
            className={css.buttonPlaylist}
            style={{ marginTop: '15px' }}
         />
         <Button
            label="Limpar Formulário"
            onClick={clearForm}
            className={css.buttonLimpar}
            style={{ backgroundColor: '#f44336', marginTop: '10px' }}
         />
      </div>
   )
}

export default PlaylistForm
