import React, { useContext, useState } from 'react'
import css from './FileItem.module.css'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { UserContext } from '../../../../../context/useUserContext'

export const FileItem = ({ file, onDelete, showActions }) => {
   const { user } = useContext(UserContext) // Obtemos o contexto do usuário
   const [dialogVisible, setDialogVisible] = useState(false)

   const downloadUrl = `${process.env.REACT_APP_API_URL}fileRoutes/download/${file._id}`

   const handleDownloadClick = (e) => {
      if (user.role === 'visitor') {
         e.preventDefault() // Impede o download
         setDialogVisible(true) // Mostra o aviso
      }
   }

   const handleDeleteClick = async () => {
      if (
         window.confirm(
            `Tem certeza de que deseja apagar o arquivo "${file.originalName}"?`
         )
      ) {
         try {
            onDelete(file._id)
         } catch (error) {
            console.error('Erro ao apagar o arquivo:', error)
         }
      }
   }

   const fileImageSrc =
      file.mimeType === 'application/pdf' ? 'pdfFiles.webp' : 'excelFiles.webp'

   return (
      <div className={css.fileItemCard}>
         <img
            src={require(`../../../../../assets/files/${fileImageSrc}`)}
            alt={file.originalName}
            className={css.fileItemImage}
         />
         <div className={css.fileItemContent}>
            <h4 className={css.fileItemTitle}>{file.cleanOriginalName}</h4>
            <a
               href={downloadUrl}
               className={css.fileItemDownload}
               download
               onClick={handleDownloadClick} // Bloqueia o download para visitantes
            >
               Descarregar
            </a>
            {showActions && (
               <Button
                  onClick={handleDeleteClick}
                  className={css.fileItemDelete}
                  label="Apagar"
                  icon="pi pi-trash"
                  size="small"
                  severity="danger"
               />
            )}
         </div>

         {/* Dialog para visitantes */}
         <Dialog
            visible={dialogVisible}
            onHide={() => setDialogVisible(false)}
            header="Acesso Restrito"
            dismissableMask
            style={{ width: '60vw' }}
         >
            <div style={{ padding: '20px' }}>
               <p style={{ color: 'white' }}>
                  Para descarregar este arquivo, é necessário fazer login.
               </p>
               <div
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     gap: '10px',
                  }}
               >
                  <Button
                     onClick={() => (window.location.href = '/login')} // Redireciona para login
                     className={css.following}
                     label="Login"
                  />
                  <Button
                     onClick={() => setDialogVisible(false)}
                     className={css.gradientButton}
                     label="Cancelar"
                  />
               </div>
            </div>
         </Dialog>
      </div>
   )
}
