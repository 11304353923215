import { stateToHTML } from 'draft-js-export-html'
import { convertFromRaw } from 'draft-js'

// Example function to convert raw Draft.js content to HTML
const convertContentToHTML = (rawDraftContent) => {
   if (!rawDraftContent) {
      console.error('No content provided')
      return ''
   }

   try {
      if (typeof rawDraftContent === 'string') {
         const contentState = convertFromRaw(JSON.parse(rawDraftContent))
         const html = stateToHTML(contentState)
         return html
      } else {
         console.error('Invalid content type:', typeof rawDraftContent)
         return '' // Return empty or default HTML
      }
   } catch (error) {
      console.error('Failed to parse content:', error)
      return '' // Return empty or default HTML on error
   }
}

export default convertContentToHTML
