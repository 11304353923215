import React, { useContext, useEffect, useState } from 'react'
import { Card } from 'primereact/card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import styles from './NotificacoesCard.module.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { ProgressBar } from 'primereact/progressbar'
import { Button } from 'primereact/button'
import { UserContext } from '../../../../../context/useUserContext'

const NotificacoesCard = () => {
   const [notifications, setNotifications] = useState([])
   const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState(null)
   const navigate = useNavigate()
   const { user } = useContext(UserContext)
   const userId = user.id

   useEffect(() => {
      const fetchNotifications = async () => {
         setIsLoading(true)
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}notification/notifications?userId=${userId}`
            )
            setNotifications(response.data)
            setError(null)
         } catch (error) {
            console.error('Error fetching notifications:', error)
            setError('Failed to fetch notifications')
         }
         setIsLoading(false)
      }

      fetchNotifications()

      const ws = new WebSocket('ws://localhost:8080')
      ws.onopen = () => {
         console.log('Connected to WebSocket')
      }
      ws.onmessage = (event) => {
         try {
            const notification = JSON.parse(event.data)
            if (notification.type === 'new-announcement') {
               setNotifications((prev) => [notification, ...prev])
            }
         } catch (error) {
            console.error('Failed to parse WebSocket message:', error)
         }
         setIsLoading(false)
      }
      ws.onerror = (error) => {
         console.error('WebSocket error:', error)
      }
      ws.onclose = (event) => {
         console.log('WebSocket closed:', event)
      }

      return () => {
         ws.close()
      }
   }, [userId])

   const markAsRead = (notificationId) => {
      axios
         .post(`${process.env.REACT_APP_API_URL}notification/mark-as-read`, {
            notificationId,
         })
         .then((response) => {
            const { username } = response.data

            setNotifications((prev) =>
               prev.map((notif) =>
                  notif._id === notificationId
                     ? { ...notif, read: true }
                     : notif
               )
            )

            // Navigate to the creator's page
            if (username) {
               navigate(`/creators/${username}`)
            }
         })
         .catch((error) => {
            console.error('Error marking notification as read:', error)
         })
   }

   const handleVisitClick = (notification, event) => {
      event.stopPropagation()
      markAsRead(notification._id)
   }

   const handleNotificationHover = (notificationId) => {
      setNotifications((prev) =>
         prev.map((notif) =>
            notif._id === notificationId ? { ...notif, read: true } : notif
         )
      )
   }

   const clearNotifications = () => {
      axios
         .post(`${process.env.REACT_APP_API_URL}notification/clear`, {
            userId,
         })
         .then(() => {
            setNotifications([])
         })
         .catch((error) => {
            console.error('Error clearing notifications:', error)
         })
   }

   return (
      <Card className={styles.card}>
         <div className={styles.titleWithIcon}>
            <span>Notificações</span>
            <FontAwesomeIcon
               icon={faCog}
               className={styles.settingsIcon}
               onClick={() => navigate('/dashboard/regular/notifications')}
            />
         </div>
         <div className={styles.notificationsSection}>
            {error && <p className={styles.error}>{error}</p>}
            {isLoading ? (
               <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
            ) : (
               <div className={styles.notificationsList}>
                  {notifications.map((notification, index) => (
                     <div
                        key={index}
                        className={`${styles.notificationItem} ${
                           notification.read ? '' : styles.unread
                        }`}
                        onMouseEnter={() =>
                           handleNotificationHover(notification._id)
                        }
                     >
                        {!notification.read && (
                           <span className={styles.unreadIndicator}></span>
                        )}
                        <FontAwesomeIcon
                           icon={faUserPlus}
                           className={styles.notificationIcon}
                        />
                        <div className={styles.notificationContent}>
                           <span className={styles.notificationText}>
                              {notification.message}
                           </span>
                           <span className={styles.notificationTime}>
                              {notification.timeAgo}
                           </span>
                           <Button
                              label="Visitar"
                              onClick={(event) =>
                                 handleVisitClick(notification, event)
                              }
                              className={styles.visitButton}
                           />
                        </div>
                     </div>
                  ))}
               </div>
            )}
            <Button
               label="Limpar Notificações"
               onClick={clearNotifications}
               className={styles.clearButton}
            />
         </div>
      </Card>
   )
}

export default NotificacoesCard
