import React, { useState, useContext } from 'react'
import axios from 'axios'
import { UserContext } from '../../../../../context/useUserContext'
import { Button } from 'primereact/button'
import { Col, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import { Dropdown } from 'primereact/dropdown'
import css from './FileItem.module.css'
import { handleError } from '../../../../../utils/handleError'

const FileUpload = ({ onFileUploadSuccess }) => {
   const { user } = useContext(UserContext)
   const creatorId = user.id
   const [selectedFile, setSelectedFile] = useState(null)
   const [selectedTopic, setSelectedTopic] = useState('')

   const handleFileChange = (e) => {
      setSelectedFile(e.target.files[0])
   }

   const handleTopicChange = (e) => {
      setSelectedTopic(e.target.value)
   }

   const handleFileUpload = async () => {
      if (!selectedFile) {
         toast.error('Por favor, selecione um arquivo antes de fazer o upload.')
         return
      }
      if (!selectedTopic) {
         toast.error('Por favor, selecione um tópico antes de fazer o upload.')
         return
      }

      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('topic', selectedTopic)
      formData.append('creator', creatorId)
      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}fileRoutes/upload`,
            formData,
            {
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
            }
         )
         onFileUploadSuccess(response.data.fileRecord)
         toast.success('Documento adicionado com sucesso')
      } catch (error) {
         handleError(error)
         toast.error('Falha ao carregar o ficheiro.')
      }
   }

   const topicOptions = [
      'ETFs',
      'Ações',
      'Reits',
      'Cryptos',
      'Finanças Pessoais',
      'Poupança',
      'Imobiliário',
      'Obrigações',
      'Fundos mútuos',
      'Empreendedorismo',
      'Futuros e Opções',
      'Trading',
   ]

   return (
      <>
         <h2 className={css.title}>Carregar Ficheiros</h2>
         <div className={css.rowUP}>
            <Col md={3} className={css.colUp}>
               <input type="file" onChange={handleFileChange} />
               <Button
                  onClick={handleFileUpload}
                  label="Upload do documento"
                  className={css.following}
                  disabled={!selectedFile}
               />
            </Col>
            <Col>
               <Dropdown
                  value={selectedTopic}
                  options={topicOptions.map((topic) => ({
                     label: topic,
                     value: topic,
                  }))}
                  onChange={handleTopicChange}
                  placeholder="Selecionar Tema"
               />
            </Col>
         </div>
      </>
   )
}

export default FileUpload
