import React, { useContext, useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import DebtNavigation from './DebtNavigation'
import { DebtsContext } from '../../../../../../../../../context/DebtsContext'

const DebtStatistics = () => {
   const { debts, selectedMonth } = useContext(DebtsContext)

   console.log('DebtStatistics', debts)

   // Calculate how much of the debt is paid off
   const debtProgress = useMemo(() => {
      const monthData = debts[selectedMonth] ? debts[selectedMonth][0] : null

      if (monthData && monthData.categories) {
         return monthData.categories.map((category) => {
            const total = category.amount || 0
            const remaining = category.remainingBalance || 0
            const paid = total - remaining

            return {
               name: category.name,
               total,
               paid,
            }
         })
      } else {
         return []
      }
   }, [debts, selectedMonth])

   // Prepare the data for the bar chart
   const barChartData = useMemo(() => {
      return {
         labels: debtProgress.map((debt) => debt.name),
         datasets: [
            {
               label: 'Dívida Total',
               data: debtProgress.map((debt) => debt.total),
               backgroundColor: '#FF6384',
            },
            {
               label: 'Dívida Paga',
               data: debtProgress.map((debt) => debt.paid),
               backgroundColor: '#36A2EB',
            },
         ],
      }
   }, [debtProgress])

   const barChartOptions = {
      scales: {
         x: {
            ticks: {
               color: 'white', // Turn x-axis labels white
            },
            grid: {
               color: '#444', // Optional: Change grid lines color for better visibility
            },
         },
         y: {
            beginAtZero: true,
            ticks: {
               color: 'white', // Turn y-axis labels white
            },
            grid: {
               color: '#444', // Optional: Change grid lines color for better visibility
            },
         },
      },
      plugins: {
         legend: {
            labels: {
               color: 'white', // Turn legend labels white
            },
         },
         tooltip: {
            callbacks: {
               label: function (tooltipItem) {
                  const total = tooltipItem.dataset.data.reduce(
                     (sum, value) => sum + value,
                     0
                  )
                  const value = tooltipItem.raw
                  const percentage = ((value / total) * 100).toFixed(2)
                  return `${tooltipItem.label}: ${percentage}% (${value}€)`
               },
            },
            bodyColor: 'white', // Tooltip body text color
            titleColor: 'white', // Tooltip title text color
            backgroundColor: '#333', // Tooltip background color for better visibility
         },
         datalabels: {
            color: 'white', // Set the data label color to white
            anchor: 'end',
            align: 'top',
            formatter: (value) => {
               return value.toLocaleString() + '€'
            },
         },
      },
   }

   return (
      <>
         <DebtNavigation />

         <Row
            style={{
               marginTop: '20px',
               justifyContent: 'center',
               marginBottom: '20px',
            }}
         >
            <Col style={{ width: '800px', height: '400px' }}>
               <h3 style={{ color: 'white' }}>Progresso das dívidas </h3>
               <Bar
                  data={barChartData}
                  options={barChartOptions}
                  plugins={[ChartDataLabels]} // Add the plugin here
               />
            </Col>
         </Row>
      </>
   )
}

export default DebtStatistics
