import { Button } from 'primereact/button'
import React from 'react'

const SaveUpdateButtons = ({ isEditing, handleSaveOrUpdate, handleDelete }) => {
   console.log('handleSaveOrUpdate', handleSaveOrUpdate)
   return (
      <div className="save-update-buttons">
         <Button onClick={handleSaveOrUpdate} size="small">
            {isEditing ? 'Atualizar Despesas' : 'Salvar Despesas'}
         </Button>
         {isEditing && (
            <Button size="small" onClick={handleDelete}>
               Excluir Despesas
            </Button>
         )}
      </div>
   )
}

export default SaveUpdateButtons
