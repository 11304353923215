import React, { useContext } from 'react'
import { Row } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import css from '../ToolIndex.module.css'
import { UserContext } from '../../../context/useUserContext'

function InvestingTools() {
   const { user } = useContext(UserContext)

   // Verifica se o usuário está autenticado
   const isAuthenticated = user?.role && user.role !== 'visitor'

   return (
      <div>
         <Row className={css.tabContainer}>
            <div className={css.tabContainer}>
               <nav className={css.tabNavigation}>
                  <NavLink
                     to="/ferramentas/investingTools/etf"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Análise de ETFs
                  </NavLink>
                  <NavLink
                     to="/ferramentas/investingTools/reits"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Valor Intrínseco de REITs
                  </NavLink>
               </nav>
            </div>
            <div
               className={`${css.tabContent} ${
                  !isAuthenticated ? css.blurContent : ''
               }`}
            >
               {!isAuthenticated && (
                  <div className={css.overlay}>
                     Por favor, faça login para acessar esta ferramenta.
                  </div>
               )}
               <Outlet /> {/* This will render the specific tool if selected */}
            </div>
         </Row>
      </div>
   )
}

export default InvestingTools
