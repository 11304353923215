const defaultExpenseCategories = [
   {
      id: 'housing',
      name: 'Habitação',
      subcategories: [
         { id: 'mortgage', name: 'Prestação Crédito Habitação' },
         { id: 'condoFees', name: 'Condomínio' },
         { id: 'homeInsurance', name: 'Seguro Multirriscos' },
      ],
   },
   {
      id: 'utilities',
      name: 'Utilities',
      subcategories: [
         { id: 'electricity', name: 'Eletricidade' },
         { id: 'gas', name: 'Gás' },
         { id: 'water', name: 'Água' },
         { id: 'telecommunications', name: 'Telecomunicações' },
      ],
   },
   {
      id: 'foodAndDrink',
      name: 'Comida e Bebida',
      subcategories: [
         { id: 'groceries', name: 'Groceries' },
         { id: 'eatingOut', name: 'Eating Out' },
         { id: 'takeaway', name: 'Takeaway' },
         { id: 'coffee', name: 'Coffee' },
         { id: 'drinks', name: 'Drinks' },
         { id: 'supplements', name: 'Supplements' },
      ],
   },
   {
      id: 'transport',
      name: 'Transportes',
      subcategories: [
         { id: 'fuel', name: 'Fuel' },
         { id: 'tollsAndParking', name: 'Tolls and Parking' },
         { id: 'publicTransport', name: 'Public Transport' },
         { id: 'carMaintenance', name: 'Car Maintenance' },
         { id: 'electricCarCharging', name: 'Electric Car Charging' },
      ],
   },
   {
      id: 'shopping',
      name: 'Shopping',
      subcategories: [
         { id: 'clothing', name: 'Clothing' },
         { id: 'footwear', name: 'Footwear' },
         { id: 'accessories', name: 'Accessories' },
         { id: 'electronics', name: 'Electronics' },
         { id: 'householdItems', name: 'Household Items' },
      ],
   },
   {
      id: 'personalCare',
      name: 'Cuidados Pessoais',
      subcategories: [
         { id: 'beautyProducts', name: 'Beauty Products' },
         { id: 'hairdresser', name: 'Hairdresser' },
      ],
   },
   {
      id: 'education',
      name: 'Educação',
      subcategories: [
         { id: 'books', name: 'Books' },
         { id: 'schoolFees', name: 'School Fees' },
         { id: 'schoolSupplies', name: 'School Supplies' },
      ],
   },
   {
      id: 'entertainment',
      name: 'Entretenimento',
      subcategories: [
         { id: 'subscriptions', name: 'Subscriptions' },
         { id: 'cinemaAndTheater', name: 'Cinema and Theater' },
         { id: 'otherEvents', name: 'Other Events' },
         { id: 'newspapers', name: 'Newspapers' },
         { id: 'ticketsAndSouvenirs', name: 'Tickets and Souvenirs' },
      ],
   },
   {
      id: 'health',
      name: 'Saúde',
      subcategories: [
         { id: 'medicalConsultations', name: 'Medical Consultations' },
         { id: 'physicalRehabilitation', name: 'Physical Rehabilitation' },
         { id: 'pharmacy', name: 'Pharmacy' },
         { id: 'dentist', name: 'Dentist' },
         { id: 'sports', name: 'Sports' },
      ],
   },
   {
      id: 'familyAndFriends',
      name: 'Família e Amigos',
      subcategories: [
         { id: 'gifts', name: 'Gifts' },
         { id: 'domesticHelp', name: 'Domestic Help' },
         { id: 'babysitter', name: 'Babysitter' },
         { id: 'otherDomesticServices', name: 'Other Domestic Services' },
         { id: 'partiesAndCelebrations', name: 'Parties and Celebrations' },
         { id: 'laundry', name: 'Laundry' },
         { id: 'pets', name: 'Pets' },
      ],
   },
   {
      id: 'insurance',
      name: 'Seguros',
      subcategories: [
         { id: 'healthInsurance', name: 'Seguro Saúde' },
         { id: 'lifeInsurance', name: 'Seguro Vida' },
         { id: 'homeInsurance', name: 'Seguro Multirriscos' },
      ],
   },
   {
      id: 'vacation',
      name: 'Férias',
      subcategories: [
         { id: 'travelTickets', name: 'Travel Tickets' },
         { id: 'accommodation', name: 'Accommodation' },
         { id: 'vacationFoodExpenses', name: 'Vacation Food Expenses' },
      ],
   },
   {
      id: 'otherExpenses',
      name: 'Outras Despesas',
      subcategories: [
         { id: 'taxes', name: 'Taxes' },
         { id: 'bankFees', name: 'Bank Fees' },
         { id: 'otherExpenses', name: 'Other Expenses' },
      ],
   },
]

module.exports = defaultExpenseCategories
