import React from 'react'
import css from './WelcomeVideoCreatorPage.module.css'

const VideoDisplay = ({ videoLink }) => {
   const getVideoId = (url) => {
      const regex =
         /(?:https:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      const match = url.match(regex)
      return match ? match[1] : null
   }

   const videoId = getVideoId(videoLink)
   if (!videoId) {
      return <p>Link do YouTube inválido. Verifique o link e tente de novo.</p>
   }

   return (
      <div className={css.videoWrapper}>
         <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
         ></iframe>
      </div>
   )
}

export default VideoDisplay
