import React from 'react'
import { Dropdown } from 'primereact/dropdown'

function DebtPrioritySelector({ selectedMethod, onSelectMethod }) {
   const methods = [
      { label: 'Bola de Neve', value: 'snowball' },
      { label: 'Avalanche', value: 'avalanche' },
   ]

   return (
      <div
         style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '20px' }}
      >
         <h3>Escolhe o teu método:</h3>
         <Dropdown
            value={selectedMethod}
            options={methods}
            onChange={(e) => {
               onSelectMethod(e.value)
            }}
            placeholder="Select a Method"
         />
      </div>
   )
}

export default DebtPrioritySelector
