import React, { createContext, useState, useEffect } from 'react'
import api from '../utils/api'

export const UserContext = createContext()

const initialState = {
   login: false, // Visitante não está logado
   id: null, // Visitante não tem um `id`
   username: 'Visitante',
   profilePictureUrl: null,
   role: 'visitor',
   email: null,
   accessToken: null,
}

const UserProvider = ({ children }) => {
   const [user, setUser] = useState(initialState)
   const [loading, setLoading] = useState(true)

   const logout = async () => {
      try {
         await api.post('/authRoutes/logout', {}, { withCredentials: true })
      } catch (error) {
         console.error('Error during logout:', error)
      } finally {
         localStorage.clear()
         setUser(initialState) // Redefine como visitante
         api.defaults.headers.common['Authorization'] = null
      }
   }

   const refreshAccessToken = async () => {
      try {
         const response = await api.post(
            '/authRoutes/refresh-token',
            {},
            { withCredentials: true }
         )
         const { accessToken } = response.data
         localStorage.setItem('token', accessToken)
         api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
         return true
      } catch (error) {
         console.error('Token refresh failed:', error)
         logout()
         return false
      }
   }

   const initializeSession = async () => {
      const token = localStorage.getItem('token')
      if (!token) {
         setUser(initialState)
         setLoading(false)
         return
      }

      try {
         api.defaults.headers.common['Authorization'] = `Bearer ${token}`
         const response = await api.get('/authRoutes/me', {
            withCredentials: true,
         })

         const userData = response.data.user

         if (!userData) {
            throw new Error('Invalid user data received')
         }

         // Adiciona o `id` recebido da API ao estado do usuário
         setUser({
            login: true,
            id: userData.id, // Certifique-se de que a API retorna o `id` do usuário aqui
            username: userData.username || 'Usuário',
            profilePictureUrl: userData.profilePictureUrl || null,
            role: userData.role || 'visitor',
            email: userData.email || null,
            accessToken: token,
         })
      } catch (error) {
         console.error('Session validation failed:', error)
         logout()
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      initializeSession()
   }, [])

   return (
      <UserContext.Provider
         value={{ user, setUser, logout, refreshAccessToken }}
      >
         {loading ? <div>Carregando...</div> : children}
      </UserContext.Provider>
   )
}

export default UserProvider
