import React, { useState } from 'react'
import axios from 'axios'

const FireDist = () => {
   const [monthlyExpenses, setMonthlyExpenses] = useState(1000)
   const [dividendYield, setDividendYield] = useState(4)
   const [yearsToGoal, setYearsToGoal] = useState(28)
   const [inflationRate, setInflationRate] = useState(2)
   const [fireGoal, setFireGoal] = useState(null)

   const annualExpenses = monthlyExpenses * 12
   const fireValue = annualExpenses / (dividendYield / 100)

   // Inflation and tax adjustment
   const inflationImpact = Math.pow(1 + inflationRate / 100, yearsToGoal)
   const adjustedFireValue = fireValue * inflationImpact
   const fireValueAfterTax = adjustedFireValue / 0.72 // Adjusting for 28% tax

   const saveFireGoal = async () => {
      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/fire-goal`,
            {
               fireGoal: fireValueAfterTax,
            }
         )
         setFireGoal(response.data.fireGoal) // Assuming the API returns the saved goal
         alert('FIRE Goal saved successfully!')
      } catch (error) {
         console.error('Error saving FIRE goal', error)
         alert('Failed to save FIRE goal')
      }
   }

   return (
      <div>
         <h2>Cálculo do FIRE - Dividendos</h2>
         <label>
            Despesas Mensais:
            <input
               type="number"
               value={monthlyExpenses}
               onChange={(e) => setMonthlyExpenses(Number(e.target.value))}
            />
         </label>
         <br />
         <label>
            Dividend Yield médio (%):
            <input
               type="number"
               value={dividendYield}
               onChange={(e) => setDividendYield(Number(e.target.value))}
            />
         </label>
         <br />
         <label>
            Meta atingida em quantos anos?:
            <input
               type="number"
               value={yearsToGoal}
               onChange={(e) => setYearsToGoal(Number(e.target.value))}
            />
         </label>
         <br />
         <label>
            Inflação anual média (%):
            <input
               type="number"
               value={inflationRate}
               onChange={(e) => setInflationRate(Number(e.target.value))}
            />
         </label>
         <br />
         <div>
            <h3>Ajuste à inflação e impostos</h3>
            <p>
               Valor do FIRE ajustado à inflação e impostos:{' '}
               {fireValueAfterTax.toFixed(2)} €
            </p>
            <button onClick={saveFireGoal}>Guardar FIRE Goal</button>
         </div>
      </div>
   )
}

export default FireDist
