import React, { createContext, useContext } from 'react'
import { useEventManager } from '../hooks/useEventManager'

const EventManagerContext = createContext(null)

export const EventManagerProvider = ({ children }) => {
   const manager = useEventManager()

   return (
      <EventManagerContext.Provider value={manager}>
         {children}
      </EventManagerContext.Provider>
   )
}

export const useEventContext = () => useContext(EventManagerContext)
