import React from 'react'
import { Row } from 'reactstrap'
import css from './perfil/DashboardCreatorComponents.module.css'
import { Outlet, NavLink } from 'react-router-dom'

export default function ContentManagement() {
   return (
      <Row className={css.tabContainer}>
         <div className={css.tabContainer}>
            <nav className={css.tabNavigation}>
               <NavLink
                  to="announcements"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Avisos
               </NavLink>
               <NavLink
                  to="welcome-video"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Boas-Vindas
               </NavLink>
               <NavLink
                  to="courses"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Cursos
               </NavLink>
               {/* <NavLink
                  to="articles"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Posts
               </NavLink> */}
               <NavLink
                  to="files"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Ficheiros
               </NavLink>
               <NavLink
                  to="events/big-events-dashboard"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Eventos
               </NavLink>
               <NavLink
                  to="playlists/myPlaylists"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Playlists
               </NavLink>
            </nav>
         </div>
         <div className={css.content}>
            <Outlet />
         </div>
      </Row>
   )
}
