import React, { useEffect } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import css from '../DashboardCreatorComponents.module.css'
import { useDashboard } from '../../../../../context/DashboardContext'
import { UserContext } from '../../../../../context/useUserContext'

// Mapeamento dos ícones para cada rede social
const socialMediaIcons = {
   youtube: 'fab fa-youtube',
   facebook: 'fab fa-facebook',
   instagram: 'fab fa-instagram',
   spotify: 'fab fa-spotify',
   tiktok: 'fab fa-tiktok',
   twitter: 'fab fa-twitter',
   telegram: 'fab fa-telegram',
   linkedin: 'fab fa-linkedin',
   pinterest: 'fab fa-pinterest',
   snapchat: 'fab fa-snapchat-ghost',
   reddit: 'fab fa-reddit',
   discord: 'fab fa-discord',
   twitch: 'fab fa-twitch',
   wechat: 'fab fa-weixin',
   vimeo: 'fab fa-vimeo',
   medium: 'fab fa-medium',
   generic: 'fas fa-link', // Ícone genérico para redes não identificadas
}

// Função para identificar o tipo de rede social
const getSocialMediaType = (link) => {
   if (link.includes('linkedin.com')) return 'linkedin'
   if (link.includes('facebook.com')) return 'facebook'
   if (link.includes('instagram.com')) return 'instagram'
   if (link.includes('twitter.com')) return 'twitter'
   if (link.includes('tiktok.com')) return 'tiktok'
   if (
      link.includes('youtube.com') ||
      link.includes('youtu.be') ||
      link.includes('@')
   )
      return 'youtube'
   if (link.includes('discord.com')) return 'discord'
   if (link.includes('pinterest.com')) return 'pinterest'
   if (link.includes('reddit.com')) return 'reddit'
   if (link.includes('snapchat.com')) return 'snapchat'
   if (link.includes('telegram.org')) return 'telegram'
   if (link.includes('twitch.tv')) return 'twitch'
   if (link.includes('medium.com')) return 'medium'
   if (link.includes('spotify.com')) return 'spotify'
   if (link.includes('vimeo.com')) return 'vimeo'
   return 'generic'
}
const SocialMediaLinks = () => {
   const {
      socialMediaLinks,
      setSocialMediaLinks,
      newLink,
      handleLinkChange,
      setNewLink,
   } = useDashboard()
   const { user } = React.useContext(UserContext)

   useEffect(() => {
      const fetchSocialMediaLinks = async () => {
         try {
            const response = await fetch(
               `${process.env.REACT_APP_API_URL}/users/${user.id}/socialMediaLinks`
            )
            if (!response.ok) {
               throw new Error('Erro ao buscar links do backend.')
            }
            const links = await response.json()
            setSocialMediaLinks(links) // Atualiza o estado local
         } catch (error) {
            console.error('Erro ao carregar links:', error)
         }
      }

      if (user.id) {
         fetchSocialMediaLinks()
      }
   }, [user.id, setSocialMediaLinks])

   const addLinkWithIcon = async () => {
      if (!newLink.trim()) return

      const normalizedLink = normalizeLink(newLink) // Normaliza o link
      const socialMediaType = getSocialMediaType(normalizedLink) // Identifica o tipo

      try {
         if (!user.id) {
            console.error('ID do usuário não está definido.')
            return
         }

         const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/socialMediaLinks`,
            {
               method: 'POST',
               headers: { 'Content-Type': 'application/json' },
               body: JSON.stringify({
                  type: socialMediaType,
                  link: normalizedLink,
               }), // Envia o link normalizado
            }
         )

         if (!response.ok) {
            throw new Error('Erro ao salvar link no backend.')
         }

         const updatedLinks = await response.json()
         setSocialMediaLinks(updatedLinks)
         setNewLink('')
      } catch (error) {
         console.error('Erro ao adicionar link:', error)
      }
   }

   const removeLink = async (linkId) => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/socialMediaLinks/${linkId}`,
            {
               method: 'DELETE',
            }
         )

         if (!response.ok) {
            throw new Error('Erro ao remover link no backend.')
         }

         const updatedLinks = await response.json()
         setSocialMediaLinks(updatedLinks)
      } catch (error) {
         console.error('Erro ao remover link:', error)
      }
   }
   const normalizeLink = (link) => {
      if (link.startsWith('@')) {
         // Caso o link seja apenas um @username, assume-se que é do YouTube
         return `https://www.youtube.com/${link}`
      }
      if (link.includes('youtube.com/@')) {
         // Verificar se o formato do link já é válido para YouTube
         return link
      }
      // Adicionar mais regras de normalização, se necessário
      return link // Retorna o link original se já estiver correto
   }

   return (
      <div className={css.socialLinksSection}>
         <h3 className={css.titulosH}>Links das Redes Sociais:</h3>
         <InputText
            type="text"
            value={newLink}
            onChange={handleLinkChange}
            placeholder="Adicionar novo link"
         />
         <Button
            label="Adicionar Rede Social"
            onClick={addLinkWithIcon}
            className={css.saveButton}
         />
         <div className={css.socialLinksList} style={{ marginTop: '2rem' }}>
            {socialMediaLinks.map((link, index) => (
               <div key={index} className={css.socialLinkItem}>
                  <a
                     href={link.link}
                     target="_blank"
                     rel="noopener noreferrer"
                     className={css.socialLink}
                  >
                     <i
                        className={`${
                           socialMediaIcons[link.type] ||
                           socialMediaIcons.generic
                        }`}
                     ></i>
                  </a>
                  <Button
                     icon="pi pi-trash"
                     className={css.removeButton}
                     onClick={() => removeLink(link._id)}
                     tooltip="Remover link"
                  />
               </div>
            ))}
         </div>
      </div>
   )
}

export default SocialMediaLinks
