import React from 'react'
import styles from './IncomeExpenseRatio.module.css'
import { useExpenses } from '../../../../../../../../../context/ExpensesContext'

const IncomeExpenseRatio = () => {
   const { income, expenses, selectedMonth } = useExpenses()

   // Get the total income for the selected month
   const currentIncome = income[selectedMonth] || 0

   // Calculate total expenses for the selected month
   const currentExpenses = expenses[selectedMonth]
      ? expenses[selectedMonth].categories.reduce((total, category) => {
           // Ensure category.subcategories is defined and is an array
           return (
              total +
              (Array.isArray(category.subcategories)
                 ? category.subcategories.reduce(
                      (subTotal, subcategory) =>
                         subTotal + (subcategory.amount || 0),
                      0
                   )
                 : 0)
           )
        }, 0)
      : 0

   // Calculate percentage of income taken by expenses
   const percentage = currentIncome
      ? ((currentExpenses / currentIncome) * 100).toFixed(2)
      : 0

   return (
      <div className={styles.box}>
         <h3 className={styles.titulo}>
            Percentagem de Orçamento gasto em Despesas
         </h3>
         <p className={styles.percentagem}>{percentage}% </p>
      </div>
   )
}

export default IncomeExpenseRatio
