import React from 'react'
import { Row, Col } from 'reactstrap'
import WelcomeVideoCreatorCard from './WelcomeVideoManagementComponents/WelcomeVideoCreatorCard'
import WelcomeVideoCreatorPage from './WelcomeVideoManagementComponents/WelcomeVideoCreatorPage'
import css from './UserVideosDisplay.module.css'

function WelcomeVideoSection() {
   return (
      <Row className={css.container}>
         <Col>
            <WelcomeVideoCreatorCard />
         </Col>
         <Col>
            <WelcomeVideoCreatorPage />
         </Col>
      </Row>
   )
}

export default WelcomeVideoSection
