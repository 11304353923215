import React from 'react'
import '../Navbar.css'
import { FaEdit, FaSignOutAlt } from 'react-icons/fa'

const UserActions = ({ onEdit, onLogout }) => {
   return (
      <div>
         <FaEdit
            onClick={onEdit}
            className="navBarIcons"
            title="Editar perfil"
         />
         <FaSignOutAlt
            onClick={onLogout}
            className="navBarIcons"
            title="Logout"
         />
      </div>
   )
}

export default UserActions
