import React, { Component } from 'react'
import './DebtRankingList.css' // Import the CSS for grid styles

class DebtRankingList extends Component {
   constructor(props) {
      super(props)
   }

   render() {
      const { rankedDebts, monthlyBreakdown } = this.props

      return (
         <div>
            <div className="debt-grid">
               {rankedDebts.map((debt, index) => {
                  const principalPaid = parseFloat(
                     debt.totalPrincipalPaid || 0
                  ).toFixed(2)
                  const interestPaid = parseFloat(
                     debt.totalInterestPaid || 0
                  ).toFixed(2)
                  const totalPayments = parseFloat(
                     debt.totalPayments || 0
                  ).toFixed(2)

                  const monthsDisplay =
                     debt.monthsToPayOff > 0
                        ? `${debt.monthsToPayOff} months`
                        : debt.normalMonthsToPayOff > 0
                        ? `${debt.normalMonthsToPayOff} months`
                        : 'Calculating...'

                  return (
                     <div
                        key={index}
                        className="debt-item"
                        style={{ marginTop: '20px' }}
                     >
                        <h3>{debt.name}</h3>
                        <div
                           className="payment-breakdown"
                           style={{ padding: '10px' }}
                        >
                           <div>
                              Quantia Total:{' '}
                              {(debt.originalBalance || 0).toFixed(2)}€
                           </div>
                           <div>
                              Taxa de Juros:{' '}
                              {debt.interestRate ? debt.interestRate : 0}%
                           </div>
                           <div>Meses até terminar: {monthsDisplay}</div>
                        </div>

                        <div className="payment-breakdown">
                           <h3>Detalhe dos Pagamentos:</h3>
                           <div style={{ marginTop: '10px', padding: '10px' }}>
                              <div>Total a pagar: {principalPaid}€</div>
                              <div>Juros Pagos: {interestPaid}€</div>
                              <div>Total Pago: {totalPayments}€</div>
                           </div>
                        </div>
                     </div>
                  )
               })}
            </div>

            <h2>Pagamento Mensal Detalhado</h2>
            <div
               className="monthly-breakdown-grid"
               style={{ marginTop: '20px' }}
            >
               {monthlyBreakdown?.map((monthDetail, monthIndex) => (
                  <div key={monthIndex} className="month-item">
                     <div>
                        <strong>Mês {monthDetail.month}:</strong>
                     </div>
                     <ul>
                        {monthDetail.details.map((detail, detailIndex) => (
                           <li
                              key={detailIndex}
                              style={{ marginBottom: '10px' }}
                           >
                              <div style={{ marginBottom: '5px' }}>
                                 <strong>{detail.name}</strong>:
                              </div>
                              Dívida paga:{' '}
                              {(parseFloat(detail.principalPaid) || 0).toFixed(
                                 2
                              )}
                              €, <br />
                              Juros Pagos:{' '}
                              {(parseFloat(detail.interestPaid) || 0).toFixed(
                                 2
                              )}
                              €, <br />
                              Por pagar:{' '}
                              {(parseFloat(detail.balance) || 0).toFixed(2)}€
                           </li>
                        ))}
                     </ul>
                  </div>
               ))}
            </div>
         </div>
      )
   }
}

export default DebtRankingList
