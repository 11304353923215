import React from 'react'
import ExpensesNav from './ExpensesNav'
import { ProgressBar } from 'primereact/progressbar'
import { useExpenses } from '../../../../../../../../../context/ExpensesContext'
import MonthTabs from './MonthTabs'

const ExpensesSummary = () => {
   const {
      categories = [],
      expenses = {},
      selectedMonth, // Get the selected month from context
      setSelectedMonth, // Get the function to update the selected month
   } = useExpenses()

   // Helper function to calculate the total actual expenses for a category
   const calculateCategoryActualExpenses = (category) => {
      const currentMonthExpenses = expenses[selectedMonth] // Use the dynamically selected month

      if (!currentMonthExpenses || !currentMonthExpenses.categories) {
         console.log('No expenses found for the current month.')
         return 0
      }

      const categoryExpenses = currentMonthExpenses.categories.find(
         (exp) => exp.id === category.id
      )

      if (!categoryExpenses) {
         console.log(
            `No matching expenses found for category ID: ${category.id}`
         )
         return 0
      }

      const totalActual = categoryExpenses.subcategories.reduce(
         (sum, subcategory) => sum + (subcategory.amount || 0),
         0
      )
      console.log(
         `Total actual expenses for category "${category.name}":`,
         totalActual
      )
      return totalActual
   }

   // Extract budget for each category
   const getCategoryBudget = (categoryId) => {
      const currentMonthExpenses = expenses[selectedMonth]

      if (!currentMonthExpenses || !currentMonthExpenses.categories) {
         console.log('No categories found for the current month.')
         return 0
      }

      const category = currentMonthExpenses.categories.find(
         (cat) => cat.id === categoryId
      )

      return category ? category.budget || 0 : 0
   }

   return (
      <div style={{ marginTop: '20px' }}>
         <ExpensesNav />
         {/* Include MonthTabs and pass selectedMonth and setSelectedMonth */}
         <MonthTabs
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
         />
         <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>
            Orçamento vs. Gastos Reais
         </h3>
         <ul
            style={{
               display: 'grid',
               gridTemplateColumns: 'repeat(4, 1fr)',
               gridGap: '20px',
               padding: '0',
               listStyle: 'none',
            }}
         >
            {Array.isArray(categories) && categories.length > 0 ? (
               categories.map((category) => {
                  const actual = calculateCategoryActualExpenses(category)
                  const budget = getCategoryBudget(category.id)
                  const percentage =
                     budget > 0 ? ((actual / budget) * 100).toFixed(2) : 0

                  return (
                     <li
                        key={category.id}
                        style={{
                           backgroundColor: '#222',
                           padding: '20px',
                           borderRadius: '8px',
                           boxShadow: '0px 2px 8px rgba(255, 255, 255, 0.5)',
                        }}
                     >
                        <strong>{category.name}:</strong>
                        <br />
                        Orçamento = {budget.toFixed(2)} €
                        <br />
                        Gasto = {actual.toFixed(2)} €
                        <br />
                        {budget > 0 && (
                           <span>
                              Diferença = €{(budget - actual).toFixed(2)}
                           </span>
                        )}
                        <ProgressBar
                           value={percentage}
                           style={{
                              height: '20px',
                              width: '100%',
                              marginTop: '10px',
                           }}
                           className={
                              percentage > 100
                                 ? 'progress-bar-danger'
                                 : 'progress-bar'
                           }
                        />
                        {percentage > 100 && (
                           <div>Budget exceeded by {percentage - 100}%!</div>
                        )}
                     </li>
                  )
               })
            ) : (
               <li>No categories available.</li>
            )}
         </ul>
      </div>
   )
}

export default ExpensesSummary
