import React from 'react'
import { NavLink } from 'react-router-dom'

const SavingsNav = () => {
   return (
      <div
         style={{
            display: 'flex',
            marginTop: '10px',
            marginBottom: '10px',
         }}
      >
         <NavLink
            to="/dashboard/regular/financialHealth/budget/savings"
            style={({ isActive }) => ({
               padding: '10px 15px',
               borderRadius: '5px',
               textDecoration: 'none',
               color: isActive ? 'black' : 'white',
               backgroundColor: isActive ? 'white' : 'transparent',
               borderBottom: isActive ? '2px solid white' : 'none',
               marginRight: '10px',
            })}
         >
            Poupanças
         </NavLink>

         <NavLink
            to="/dashboard/regular/financialHealth/budget/savings/categories"
            style={({ isActive }) => ({
               padding: '10px 15px',
               borderRadius: '5px',
               textDecoration: 'none',
               color: isActive ? 'black' : 'white',
               backgroundColor: isActive ? 'white' : 'transparent',
               borderBottom: isActive ? '2px solid white' : 'none',
               marginRight: '10px',
            })}
         >
            Gerir Categorias
         </NavLink>

         <NavLink
            to="/dashboard/regular/financialHealth/budget/savings/goals"
            style={({ isActive }) => ({
               padding: '10px 15px',
               borderRadius: '5px',
               textDecoration: 'none',
               color: isActive ? 'black' : 'white',
               backgroundColor: isActive ? 'white' : 'transparent',
               borderBottom: isActive ? '2px solid white' : 'none',
               marginRight: '10px',
            })}
         >
            Define as Tuas Metas
         </NavLink>
      </div>
   )
}

export default SavingsNav
