import React from 'react'
import { Col, Row } from 'reactstrap'
import ArticleItem from './ArticleItem'
import css from './Articles.module.css'

function ArticlesGrid({ articles, onEditArticle, fetchArticles, onDelete }) {
   return (
      <div className={css.articlesContainer}>
         {articles.map((article, index) => (
            <Row key={article._id || index}>
               <Col>
                  <ArticleItem
                     key={article._id}
                     article={article}
                     onEditArticle={onEditArticle}
                     fetchArticles={fetchArticles}
                     onDelete={onDelete}
                  />
               </Col>
            </Row>
         ))}
      </div>
   )
}

export default ArticlesGrid
