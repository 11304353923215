import React from 'react'
import './ProgressSpinner.css' // This will be your custom CSS file for styling the spinner

const ProgressSpinner = () => (
   <div className="spinner-container">
      <div className="spinner"></div>
   </div>
)

export default ProgressSpinner
