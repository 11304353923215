// components/debtsFormComponents/MonthTabs.js
import React, { useContext } from 'react'
import styles from '../../IncomeForm.module.css'
import { DebtsContext } from '../../../../../../../../../context/DebtsContext'

const MonthTabs = () => {
   const { selectedMonth, setSelectedMonth } = useContext(DebtsContext)
   const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
   ]
   const currentMonth = new Date().getMonth()

   return (
      <div className={styles.monthTabs}>
         {months.slice(0, currentMonth + 1).map((month, i) => (
            <div
               key={i}
               className={selectedMonth === i ? styles.activeTab : styles.tab}
               onClick={() => setSelectedMonth(i)}
            >
               {month}
            </div>
         ))}
      </div>
   )
}

export default MonthTabs
