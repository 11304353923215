// DebtInput.jsx
import React from 'react'
import { Col, Row } from 'reactstrap'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Controller } from 'react-hook-form'
import { ProgressBar } from 'primereact/progressbar'
import InterestEstimator from './debtSimulations/InterestEstimator'

const DebtInput = ({ category, control, getValues, index }) => {
   const handleNumberChange = (e, field) => {
      const { value } = e.target
      // Allow numbers and decimals
      const validValue =
         value === '' || /^[0-9]*\.?[0-9]*$/.test(value) ? value : field.value
      field.onChange(validValue)
   }

   const balance = parseFloat(getValues(`debts[${index}].amount`)) || 0
   const remainingBalance =
      parseFloat(getValues(`debts[${index}].remainingBalance`)) || 0
   const amountPaidOff = balance - remainingBalance
   const progress = balance ? ((amountPaidOff / balance) * 100).toFixed(2) : 0
   const interestRate =
      parseFloat(getValues(`debts[${index}].interestRate`)) || 0
   console.log('Debt Progress:', {
      debtName: category.name,
      totalAmount: balance,
      remainingBalance,
      amountPaidOff,
      interestRate,
      progress: `${progress}%`,
   })

   return (
      <Row
         xs={3}
         key={category.id}
         style={{
            marginTop: '10px',
            boxShadow: '0 0 5px #ccc',
            padding: '10px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '15px',
         }}
      >
         <h3>{category.name}:</h3>
         <Controller
            name={`debts[${index}].amount`}
            control={control}
            render={({ field }) => (
               <InputText
                  type="text"
                  placeholder="Total Amount (€)"
                  value={field.value ? parseFloat(field.value).toFixed(2) : ''}
                  onChange={(e) => handleNumberChange(e, field)}
               />
            )}
         />
         <Controller
            name={`debts[${index}].remainingBalance`}
            control={control}
            render={({ field }) => (
               <InputText
                  type="text"
                  placeholder="Remaining Balance (€)"
                  value={field.value}
                  onChange={(e) => handleNumberChange(e, field)}
               />
            )}
         />
         <Controller
            name={`debts[${index}].interestRate`}
            control={control}
            render={({ field }) => (
               <InputText
                  type="text"
                  placeholder="Interest Rate (%)"
                  value={field.value}
                  onChange={(e) => handleNumberChange(e, field)}
               />
            )}
         />
         <Controller
            name={`debts[${index}].payment`}
            control={control}
            render={({ field }) => (
               <InputText
                  type="text"
                  placeholder="Minimum Payment (€)"
                  value={field.value}
                  onChange={(e) => handleNumberChange(e, field)}
               />
            )}
         />
         <Controller
            name={`debts[${index}].dueDate`}
            control={control}
            render={({ field }) => (
               <Calendar
                  {...field}
                  value={field.value}
                  placeholder="Due Date"
                  showIcon
                  style={{ width: '320px', height: '50px' }} // Adjust width and height as needed
               />
            )}
         />
         <div>
            <InterestEstimator
               totalAmount={balance}
               remainingBalance={remainingBalance}
               interestRate={
                  parseFloat(getValues(`debts[${index}].interestRate`)) || 0
               }
            />

            {balance > 0 && (
               <div
                  style={{
                     marginTop: '10px',
                     boxShadow: '0 0 5px #ccc',
                     padding: '10px',
                     borderRadius: '5px',
                  }}
               >
                  <p style={{ marginBottom: '5px' }}>Progresso: {progress}%</p>
                  <ProgressBar
                     value={progress}
                     showValue
                     style={{ height: '20px' }}
                  />
               </div>
            )}
         </div>
      </Row>
   )
}

export default DebtInput
