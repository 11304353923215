import React from 'react'
import styles from './IncomeForm.module.css'
import ExpensesDetails from './components/expensesFormComponents/ExpensesDetails'
import SaveUpdateButtons from './components/expensesFormComponents/SaveUpdateButtons'
import { useExpenses } from '../../../../../../../context/ExpensesContext'
import MonthTabs from './components/expensesFormComponents/MonthTabs'
import ExpensesNav from './components/expensesFormComponents/ExpensesNav'
import IncomeExpenseRatio from './components/expensesFormComponents/IncomeExpenseRatio'

const ExpensesForm = () => {
   const {
      expenses,
      categories = [], // Default to an empty array to avoid issues
      actualExpenses = {}, // Default to an empty object
      budgets,
      selectedMonth,
      submissionMessage,
      isEditing,
      setSelectedMonth,
      handleSaveOrUpdate,
      handleDelete,
      handleChange,
      handleBudgetChange,
      handleWhatIfChange,
   } = useExpenses()

   return (
      <div className={styles.container}>
         <ExpensesNav />

         <h2 className={styles.title}>Preencha as tuas Despesas</h2>
         {submissionMessage && (
            <p className={styles.submissionMessage}>{submissionMessage}</p>
         )}

         <MonthTabs
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
         />
         <IncomeExpenseRatio />
         <div className={styles.expenseForm}>
            <ExpensesDetails
               categories={categories}
               expenses={expenses}
               actualExpenses={actualExpenses}
               budgets={budgets}
               selectedMonth={selectedMonth}
               handleChange={handleChange}
               handleBudgetChange={handleBudgetChange}
               handleWhatIfChange={handleWhatIfChange}
            />
         </div>

         <SaveUpdateButtons
            isEditing={isEditing}
            handleSaveOrUpdate={handleSaveOrUpdate}
            handleDelete={handleDelete}
         />
      </div>
   )
}

export default ExpensesForm
