import React, { useContext, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { UserContext } from '../../../../../../../../../context/useUserContext'
import axios from 'axios'
import { getBarChartOptions } from './chartConfig'

const SavingsBarChart = ({ selectedMonth }) => {
   const { user } = useContext(UserContext)
   const userId = user?.id
   const [savingsByMonth, setSavingsByMonth] = useState([])
   // const selectedMonth = new Date().getMonth()

   useEffect(() => {
      const fetchSavingsData = async () => {
         try {
            const url = `${process.env.REACT_APP_API_URL}/savingRoutes/user/${userId}/yearly-savings`
            const response = await axios.get(url, {
               headers: {
                  Authorization: `Bearer ${user.accessToken}`,
               },
            })
            console.log('response SavingsBarChart', response)
            setSavingsByMonth(
               response.data.savings[selectedMonth]?.categories || []
            )
         } catch (err) {
            console.error('Error fetching savings data:', err)
         }
      }

      fetchSavingsData()
   }, [userId, selectedMonth])

   const chartData = {
      labels: savingsByMonth.map((category) => category.name),
      datasets: [
         {
            label: 'Savings Breakdown',
            data: savingsByMonth.map((category) => category.savings || 0),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            borderColor: 'white',
            borderWidth: 1,
         },
      ],
   }

   return <Bar data={chartData} options={getBarChartOptions()} />
}

export default SavingsBarChart
