import React from 'react'
import { Row } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import css from '../../.././../Layout.module.css' // Adjust the path as needed

const Playlist = () => {
   return (
      <>
         <Row className={css.tabContainer2}>
            <nav className={css.tabNavigation1}>
               <NavLink
                  to="myPlaylists" // Relative path from /playlists
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Minhas Playlist
               </NavLink>
               <NavLink
                  to="create" // Relative path from /playlists
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Criar Playlist
               </NavLink>
               <NavLink
                  to="manage" // Relative path from /playlists
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Gerir Playlists
               </NavLink>
            </nav>
         </Row>
         <div className={css.content}>
            <Outlet />
         </div>
      </>
   )
}

export default Playlist
