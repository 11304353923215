// useCourseForm.js
import { useFormik } from 'formik'
import { validationSchema, getInitialValues } from './courseFormSchema'
import { toast } from 'react-toastify'

const useCourseForm = (
   existingCourse,
   createCourse,
   handleUpdateCourse,
   onClose
) => {
   const formik = useFormik({
      initialValues: getInitialValues(existingCourse),
      validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
         const formData = new FormData()
         Object.entries(values).forEach(([key, value]) => {
            formData.append(
               key,
               value instanceof File ? value : value.toString()
            )
         })

         try {
            if (existingCourse) {
               await handleUpdateCourse(existingCourse._id, formData)
               toast.success('Course updated successfully!')
            } else {
               await createCourse(formData)
               toast.success('Course added successfully!')
            }
            onClose()
         } catch (error) {
            console.error('Failed to process course:', error)
            toast.error(
               `Failed to ${existingCourse ? 'update' : 'add'} course.`
            )
         } finally {
            setSubmitting(false)
         }
      },
   })

   return formik
}

export default useCourseForm
