import React, { createContext, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { UserContext } from './useUserContext'

export const SavingsContext = createContext()

export const SavingsProvider = ({ children }) => {
   const [savings, setSavings] = useState([]) // Initialize savings
   const [categories, setCategories] = useState([])
   const [emergencyFundAmount, setEmergencyFundAmount] = useState(0)
   const [savingsGoals, setSavingsGoals] = useState({}) // Initialize savingsGoals as an empty object
   const { user } = useContext(UserContext)
   const userId = user?.id
   const defaultCategories = [
      { id: '1', name: 'Fundo de Emergência', savings: 0, goal: 0 },
      { id: '2', name: 'Fundo de Aposentadoria', savings: 0, goal: 0 },
      { id: '3', name: 'Poupança para Educação', savings: 0, goal: 0 },
      { id: '4', name: 'Poupança Geral', savings: 0, goal: 0 },
   ]
   useEffect(() => {
      if (userId) {
         fetchCategories()
      }
   }, [userId])

   const fetchCategories = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}users/${userId}/savings-categories`,
            {
               headers: {
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )
         console.log('Fetched categories:', response.data)
         // Check if response data is an array and non-empty
         const categoriesData =
            Array.isArray(response.data) && response.data.length > 0
               ? response.data
               : defaultCategories

         setCategories(categoriesData)
      } catch (error) {
         console.error('Error fetching categories:', error)
         setCategories(defaultCategories) // Fallback to default if error occurs
      }
   }

   return (
      <SavingsContext.Provider
         value={{
            savings,
            categories,
            savingsGoals,
            setSavingsGoals,
            setSavings,
            setCategories,
            emergencyFundAmount,
            setEmergencyFundAmount,
         }}
      >
         {children}
      </SavingsContext.Provider>
   )
}
