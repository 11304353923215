import React from 'react'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import css from '../../../DashboardRegular.module.css'

const SubscriptionCard = ({ onUpgradeClick }) => {
   return (
      <Card
         className={`${css.card} ${css.alinhaDadosCreator}`}
         title="As suas subscrições"
         style={{ color: 'white' }}
      >
         <p>Não tem plano de subscrição ativo.</p>
         <Button
            label="Upgrade para Premium"
            className="p-button-primary"
            style={{ marginTop: '1rem' }}
            onClick={onUpgradeClick}
         />
      </Card>
   )
}

export default SubscriptionCard
