import React, { useEffect, useContext } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import axios from 'axios'
import { UserContext } from '../../../../../../../../../context/useUserContext'
import { SavingsContext } from '../../../../../../../../../context/SavingsContext'
import SavingsNav from './SavingsNav'
import { Col, Row } from 'reactstrap'

const SavingsGoalsManager = () => {
   const { categories, savingsGoals, setSavingsGoals } =
      useContext(SavingsContext)
   const { user } = useContext(UserContext)
   const userId = user.id

   useEffect(() => {
      const fetchSavingsGoals = async () => {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}/savingRoutes/user/${userId}/goals`,
               {
                  headers: {
                     Authorization: `Bearer ${user.accessToken}`,
                  },
               }
            )
            const goals = response.data.reduce((acc, goal) => {
               acc[goal.categoryId] = goal.goalAmount
               return acc
            }, {})
            setSavingsGoals(goals)
         } catch (error) {
            console.error('Error fetching savings goals:', error)
         }
      }

      fetchSavingsGoals()
   }, [userId, user.accessToken, setSavingsGoals])

   const handleGoalChange = (e) => {
      const { name, value } = e.target
      setSavingsGoals((prevGoals) => ({
         ...prevGoals,
         [name]: parseFloat(value) || 0,
      }))
   }

   const handleSaveGoals = async () => {
      try {
         for (let category of categories) {
            const goalData = {
               userId: user.id,
               categoryId: category.id,
               goalAmount: savingsGoals[category.id] || 0,
            }

            await axios.post(
               `${process.env.REACT_APP_API_URL}/savingRoutes/user/${userId}/goals`,
               goalData,
               {
                  headers: {
                     Authorization: `Bearer ${user.accessToken}`,
                  },
               }
            )
         }

         alert('Savings goals saved successfully!')
      } catch (error) {
         console.error('Error saving savings goals:', error)
         alert('An error occurred while saving the savings goals.')
      }
   }

   return (
      <div>
         <SavingsNav />

         <h2>Define as Tuas Metas</h2>
         <Row>
            <Col xs={3}>
               {categories.map((category) => (
                  <div key={category.id}>
                     <label>Meta para o {category.name}:</label>
                     <InputText
                        type="number"
                        name={category.id}
                        value={savingsGoals?.[category.id] || ''}
                        onChange={handleGoalChange}
                        style={{ width: '100%', marginTop: '5px' }}
                     />
                  </div>
               ))}
            </Col>
         </Row>
         <Button onClick={handleSaveGoals} size="small" className="mt-3">
            Guardar Objetivos
         </Button>
      </div>
   )
}

export default SavingsGoalsManager
