// useUserNavigation.js
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../context/useUserContext'

const useUserNavigation = () => {
   const { user } = useContext(UserContext)
   const navigate = useNavigate()

   // Navega para o login
   const navigateToLogin = () => {
      console.log('Navigating to login...')
      navigate('/login') // Certifique-se de que '/login' é a rota correta
   }

   // Navega para o dashboard com base no tipo de usuário
   const navigateToDashboard = () => {
      if (!user) {
         console.warn('User not found. Redirecting to login...')
         navigate('/login')
         return
      }

      let path = ''
      switch (user.role) {
         case 'RegularUser':
            path = user.isPremium
               ? '/dashboard/premium'
               : '/dashboard/regular/favorites'
            break
         case 'CreatorUser':
            path = '/dashboard/creator'
            break
         case 'Mod':
            path = '/dashboard/moderator'
            break
         case 'AdminUser':
            path = '/dashboard/AdminUser'
            break
         default:
            path = '/login'
            break
      }
      console.log(`Navigating to dashboard: ${path}`)
      navigate(path)
   }

   return { navigateToDashboard, navigateToLogin }
}

export default useUserNavigation
