import React from 'react'
import { FileItem } from './FileItem'
import css from './FilesGrid.module.css'

export const FilesGrid = ({
   files,
   onDelete,
   showActions = true,
   fetchFiles,
}) => {
   // Handle empty or undefined files gracefully
   if (!files || files.length === 0) {
      return (
         <div className={css.placeholder}>
            Nenhum arquivo disponível no momento.
         </div>
      )
   }

   return (
      <div className={css.filesGridContainer}>
         {files.map((file) => (
            <FileItem
               key={file._id}
               file={file}
               onDelete={onDelete}
               showActions={showActions}
               fetchFiles={fetchFiles}
            />
         ))}
      </div>
   )
}
