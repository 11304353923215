import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import css from './EventForm.module.css'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { Checkbox } from 'primereact/checkbox'
import { UserContext } from '../../../../../../context/useUserContext'

const EventForm = ({ eventData, onSave, onCancel }) => {
   const { user } = React.useContext(UserContext)
   const [eventType, setEventType] = useState('presencial')
   const isAdmin = user.role
   const [imagePreview, setImagePreview] = useState(null)
   const locationOptions = [
      { label: 'Online', value: 'online' },
      { label: 'Presencial', value: 'presencial' },
   ]
   const eventCategories = [
      { label: 'Finanças Pessoais', value: 'Finanças Pessoais' },
      { label: 'Investimentos', value: 'Investimentos' },
      { label: 'Contabilidade', value: 'Contabilidade' },
      { label: 'Other', value: 'Other' },
   ]
   const accessibilityOptions = [
      { label: 'Wheelchair Access', value: 'Wheelchair Access' },
      {
         label: 'Sign Language Interpreter',
         value: 'Sign Language Interpreter',
      },
      { label: 'Other', value: 'Other' },
   ]
   const [formState, setFormState] = useState({
      title: '',
      description: '',
      startTime: null,
      endTime: null,
      locationType: '',
      eventCategory: '',
      expectedAttendees: '',
      accessibilityOptions: [],
      contactEmail: '',
      contactPhone: '',
      termsAccepted: false,
      registrationLink: '',
      onlineLink: '',
      address: '',
      eventCreatorName: '',
      coverImage: null,
   })

   useEffect(() => {
      if (eventData) {
         const newState = {
            ...formState,
            title: eventData.title || '',
            description: eventData.description || '',
            startTime: eventData.start ? new Date(eventData.start) : null, // Ensure this is a Date or null
            endTime: eventData.end ? new Date(eventData.end) : null,
            locationType: eventData?.locationType || 'Online',
            registrationLink: eventData?.registrationLink || '',
            onlineLink: eventData?.onlineLink || '',
            address: eventData?.address || '',
            eventCreatorName: eventData?.eventCreatorName || '',
            eventCategory: eventData?.eventCategory || '',
            expectedAttendees: eventData?.expectedAttendees || '',
            accessibilityOptions: eventData.accessibilityOptions || [],
            contactEmail: eventData?.contactEmail || '',
            contactPhone: eventData?.contactPhone || '',
            termsAccepted: eventData?.termsAccepted || false,
            coverImage: eventData?.coverImage || null,
         }

         setFormState(newState)

         if (eventData.coverImage && typeof eventData.coverImage === 'string') {
            setImagePreview(eventData.coverImage)
         }
      } else {
         setFormState({
            title: '',
            description: '',
            startTime: null, // Use null for Date objects when resetting
            endTime: null,
            locationType: '',
            registrationLink: '',
            onlineLink: '',
            address: '',
            eventCreatorName: '',
            eventCategory: '',
            expectedAttendees: '',
            accessibilityOptions: [],
            contactEmail: '',
            contactPhone: '',
            termsAccepted: false,
            coverImage: null,
         })
         setImagePreview(null) // Also reset the image preview
      }
   }, [eventData])

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormState((prev) => ({ ...prev, [name]: value }))
   }

   const handleSubmit = (e) => {
      e.preventDefault()
      if (!formState.termsAccepted && isAdmin !== 'AdminUser') {
         alert('Please accept the terms and conditions.')
         return
      }

      const formData = new FormData()
      // Append all text or select input fields
      formData.append('title', formState.title)
      formData.append('description', formState.description)
      formData.append('locationType', formState.locationType)
      formData.append('eventCategory', formState.eventCategory)
      formData.append('expectedAttendees', formState.expectedAttendees)
      formData.append('contactEmail', formState.contactEmail)
      formData.append('contactPhone', formState.contactPhone)
      formData.append('eventCreatorName', formState.eventCreatorName)
      formData.append('registrationLink', formState.registrationLink)
      formData.append('onlineLink', formState.onlineLink)
      formData.append('address', formState.address)
      formData.append('termsAccepted', formState.termsAccepted.toString())
      formData.append('eventCreator', user.id)

      // Convert Date objects to ISO strings for the server
      formData.append(
         'startTime',
         formState.startTime ? formState.startTime.toISOString() : ''
      )
      formData.append(
         'endTime',
         formState.endTime ? formState.endTime.toISOString() : ''
      )

      // Append file if it's available
      if (formState.coverImage) {
         formData.append(
            'coverImage',
            formState.coverImage,
            formState.coverImage.name
         )
      }

      // Handle MultiSelect fields if necessary
      if (formState.accessibilityOptions.length > 0) {
         formData.append(
            'accessibilityOptions',
            formState.accessibilityOptions.join(',')
         )
      }

      // Optionally log formData for debugging
      for (let [key, value] of formData.entries()) {
         console.log(`${key}: ${value}`)
      }

      onSave(formData)
   }

   const handleFileChange = (e) => {
      const file = e.target.files[0]
      if (file) {
         setFormState((prev) => ({
            ...prev,
            coverImage: file,
         }))
         const reader = new FileReader()
         reader.onloadend = () => {
            setImagePreview(reader.result)
         }
         reader.readAsDataURL(file)
      }
   }
   const handleLocationChange = (e) => {
      setEventType(e.value)
      setFormState((prev) => ({
         ...prev,
         locationType: e.value,
         address: e.value === 'presencial' ? prev.address : '',
         onlineLink: e.value === 'online' ? prev.onlineLink : '',
      }))
   }

   const handleTimeChange = (e, timeType) => {
      setFormState((prevState) => ({
         ...prevState,
         [timeType]: e.value,
      }))
   }
   const renderExternalFields = () => {
      if (isAdmin !== 'AdminUser') {
         return (
            <>
               <div className={css.p_field}>
                  <div htmlFor="contactEmail">Contact Email:</div>
                  <InputText
                     id="contactEmail"
                     name="contactEmail"
                     value={formState.contactEmail}
                     onChange={handleChange}
                     required
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="contactPhone">Contact Phone:</div>
                  <InputText
                     id="contactPhone"
                     name="contactPhone"
                     value={formState.contactPhone}
                     onChange={handleChange}
                     required
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="termsAccepted">Agree to Terms:</div>
                  <Checkbox
                     inputId="termsAccepted"
                     checked={formState.termsAccepted}
                     onChange={(e) =>
                        setFormState({ ...formState, termsAccepted: e.checked })
                     }
                  />
                  <label htmlFor="termsAccepted">
                     I agree to the terms and conditions
                  </label>
               </div>
            </>
         )
      }
   }

   return (
      <form
         onSubmit={handleSubmit}
         className={css.eventForm}
         encType="multipart/form-data"
      >
         <h2>{eventData ? 'Editar Evento' : 'Criar Evento'}</h2>
         <Row style={{ display: 'flex' }}>
            <Col>
               <div className={css.p_field}>
                  <div htmlFor="title">Title:</div>
                  <InputText
                     id="title"
                     name="title"
                     value={formState.title}
                     onChange={handleChange}
                     required
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="description">Description:</div>
                  <InputTextarea
                     id="description"
                     name="description"
                     value={formState.description}
                     onChange={handleChange}
                     autoResize
                     required
                  />
               </div>

               <div className={css.p_field}>
                  <div htmlFor="startTime">Event Start:</div>
                  <Calendar
                     id="startTime"
                     name="startTime"
                     value={formState.startTime}
                     onSelect={(e) => handleTimeChange(e, 'startTime')}
                     showTime
                     showIcon
                     required
                     dateFormat="dd/mm/yy"
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="endTime">Event End:</div>
                  <Calendar
                     id="endTime"
                     name="endTime"
                     value={formState.endTime}
                     onSelect={(e) => handleTimeChange(e, 'endTime')}
                     showTime
                     showIcon
                     required
                     dateFormat="dd/mm/yy"
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="registrationLink">Registration Link:</div>
                  <InputText
                     id="registrationLink"
                     name="registrationLink"
                     value={formState.registrationLink}
                     onChange={handleChange}
                     required
                  />
               </div>
            </Col>
            <Col>
               <div className={css.p_field}>
                  <div htmlFor="location">Location Type:</div>
                  <Dropdown
                     id="locationType"
                     name="locationType"
                     value={formState.locationType}
                     options={locationOptions}
                     onChange={handleLocationChange}
                     placeholder="Select a Location Type"
                  />
               </div>
               {eventType === 'presencial' ? (
                  <div className={css.p_field}>
                     <div htmlFor="address">Address:</div>
                     <InputText
                        id="address"
                        name="address"
                        value={formState.address}
                        onChange={handleChange}
                        required={eventType === 'presencial'}
                     />
                  </div>
               ) : (
                  <div className={css.p_field}>
                     <div htmlFor="onlineLink">Live Link:</div>
                     <InputText
                        id="onlineLink"
                        name="onlineLink"
                        value={formState.onlineLink}
                        onChange={handleChange}
                        required={eventType === 'online'}
                     />
                  </div>
               )}
               <div className={css.p_field}>
                  <div htmlFor="eventCreatorName">Event Creator:</div>
                  <InputText
                     id="eventCreatorName"
                     name="eventCreatorName"
                     value={formState.eventCreatorName}
                     onChange={handleChange}
                     required
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="eventCategory">Event Category:</div>
                  <Dropdown
                     id="eventCategory"
                     name="eventCategory"
                     value={formState.eventCategory}
                     options={eventCategories}
                     onChange={handleChange}
                     placeholder="Select a Category"
                     required={isAdmin !== 'AdminUser'}
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="expectedAttendees">Expected Attendees:</div>
                  <InputText
                     id="expectedAttendees"
                     name="expectedAttendees"
                     value={formState.expectedAttendees}
                     onChange={handleChange}
                     required={isAdmin !== 'AdminUser'}
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="accessibilityOptions">
                     Accessibility Options:
                  </div>
                  <MultiSelect
                     id="accessibilityOptions"
                     name="accessibilityOptions"
                     value={formState.accessibilityOptions}
                     options={accessibilityOptions}
                     onChange={(e) =>
                        setFormState((prev) => ({
                           ...prev,
                           accessibilityOptions: e.value,
                        }))
                     }
                     optionLabel="label"
                     placeholder="Select options"
                  />
               </div>
               <div className={css.p_field}>
                  <div htmlFor="coverImage">Event Cover Image:</div>
                  <input
                     type="file"
                     id="coverImage"
                     name="coverImage"
                     onChange={handleFileChange}
                     required={!eventData?.extendedProps?.coverImage}
                  />
                  {imagePreview && (
                     <img
                        src={imagePreview}
                        alt="Preview"
                        style={{
                           maxWidth: 'auto',
                           marginTop: '10px',
                           height: '200px',
                        }}
                     />
                  )}
               </div>
            </Col>
         </Row>
         <Row>
            <Col>{renderExternalFields()}</Col>
         </Row>
         <Row className={css.formActions}>
            <Col className={css.formButtonCol}>
               <Button
                  type="submit"
                  label="Save Event"
                  className="p-button-success"
               />
               <Button
                  label="Cancel"
                  className="p-button-text"
                  onClick={onCancel}
               />
            </Col>
         </Row>
      </form>
   )
}

export default EventForm
