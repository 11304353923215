import React, { useState, useContext } from 'react'
import { InputText, Button, InputTextarea } from 'primereact'
import axios from 'axios'
import { toast } from 'react-toastify'
import css from '../WelcomeVideo.module.css'
import { UserContext } from '../../../../../../context/useUserContext'
import './DialogStyles.css'

const VideoLinkInput = ({ onLinkSubmit, fetchUserVideos, videoType }) => {
   const [videoLink, setVideoLink] = useState('')
   const [title, setTitle] = useState('')
   const [description, setDescription] = useState('')
   const { user } = useContext(UserContext)
   const [isLoading, setIsLoading] = useState(false)

   const handleSubmit = async (e) => {
      e.preventDefault()
      setIsLoading(true)

      try {
         const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}welcomeVideos/`,
            {
               title,
               description,
               videoLink,
               videoType, // Use the prop directly
               creator: user.id,
            }
         )

         await fetchUserVideos()
         onLinkSubmit(data.videoLink)
         setTitle('')
         setDescription('')
         setVideoLink('')

         toast.success('Video successfully uploaded!')
      } catch (error) {
         console.error('Error uploading video:', error)
         toast.error('Failed to upload video.')
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <div>
         <form onSubmit={handleSubmit}>
            <div className={css.displayFlex}>
               <InputText
                  value={videoLink}
                  onChange={(e) => setVideoLink(e.target.value)}
                  placeholder="Link do vídeo do YouTube"
                  required
               />
               <InputText
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Título"
                  required
               />
               <InputTextarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Descrição"
                  required
                  autoResize
               />
            </div>
            <Button
               style={{ marginTop: '20px' }}
               label="Submeter Vídeo"
               icon="pi pi-check"
               type="submit"
               disabled={isLoading}
               className="btnEditar"
            />
         </form>
      </div>
   )
}

export default VideoLinkInput
