import React from 'react'
import { Row, Col } from 'reactstrap'
import useWelcomeVideo from '../../../../../../hooks/useWelcomeVideo'
import VideoDisplay from './VideoDisplay'
import VideoLinkInput from './VideoLinkInput'
import UserVideosDisplay from './UserVideosDisplay'
import css from './WelcomeVideoCreatorPage.module.css' // Importar novo CSS
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
function WelcomeVideoCreatorPage() {
   const {
      currentVideo,
      welcomeVideoVisible,
      toggleVideoVisibility,
      userVideos,
      fetchUserVideos,
      setVideoLink,
   } = useWelcomeVideo('creatorPage')

   return (
      <Row className={css.container}>
         <Col className={css.videoSection}>
            <div className={css.checkboxLabel}>
               <Col sm={9} md={4}>
                  Mostrar vídeo de boas-vindas
               </Col>
               <Col md={1}>
                  <input
                     className={css.checkbox}
                     type="checkbox"
                     checked={welcomeVideoVisible}
                     onChange={toggleVideoVisibility}
                  />
               </Col>
            </div>
            <h3 className={css.title}>
               Vídeo de boas-vindas na Página Pública
            </h3>
            <div className={css.videoDisplay}>
               {currentVideo ? (
                  <VideoDisplay
                     key={currentVideo._id}
                     videoLink={currentVideo.videoLink}
                  />
               ) : (
                  <p className={css.noVideoText}>
                     Não tem um vídeo de boas-vindas. Por favor carregue um
                     vídeo do Youtube.
                  </p>
               )}
            </div>
         </Col>

         <Col className={css.addVideoSection}>
            <h3 className={css.title}>Adicionar novo vídeo de boas-vindas</h3>
            <VideoLinkInput
               userVideos={userVideos}
               fetchUserVideos={fetchUserVideos}
               onLinkSubmit={setVideoLink}
               videoType="creatorPage"
            />
         </Col>
         <Col className={css.manageVideosSection}>
            <h3 className={css.title}>Gerir Vídeos</h3>
            <UserVideosDisplay videoType="creatorPage" />
         </Col>
      </Row>
   )
}

export default WelcomeVideoCreatorPage
