import React, { useState } from 'react'
import axios from 'axios' // Assuming you are using axios for API requests

const FireAcc = () => {
   const [monthlyExpenses, setMonthlyExpenses] = useState(1000)
   const [yearsToGoal, setYearsToGoal] = useState(42)
   const [inflationRate, setInflationRate] = useState(2)

   const annualExpenses = monthlyExpenses * 12
   const fireValue = annualExpenses * 25 // 4% Rule

   // Inflation and tax adjustment
   const inflationImpact = Math.pow(1 + inflationRate / 100, yearsToGoal)
   const adjustedFireValue = fireValue * inflationImpact
   const fireValueAfterTax = adjustedFireValue / 0.72 // Adjusting for 28% tax

   const saveFireGoal = async () => {
      try {
         await axios.post(`${process.env.REACT_APP_API_URL}/fire-goal`, {
            fireGoal: fireValueAfterTax,
         })
         alert('FIRE Goal saved successfully!')
      } catch (error) {
         console.error('Error saving FIRE goal', error)
         alert('Failed to save FIRE goal')
      }
   }

   return (
      <div>
         <h2>Cálculo do FIRE - Regra dos 4%</h2>
         <label>
            Despesas Mensais:
            <input
               type="number"
               value={monthlyExpenses}
               onChange={(e) => setMonthlyExpenses(Number(e.target.value))}
            />
         </label>
         <br />
         <label>
            Meta atingida em quantos anos?:
            <input
               type="number"
               value={yearsToGoal}
               onChange={(e) => setYearsToGoal(Number(e.target.value))}
            />
         </label>
         <br />
         <label>
            Inflação anual média (%):
            <input
               type="number"
               value={inflationRate}
               onChange={(e) => setInflationRate(Number(e.target.value))}
            />
         </label>
         <br />
         <div>
            <h3>Ajuste à inflação e impostos</h3>
            <p>
               Valor do FIRE ajustado à inflação e impostos:{' '}
               {fireValueAfterTax.toFixed(2)} €
            </p>
            <button onClick={saveFireGoal}>Guardar FIRE Goal</button>
         </div>
      </div>
   )
}

export default FireAcc
