import React from 'react'
import { Col } from 'reactstrap'
import css from './Footer.module.css'
import { Link } from 'react-router-dom'

function Footer() {
   return (
      <>
         <div className={css.footer}>
            <Col md={3} className={css.logoSection}>
               <img
                  src={require('../assets/logo.png')}
                  alt="Logo"
                  className={css.logoImage}
               />
               <div className={css.branding}>
                  <span className={css.brandName}>+Riqueza</span>
                  <p>&copy; 2023 +Riqueza. All rights reserved.</p>
               </div>
            </Col>
            <Col md={9} className={css.footerNavContainer}>
               <nav className={css.footerNav}>
                  <ul>
                     <li>
                        <a href="/">Home</a>
                     </li>
                     <li>
                        <Link to="/aboutus">About</Link>
                     </li>
                     <li>
                        <a href="/contact">Contact Us</a>
                     </li>
                  </ul>
               </nav>
            </Col>
         </div>
         <div className={css.footerBottom}>
            <Col md={12} className={css.footerLinksContainer}>
               <div className={css.footerLinks}>
                  <a href="/cookie-policy">Cookie Policy</a>
                  <a href="/privacy-policy">Privacy Policy</a>
                  <a href="/terms-of-service">Terms of Service</a>
               </div>
            </Col>
         </div>
      </>
   )
}

export default Footer
