import React from 'react'
import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'
import { useExpenses } from '../../../../../../../../../context/ExpensesContext'
import ExpensesNav from './ExpensesNav'
import MonthTabs from './MonthTabs'
import ChartDataLabels from 'chartjs-plugin-datalabels' // Import datalabels plugin

const ExpensesCharts = () => {
   const { expenses, income, selectedMonth, setSelectedMonth } = useExpenses()

   const prepareChartData = () => {
      const currentExpenses = expenses[selectedMonth] || { categories: [] }
      const currentIncome = income[selectedMonth] || 0

      const labels = currentExpenses.categories.map((category) => category.name)

      const actualValues = currentExpenses.categories.map((category) =>
         category.subcategories.reduce((sum, sub) => sum + (sub.amount || 0), 0)
      )

      const budgetValues = currentExpenses.categories.map(
         (category) => category.budget || 0
      )

      // Repeat the income value for each category so it's displayed side by side
      const incomeValues = Array(currentExpenses.categories.length).fill(
         currentIncome
      )

      return {
         labels,
         datasets: [
            {
               label: 'Income',
               data: incomeValues, // Repeat the income value for each category
               backgroundColor: 'orange',
            },
            {
               label: 'Actual Expenses',
               data: actualValues,
               backgroundColor: 'blue',
            },
            {
               label: 'Budget',
               data: budgetValues,
               backgroundColor: 'green',
            },
         ],
      }
   }

   const chartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      barPercentage: 0.8, // Increase space between bars
      categoryPercentage: 1, // Adjusts the width of bars within the category
      plugins: {
         legend: {
            position: 'bottom', // Move legend to the bottom
            labels: {
               color: 'white', // Make legend text white
            },
         },

         datalabels: {
            color: 'white', // Make the numbers on the bars white
            anchor: 'end',
            align: 'top', // Position the labels at the top of the bars
            padding: { top: 10 }, // Add margin between the label and the bar (adjust as needed)
            formatter: (value) => value.toFixed(0), // Format the values to 0 decimal places
         },
      },
      scales: {
         x: {
            ticks: {
               color: 'white', // Make X-axis labels white
            },
         },
         y: {
            beginAtZero: true, // Start at zero for better comparison
            ticks: {
               color: 'white', // Make Y-axis labels white
            },
         },
      },
   }

   return (
      <div className="expenses-chart">
         <ExpensesNav />
         <MonthTabs
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
         />
         <h3 style={{ marginBottom: '20px' }}>
            Rendimento Mensal vs Despesas vs Orçamento
         </h3>
         <div style={{ height: '600px', width: '800px' }}>
            <Bar
               data={prepareChartData()}
               options={chartOptions}
               plugins={[ChartDataLabels]}
            />
         </div>
      </div>
   )
}

export default ExpensesCharts
