import React, { Suspense, lazy, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Col } from 'reactstrap'
import { FaBars, FaTrash } from 'react-icons/fa'
import { InputText } from 'primereact/inputtext'
import css from './Playlist.module.css'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import './DialogStyles.css'
import { usePlaylist } from '../../../../../context/PlaylistContext'
const YouTubePlayerLazy = lazy(() =>
   import('../../../../../components/youtubeCarousels/YoutubePlayer')
)

const EditPlaylist = () => {
   const {
      playlistName,
      setPlaylistName,
      videoLinks,
      setVideoLinks,
      selectedTopic,
      setSelectedTopic,
      allPlaylists,
      selectedPlaylist,
      setSelectedPlaylist,
      handleUpdatePlaylist,
      handleDeletePlaylist,
      handleAddVideo,
      newVideoLink,
      setNewVideoLink,
      setPlaylistId,
      topicOptions,
      fetchPlaylists,
      handleRemoveVideo,
   } = usePlaylist()
   useEffect(() => {
      return () => {
         // Reset state to initial values or perform any cleanup tasks
         setPlaylistName('')
         setVideoLinks([])
         setSelectedTopic(null)
         setNewVideoLink('')
         setSelectedPlaylist(null)
         // Any other state reset you might consider necessary
      }
   }, [])
   const onDragEnd = async (result) => {
      if (!result.destination) return

      const reorderedVideos = Array.from(videoLinks)
      const [removed] = reorderedVideos.splice(result.source.index, 1)
      reorderedVideos.splice(result.destination.index, 0, removed)

      setVideoLinks(reorderedVideos)
      await fetchPlaylists()
   }

   const playlistOptions = allPlaylists.map((playlist) => ({
      label: playlist.name,
      value: playlist,
   }))
   const extractYouTubeVideoId = (url) => {
      const regex =
         /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      const match = url.match(regex)
      return match ? match[1] : null // Retorna apenas o ID ou null se não for válido
   }

   const handleTopicChange = (e) => {
      setSelectedTopic(e.value)
   }
   const handlePlaylistSelection = (e) => {
      const selected = e.value
      setSelectedPlaylist(selected)

      setPlaylistId(selected.id)
      setPlaylistName(selected.name)
      setSelectedTopic(selected.topic)

      // Processar os links para garantir que estejam no formato correto
      const processedLinks = (selected.videos || []).map(
         (link) => extractYouTubeVideoId(link) || link
      )
      setVideoLinks(processedLinks)
   }

   useEffect(() => {
      if (selectedPlaylist) {
         setPlaylistName(selectedPlaylist.name)
         setVideoLinks(selectedPlaylist.videos || []) // Again, use 'videos'
         setSelectedTopic(selectedPlaylist.topic)
      }
   }, [selectedPlaylist])

   console.log('videoLinks', videoLinks)

   return (
      <div className={css.container}>
         <Col xs={12} md={6} className={css.inputContainer}>
            <div className={css.subtituloResultado3}>Selecionar Playlist:</div>
            <Dropdown
               style={{ marginBottom: '10px' }}
               value={selectedPlaylist}
               options={playlistOptions}
               onChange={handlePlaylistSelection}
               placeholder="Selecionar a playlist"
               optionLabel="label"
            />
            <div className={css.subtituloResultado3}>Nome da Playlist:</div>
            <InputText
               type="text"
               className="p-inputtext-sm"
               placeholder="Nome da Playlist"
               value={playlistName}
               onChange={(e) => setPlaylistName(e.target.value)}
            />
            <div className={css.subtituloResultado3}>Tópico da Playlist:</div>
            <Dropdown
               value={selectedTopic}
               options={topicOptions}
               onChange={handleTopicChange}
               placeholder="Selecionar Tema"
            />
            <div>
               <div className={css.subtituloResultado3}>
                  Adicionar vídeos a Playlist:
               </div>
               <InputText
                  className="p-inputtext-sm"
                  placeholder="Introduzir link"
                  value={newVideoLink}
                  onChange={(e) => setNewVideoLink(e.target.value)}
               />
               <Button
                  label="Adicionar Video"
                  size="small"
                  onClick={() => handleAddVideo(true)}
                  className="btnAdicionar1"
               />
            </div>

            <div className={css.darMargins}>
               <Button
                  label="Guardar Alterações"
                  onClick={() => handleUpdatePlaylist()}
                  style={{ marginRight: '15px' }}
                  size="small"
                  className="btnAdicionar1"
               />
               <Button
                  label="Apagar Playlist"
                  className="gradientButtonApagar"
                  onClick={handleDeletePlaylist}
                  size="small"
               />
            </div>
         </Col>

         <Col xs={12} md={6}>
            <DragDropContext onDragEnd={onDragEnd}>
               <Droppable droppableId="videos" direction="vertical">
                  {(provided) => (
                     <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Suspense fallback={<div>Loading video player...</div>}>
                           {videoLinks.map((video, index) => (
                              <Draggable
                                 key={video._id}
                                 draggableId={`video-${index}`}
                                 index={index}
                              >
                                 {(provided) => (
                                    <div
                                       ref={provided.innerRef}
                                       {...provided.draggableProps}
                                       style={{
                                          ...provided.draggableProps.style,
                                          border: '1px solid #ddd',
                                          marginBottom: '8px',
                                          padding: '10px',
                                          display: 'flex',
                                          alignItems: 'center',
                                       }}
                                    >
                                       <div
                                          {...provided.dragHandleProps}
                                          className={css.dragHandle}
                                       >
                                          <FaBars />
                                       </div>

                                       <YouTubePlayerLazy
                                          videoId={video}
                                          setWidth={180}
                                          setHeight={120}
                                       />
                                       <FaTrash
                                          onClick={() =>
                                             handleRemoveVideo(index)
                                          }
                                          style={{
                                             marginLeft: '10px',
                                             fill: 'white',
                                          }}
                                       />
                                    </div>
                                 )}
                              </Draggable>
                           ))}
                        </Suspense>
                        {provided.placeholder}
                     </div>
                  )}
               </Droppable>
            </DragDropContext>
         </Col>
      </div>
   )
}

export default EditPlaylist
