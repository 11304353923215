import React, {
   createContext,
   useContext,
   useState,
   useEffect,
   useCallback,
} from 'react'
import axios from 'axios'

import { UserContext } from './useUserContext'

const PlaylistContext = createContext()

export const PlaylistProvider = ({ children }) => {
   const [playlistName, setPlaylistName] = useState('')
   const [playlistId, setPlaylistId] = useState('')
   const [videoLinks, setVideoLinks] = useState([])
   const [newVideoLink, setNewVideoLink] = useState('')
   const [selectedTopic, setSelectedTopic] = useState('')
   const [allPlaylists, setAllPlaylists] = useState([])
   const [isLoadingPlaylists, setIsLoadingPlaylists] = useState(false)
   const { user, isLoading: isUserLoading } = useContext(UserContext)
   const [selectedPlaylist, setSelectedPlaylist] = useState(null)
   const [mainPlaylistId, setMainPlaylistId] = useState('')
   const [playlistVisibility, setPlaylistVisibility] = useState(false)
   const hasMinimumVideos = videoLinks.length >= 3
   const topicOptions = [
      { label: 'ETFs', value: 'ETFs' },
      { label: 'Ações', value: 'Ações' },
      { label: 'REITs', value: 'REITs' },
      { label: 'Cripto Moedas', value: 'Cripto Moedas' },
      { label: 'Finanças Pessoais', value: 'Finanças Pessoais' },
      { label: 'Poupança', value: 'Poupança' },
      { label: 'Imobiliário', value: 'Imobiliário' },
      { label: 'Obrigações', value: 'Obrigações' },
      { label: 'Fundos mútuos', value: 'Fundos mútuos' },
      { label: 'Empreendedorismo', value: 'Empreendedorismo' },
      { label: 'Futuros e Opções', value: 'Futuros e Opções' },
      { label: 'Trading', value: 'Trading' },
   ]

   // Fetch playlists only if user is logged in and user data is available
   const fetchPlaylists = useCallback(async () => {
      if (!user?.id || isUserLoading) {
         console.warn('Usuário não logado ou ainda carregando')
         return false // Retorna false se não houver usuário ou se ainda estiver carregando
      }

      setIsLoadingPlaylists(true) // Inicia o estado de carregamento
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}playlistRoutes/regular/${user.id}`
         )
         console.log('Playlists retornadas:', response.data)
         setAllPlaylists(response.data)
         return true // Retorna true indicando que o fetch foi bem-sucedido
      } catch (error) {
         console.error('Error loading playlists:', error)
         return false // Retorna false em caso de erro
      } finally {
         setIsLoadingPlaylists(false)
      }
   }, [user, isUserLoading])

   const togglePlaylistVisibility = async () => {
      if (!playlistId) {
         console.error(
            'Nenhuma playlist selecionada para alternar a visibilidade.'
         )
         return
      }

      const updatedVisibility = !playlistVisibility // Inverta o estado atual
      setPlaylistVisibility(updatedVisibility) // Atualiza localmente no frontend

      try {
         // Atualiza no servidor
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`, // Certifique-se de que o endpoint é correto
            {
               contentVisibility: {
                  playlists: { [playlistId]: updatedVisibility }, // Adiciona a visibilidade da playlist
               },
            },
            { headers: { Authorization: `Bearer ${user.accessToken}` } } // Inclui autenticação
         )

         console.log('Visibilidade da playlist atualizada com sucesso!')
      } catch (error) {
         console.error('Erro ao alternar a visibilidade da playlist:', error)

         // Reverte a alteração local se falhar no servidor
         setPlaylistVisibility(!updatedVisibility)
      }
   }

   // Only call `fetchPlaylists` if the user is logged in
   useEffect(() => {
      if (user?.id && !isUserLoading) {
         fetchPlaylists()
      }
   }, [user, isUserLoading, fetchPlaylists])

   const handleCreatePlaylist = async () => {
      if (!playlistName.trim() || !selectedTopic || videoLinks?.length < 3) {
         // toast.error('Please fill all fields and add at least 3 videos.')
         return
      }
      try {
         const videoData = {
            playlistName,
            videoLinks,
            creator: user.id,
            playlistType: 'regular',
            topic: selectedTopic,
         }
         await axios.post(
            `${process.env.REACT_APP_API_URL}playlistRoutes/`,
            videoData
         )
         // toast.success('Playlist created successfully!')
         fetchPlaylists()
         clearForm()
      } catch (error) {
         console.error('Error creating playlist:', error)
         // toast.error('Error creating the playlist.')
      }
   }
   const handleSetMainPlaylist = async (playlistId) => {
      console.log('playlistId', playlistId)
      console.log('userId', user.id)

      if (!playlistId) {
         console.error('Nenhuma playlist selecionada.')
         return
      }

      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}playlistRoutes/setMain/${playlistId}`, // Corrigir URL
            { userId: user.id } // Certifique-se de enviar o userId corretamente
         )

         console.log('Playlist principal definida com sucesso!')
         setMainPlaylistId(playlistId) // Atualiza localmente
      } catch (error) {
         console.error('Erro ao definir a playlist principal:', error)
      }
   }

   const handleUpdatePlaylist = async () => {
      if (!playlistId) return
      try {
         const response = await axios.put(
            `${process.env.REACT_APP_API_URL}playlistRoutes/${playlistId}`,
            {
               playlistName,
               videoLinks,
               topic: selectedTopic,
            }
         )
         if (response.status === 200) {
            // toast.success('Playlist updated successfully!')
         }
         fetchPlaylists()
      } catch (error) {
         console.error('Error updating playlist:', error)
         // toast.error('Error updating the playlist.')
      }
   }

   const handleRemoveVideo = async (index) => {
      const updatedVideoLinks = videoLinks.filter((_, i) => i !== index)
      setVideoLinks(updatedVideoLinks)

      // Opcional: Atualizar no servidor
      if (playlistId) {
         try {
            await axios.put(
               `${process.env.REACT_APP_API_URL}playlistRoutes/${playlistId}`,
               { videoLinks: updatedVideoLinks }
            )
            console.log('Vídeo removido com sucesso do servidor.')
         } catch (error) {
            console.error('Erro ao remover vídeo do servidor:', error)
         }
      }
   }

   const extractYouTubeVideoId = (url) => {
      const regex =
         /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      const match = url.match(regex)
      return match ? match[1] : null // Retorna apenas o ID ou null se não for válido
   }

   const handleAddVideo = async (addToTop = false) => {
      if (!newVideoLink.trim()) {
         console.error('O link do vídeo não pode estar vazio.')
         return
      }

      const videoId = extractYouTubeVideoId(newVideoLink)
      if (!videoId) {
         console.error('Por favor, insira um link válido do YouTube.')
         return
      }

      const updatedVideoLinks = addToTop
         ? [videoId, ...videoLinks]
         : [...videoLinks, videoId]

      setVideoLinks(updatedVideoLinks)
      setNewVideoLink('') // Limpa o input após adicionar

      // Opcional: Atualizar no servidor
      if (playlistId) {
         try {
            await axios.put(
               `${process.env.REACT_APP_API_URL}playlistRoutes/${playlistId}`,
               { videoLinks: updatedVideoLinks }
            )
            console.log('Vídeo adicionado com sucesso à playlist no servidor.')
         } catch (error) {
            console.error('Erro ao adicionar vídeo ao servidor:', error)
         }
      }
   }

   const isValidYouTubeLink = (url) => {
      const regex =
         /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      return regex.test(url)
   }

   const handleDeletePlaylist = async () => {
      if (!playlistId) {
         // toast.error('No playlist selected.')
         return
      }
      try {
         const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}playlistRoutes/${playlistId}`
         )
         if (response.status === 200) {
            // toast.success('Playlist deleted successfully!')
            clearForm()
         }
         fetchPlaylists()
      } catch (error) {
         console.error('Error deleting playlist:', error)
         // toast.error('Error deleting the playlist.')
      }
   }

   const clearForm = () => {
      setPlaylistName('')
      setVideoLinks([])
      setSelectedTopic('')
      setNewVideoLink('')
      setSelectedPlaylist(null)
      // toast.info('Form cleared.')
   }

   return (
      <PlaylistContext.Provider
         value={{
            playlistName,
            setPlaylistName,
            playlistId,
            setPlaylistId,
            videoLinks,
            setVideoLinks,
            newVideoLink,
            setNewVideoLink,
            selectedTopic,
            setSelectedTopic,
            allPlaylists,
            handleCreatePlaylist,
            handleDeletePlaylist,
            handleUpdatePlaylist,
            clearForm,
            topicOptions,
            isLoadingPlaylists,
            fetchPlaylists,
            selectedPlaylist,
            setSelectedPlaylist,
            handleAddVideo,
            handleRemoveVideo,
            mainPlaylistId,
            setMainPlaylistId,
            handleSetMainPlaylist,
            playlistVisibility,
            togglePlaylistVisibility,
            hasMinimumVideos,
         }}
      >
         {children}
      </PlaylistContext.Provider>
   )
}

export const usePlaylist = () => useContext(PlaylistContext)
