import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import css from './EventsDashboard.module.css'
import { useEventContext } from '../../../../../../context/EventManagerContext'
import EventCreationForm from './EventCreationForm'
import EventVisibilityToggle from './EventVisibilityToggle'
import './DialogStyles.css'

const EventsDashboard = () => {
   const {
      events,
      fetchEvents,
      handleDeleteEvent,
      toggleEventVisibility,
      isEventVisible,
   } = useEventContext()
   const [isDialogOpen, setIsDialogOpen] = useState(false)
   const [currentEvent, setCurrentEvent] = useState(null)
   const [isMobileView, setIsMobileView] = useState(window.innerWidth < 991)

   useEffect(() => {
      fetchEvents()
      const handleResize = () => setIsMobileView(window.innerWidth < 991)
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
   }, [fetchEvents])

   const openDialog = (event = null) => {
      setCurrentEvent(event)
      setIsDialogOpen(true)
   }

   const closeDialog = () => {
      setCurrentEvent(null)
      setIsDialogOpen(false)
   }

   const actionBodyTemplate = (rowData) => (
      <div className={css.actionButtons}>
         <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success"
            onClick={() => openDialog(rowData)}
            aria-label="Editar"
         />
         <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger"
            onClick={() => handleDeleteEvent(rowData._id)}
            aria-label="Apagar"
         />
      </div>
   )

   const imageBodyTemplate = (rowData) => (
      <img
         src={rowData.bannerImage || 'https://via.placeholder.com/100'}
         alt={rowData.name}
         className={css.eventImage}
      />
   )

   const renderCards = () => (
      <div className={css.cardContainer}>
         {events.map((event) => (
            <div className={css.eventCard} key={event._id}>
               <img
                  src={event.bannerImage || 'https://via.placeholder.com/100'}
                  alt={event.name}
                  className={css.eventCardImage}
               />
               <div className={css.eventCardContent}>
                  <h3 className={css.eventCardTitle}>
                     <strong>Evento: </strong>
                     {event.name}
                  </h3>
                  <p className={css.eventCardDate}>
                     <strong>Data: </strong>
                     {new Date(event.date).toLocaleDateString('pt-PT')}
                  </p>
                  <div className={css.eventCardActions}>
                     <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-success"
                        onClick={() => openDialog(event)}
                     />
                     <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger"
                        onClick={() => handleDeleteEvent(event._id)}
                     />
                  </div>
               </div>
            </div>
         ))}
      </div>
   )

   return (
      <div className={css.tabContainer}>
         <h2 className={css.titleEventos}>Gestão de Eventos</h2>
         <div className={css.flexContainer}>
            <div className="col-sm-12 col-md-3 darCenter">
               <EventVisibilityToggle
                  isVisible={isEventVisible}
                  setIsVisible={toggleEventVisibility}
               />
            </div>
            <div className="col-sm-12 col-md-9 darCenter">
               <Button
                  label="Adicionar Evento"
                  onClick={() => openDialog()}
                  className={css.btnAdicionarEvento}
               />
            </div>
         </div>

         {isMobileView ? (
            renderCards()
         ) : (
            <div className={css.dataTableWrapper}>
               <DataTable
                  value={events}
                  responsiveLayout="scroll"
                  className={css.eventTable}
               >
                  <Column
                     header="Imagem"
                     body={imageBodyTemplate}
                     style={{ width: '100px' }}
                  />
                  <Column field="name" header="Título" sortable />
                  <Column
                     field="date"
                     header="Data"
                     body={(rowData) =>
                        new Date(rowData.date).toLocaleDateString('pt-PT')
                     }
                     sortable
                  />
                  <Column header="Ações" body={actionBodyTemplate} />
               </DataTable>
            </div>
         )}

         <Dialog
            header={currentEvent ? 'Editar Evento' : 'Criar Novo Evento'}
            visible={isDialogOpen}
            style={{ width: '50vw' }}
            onHide={closeDialog}
         >
            <EventCreationForm event={currentEvent} onClose={closeDialog} />
         </Dialog>
      </div>
   )
}

export default EventsDashboard
