import React from 'react'
import { Bar } from 'react-chartjs-2'

export default function ExpenseBreakdownChart({ data }) {
   const options = {
      indexAxis: 'y',
      plugins: {
         legend: {
            display: false,
         },
      },
      scales: {
         x: {
            ticks: {
               color: 'white',
            },
         },
         y: {
            ticks: {
               color: 'white',
            },
         },
      },
   }

   return (
      <div>
         <h3>Segregação de Despesas Anual</h3>
         {data && data.labels.length > 0 ? (
            <Bar data={data} options={options} />
         ) : (
            <p>No data available to display.</p>
         )}
      </div>
   )
}
