import { toast } from 'react-toastify'

export const handleError = (error) => {
   console.error('API call failed: ', error)
   let errorMessage = 'Oops! Something went wrong. Please try again later.'

   if (error.response) {
      const { status, data } = error.response
      if (status === 403) {
         errorMessage = 'You do not have permission to perform this action.'
      } else if (status === 404) {
         errorMessage = 'Requested resource not found.'
      } else if (data && data.message) {
         errorMessage = data.message
      }
   } else if (error.request) {
      errorMessage = 'Network error. Please check your connection.'
   } else if (error.message) {
      errorMessage = error.message
   }

   toast.error(errorMessage)
   // Optionally re-throw to handle the error further up in the component hierarchy
   throw error
}
