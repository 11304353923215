import React from 'react'
import css from './InvestimentosNav.module.css' // Correct path to your CSS module
import { NavLink, Outlet } from 'react-router-dom'

export default function InvestimentosNav() {
   return (
      <div>
         <nav className={css.tabNavigation}>
            <NavLink
               to="investments"
               className={({ isActive }) =>
                  isActive ? css.activeTab : css.tab
               }
            >
               Investimentos
            </NavLink>
            <NavLink
               to="portfolio"
               className={({ isActive }) =>
                  isActive ? css.activeTab : css.tab
               }
            >
               Portfólio
            </NavLink>
         </nav>
         <Outlet />
      </div>
   )
}
