import React, { useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faArrowUp,
   faArrowDown,
   faStar,
} from '@fortawesome/free-solid-svg-icons'

const IncomeComparison = ({ incomes, months, selectedMonth }) => {
   const parseIncome = (value) =>
      parseFloat(value?.toString().replace(',', '.')) || 0

   const monthlyTotals = incomes.map((income) =>
      parseIncome(income?.totalIncome || 0)
   )

   const currentMonthIncome = monthlyTotals[selectedMonth]
   const previousMonthIncome =
      selectedMonth > 0 ? monthlyTotals[selectedMonth - 1] : null
   const nextMonthIncome =
      selectedMonth < incomes.length - 1
         ? monthlyTotals[selectedMonth + 1]
         : null

   const bestMonthIndex = monthlyTotals.indexOf(
      Math.max(...monthlyTotals.filter((income) => income > 0))
   )
   const worstMonthIndex = monthlyTotals.indexOf(
      Math.min(...monthlyTotals.filter((income) => income > 0))
   )

   const previousMonthComparison =
      previousMonthIncome !== null
         ? ((currentMonthIncome - previousMonthIncome) / previousMonthIncome) *
           100
         : null
   const nextMonthComparison =
      nextMonthIncome !== null
         ? ((currentMonthIncome - nextMonthIncome) / nextMonthIncome) * 100
         : null

   const formatComparisonText = (value) => {
      if (value === null) return 'N/A'
      const roundedValue = value.toFixed(2)
      const color = roundedValue > 0 ? 'green' : 'red'
      const icon = roundedValue > 0 ? faArrowUp : faArrowDown
      return (
         <span style={{ color: color, fontWeight: 'bold' }}>
            <FontAwesomeIcon icon={icon} style={{ marginRight: '5px' }} />
            {Math.abs(roundedValue)}%
         </span>
      )
   }

   return (
      <div
         style={{
            padding: '20px',
            backgroundColor: '#333',
            borderRadius: '10px',
            color: '#fff',
         }}
      >
         <h3 style={{ borderBottom: '2px solid #ccc', paddingBottom: '10px' }}>
            Análise Comparativa de Rendimentos
         </h3>
         <p style={{ marginTop: '10px' }}>
            <strong>Melhor Mês:</strong> {months[bestMonthIndex]} - €
            {monthlyTotals[bestMonthIndex].toFixed(2)}{' '}
            <FontAwesomeIcon
               icon={faStar}
               style={{ color: 'gold', marginLeft: '5px' }}
            />
         </p>
         <p>
            <strong>Pior Mês:</strong> {months[worstMonthIndex]} - €
            {monthlyTotals[worstMonthIndex].toFixed(2)}
         </p>
         <p>
            <strong>
               Este mês foi {previousMonthComparison > 0 ? 'melhor' : 'pior'}{' '}
               que o mês passado em:
            </strong>{' '}
            {formatComparisonText(previousMonthComparison)}
         </p>
         <p>
            <strong>
               Este mês foi {nextMonthComparison > 0 ? 'melhor' : 'pior'} que o
               próximo mês em:
            </strong>{' '}
            {formatComparisonText(nextMonthComparison)}
         </p>
      </div>
   )
}

export default IncomeComparison
