import React from 'react'
import { Dialog } from 'primereact/dialog'
import styles from './WebsiteModal.module.css'

const WebsiteModal = ({ visible, onModalClose, website }) => {
   const customHeader = <div style={{ color: 'white' }}>{website.name}</div>

   return (
      <Dialog
         header={customHeader}
         visible={visible}
         className={styles.websiteModal}
         onHide={onModalClose}
         modal
         draggable={false}
         dismissableMask
      >
         <div className={styles.paddedContainer2}>
            {website.logo && (
               <img
                  src={website.logo}
                  alt={website.name}
                  className={styles.logo}
               />
            )}
            <table className={styles.table}>
               <tbody>
                  <tr>
                     <td>
                        <b>Descrição:</b>
                     </td>
                     <td>{website.description}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Categorias:</b>
                     </td>
                     <td>{website.categories}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Gratuito ou Pago:</b>
                     </td>
                     <td>{website.freeOrPaid}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Website:</b>
                     </td>
                     <td>
                        <a
                           href={website.websiteLink}
                           target="_blank"
                           rel="noopener noreferrer"
                           className={styles.link}
                        >
                           Visit Site
                        </a>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </Dialog>
   )
}

export default WebsiteModal
