import React from 'react'
import { Row, Col } from 'reactstrap'
import useWelcomeVideo from '../../../../../../hooks/useWelcomeVideo'
import VideoDisplay from './VideoDisplay'
import VideoLinkInput from './VideoLinkInput'
import UserVideosDisplay from './UserVideosDisplay'
import css from './WelcomeVideoCreatorPage.module.css' // Reutilizando o mesmo CSS

function WelcomeVideoCreatorCard() {
   const { currentVideo, userVideos, fetchUserVideos, setVideoLink } =
      useWelcomeVideo('creatorCard')

   return (
      <Row className={css.container}>
         <Col className={css.videoSection}>
            <h3 className={css.title}>
               Vídeo de boas-vindas no Cartão de Apresentação
            </h3>
            <div className={css.videoDisplay} style={{ marginBottom: '33px' }}>
               {currentVideo ? (
                  <VideoDisplay
                     key={currentVideo._id}
                     videoLink={currentVideo.videoLink}
                  />
               ) : (
                  <p className={css.noVideoText}>
                     Não tem um vídeo de boas-vindas. Por favor carregue um
                     vídeo do Youtube.
                  </p>
               )}
            </div>
         </Col>
         <Col className={css.addVideoSection}>
            <h3 className={css.title}>Adicionar novo vídeo de boas-vindas</h3>
            <VideoLinkInput
               userVideos={userVideos}
               fetchUserVideos={fetchUserVideos}
               onLinkSubmit={setVideoLink}
               videoType="creatorCard"
            />
         </Col>
         <Col className={css.manageVideosSection}>
            <h3 className={css.title}>Gerir Vídeos</h3>
            <UserVideosDisplay videoType="creatorCard" />
         </Col>
      </Row>
   )
}

export default WelcomeVideoCreatorCard
