import React, { useContext, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../../context/useUserContext'
import { handleError } from '../../../../../../utils/handleError'
import { Col } from 'react-bootstrap'
import css from './EventsDashboard.module.css'
const EventVisibilityToggle = ({ isVisible, setIsVisible }) => {
   const { user } = useContext(UserContext)
   const [isLoading, setIsLoading] = useState(false)

   const toggleVisibility = async () => {
      const newVisibility = !isVisible
      setIsLoading(true)
      try {
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`,
            { contentVisibility: { events: newVisibility } }, // Adjust the visibility for events
            { headers: { Authorization: `Bearer ${user.accessToken}` } }
         )
         setIsVisible(newVisibility)
         toast.success('Visibility of events has been successfully updated!')
      } catch (error) {
         handleError(error)
         toast.error('Failed to update event visibility.')
         setIsVisible(isVisible) // Reset to previous state in case of error
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <div className={css.visibilityToggleContainer}>
         <Col sm={10} md={6}>
            Mostrar Eventos:
         </Col>
         <Col md={1}>
            <input
               type="checkbox"
               checked={isVisible}
               onChange={toggleVisibility}
               disabled={isLoading} // Disable checkbox while loading
            />
         </Col>
         {isLoading && <p>Updating...</p>}
      </div>
   )
}

export default EventVisibilityToggle
