import React, { useState } from 'react'
import EventsDashboard from './EventsDashboard'
import { Dialog } from 'primereact/dialog'
import EventCreationForm from './EventCreationForm'

const EventManagementPage = () => {
   const [isDialogOpen, setIsDialogOpen] = useState(false)
   const [editingEvent, setEditingEvent] = useState(null)

   const handleOpenDialog = (event = null) => {
      setEditingEvent(event) // Define o evento a ser editado (ou null para novo)
      setIsDialogOpen(true)
   }

   const handleCloseDialog = () => {
      setEditingEvent(null)
      setIsDialogOpen(false)
   }

   return (
      <div>
         {/* Componente da tabela de gestão de eventos */}
         <EventsDashboard onOpenDialog={handleOpenDialog} />

         {/* Modal para criar ou editar eventos */}
         <Dialog
            header={editingEvent ? 'Editar Evento' : 'Criar Novo Evento'}
            visible={isDialogOpen}
            style={{ width: '50vw' }}
            onHide={handleCloseDialog}
         >
            <EventCreationForm
               event={editingEvent}
               onClose={handleCloseDialog}
            />
         </Dialog>
      </div>
   )
}

export default EventManagementPage
