import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import MyEditor from '../../../../../components/editor/MyEditor'
import { InputText, Button } from 'primereact'
import {
   EditorState,
   ContentState,
   convertFromRaw,
   convertToRaw,
} from 'draft-js'
import { UserContext } from '../../../../../context/useUserContext'
import { Dropdown } from 'primereact/dropdown'
import { toast } from 'react-toastify'
import axios from 'axios'
import css from './EditorManager.module.css'
import { EditorManagerContext } from '../../../../../context/EditorManagerContext'
import { handleError } from '../../../../../utils/handleError'

Modal.setAppElement('#root')

function EditorManager({
   onArticleSubmitted,
   editingArticle,
   resetEditingArticle,
   fetchArticles,
}) {
   const { isModalOpen, setModalIsOpen } = useContext(EditorManagerContext)
   const { user } = useContext(UserContext)
   const [editorState, setEditorState] = useState(EditorState.createEmpty())
   const [articleTitle, setArticleTitle] = useState('')
   const [articleTopic, setArticleTopic] = useState('')

   // Open modal for new article
   const openNewArticleModal = () => {
      setArticleTitle('')
      setArticleTopic('')
      setEditorState(EditorState.createEmpty())
      setModalIsOpen(true)
   }

   // Open modal for editing article
   useEffect(() => {
      if (editingArticle) {
         setArticleTitle(editingArticle.title)
         setArticleTopic(editingArticle.topic)
         const contentState = editingArticle.content
            ? convertFromRaw(JSON.parse(editingArticle.content))
            : EditorState.createEmpty()
         setEditorState(EditorState.createWithContent(contentState))
         setModalIsOpen(true) // Ensure modal is opened when editing
      }
   }, [editingArticle])

   const handleArticleSubmit = async () => {
      const contentState = editorState.getCurrentContent()
      if (!contentState.hasText()) {
         toast.error('Content cannot be empty.')
         return
      }
      const rawContent = convertToRaw(contentState)
      const articleData = {
         title: articleTitle.trim(),
         content: JSON.stringify(rawContent),
         author: user.id,
         topic: articleTopic,
         imageUrls: editingArticle ? editingArticle.imageUrls : [],
      }

      if (!articleData.title || !articleData.topic) {
         toast.error('Title and topic are required.')
         return
      }

      const endpoint = editingArticle
         ? `/articleRoutes/${editingArticle._id}`
         : '/articleRoutes/'
      const method = editingArticle ? 'put' : 'post'

      try {
         const response = await axios({
            method,
            url: `${process.env.REACT_APP_API_URL}${endpoint}`,
            data: articleData,
            headers: { Authorization: `Bearer ${user.token}` },
         })
         onArticleSubmitted(response.data)
         toast.success('Article submitted successfully!')
         fetchArticles()
         closeModal()
      } catch (error) {
         handleError(error)
         toast.error('Failed to submit article.')
      }
   }

   const closeModal = () => {
      resetEditingArticle() // Ensure editing state is reset
      setModalIsOpen(false)
      setEditorState(EditorState.createEmpty())
      setArticleTitle('')
      setArticleTopic('')
   }

   return (
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
         <Button
            label="Create New Article"
            onClick={openNewArticleModal}
            className={css.modalButton}
         />
         <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Article Editor"
            className={css.ReactModal__Content}
            overlayClassName={css.ReactModal__Overlay}
         >
            <h2>{editingArticle ? 'Editar Artigo' : 'Criar Novo Artigo'}</h2>
            <form onSubmit={(e) => e.preventDefault()}>
               <InputText
                  value={articleTitle}
                  onChange={(e) => setArticleTitle(e.target.value)}
                  placeholder="Título"
                  required
               />
               <Dropdown
                  value={articleTopic}
                  options={[
                     'ETFs',
                     'Ações',
                     'REITs',
                     'Cripto Moedas',
                     'Finanças Pessoais',
                     'Poupança',
                     'Imobiliário',
                     'Obrigações',
                     'Fundos mútuos',
                     'Empreendedorismo',
                     'Futuros e Opções',
                     'Trading',
                  ]}
                  onChange={(e) => setArticleTopic(e.value)}
                  placeholder="Tópico"
                  required
               />
               <MyEditor
                  editorState={editorState}
                  onContentChange={setEditorState}
               />
               <Button
                  label="Submit"
                  onClick={handleArticleSubmit}
                  className={css.submitButton}
               />
               <Button
                  label="Close"
                  onClick={closeModal}
                  className={css.closeButton}
               />
            </form>
         </Modal>
      </div>
   )
}

export default EditorManager
