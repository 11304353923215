import React, { Component } from 'react'
import { Button } from 'react-bootstrap' // Example using React Bootstrap for UI elements
import { handleError } from './handleError'

class ErrorBoundary extends Component {
   state = { hasError: false, error: null, errorInfo: null }

   static getDerivedStateFromError(error) {
      return { hasError: true }
   }

   componentDidCatch(error, errorInfo) {
      this.setState({ error, errorInfo })
      handleError(error, errorInfo)
   }

   handleRetry = () => {
      window.location.reload() // Simple way to refresh the page
   }

   render() {
      if (this.state.hasError) {
         return (
            <div style={{ padding: 20, textAlign: 'center' }}>
               <h2>Oops! Something went wrong.</h2>
               <p>
                  Our team has been notified, but click below to fill out a
                  report.
               </p>
               <Button onClick={this.handleRetry}>Try Again</Button>
               <Button
                  onClick={() =>
                     console.log(this.state.error, this.state.errorInfo)
                  }
               >
                  Report Issue
               </Button>
               <details style={{ whiteSpace: 'pre-wrap', marginTop: 20 }}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
               </details>
            </div>
         )
      }

      return this.props.children
   }
}

export default ErrorBoundary
