import React from 'react'
import { Row } from 'reactstrap'
import DebtInput from './DebtInput'

const DebtDetails = ({ categories, control, getValues }) => {
   return (
      <Row style={{ display: 'flex' }}>
         {categories.map((category, index) => (
            <DebtInput
               key={category.id}
               category={category}
               control={control}
               getValues={getValues} // Pass getValues function
               index={index}
            />
         ))}
      </Row>
   )
}

export default DebtDetails
