import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './FavoriteTopicsCard.module.css'
import axios from 'axios'
import { Col } from 'reactstrap'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import {
   faTwitter,
   faFacebook,
   faInstagram,
   faLinkedin,
   faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { UserContext } from '../../../../../context/useUserContext'
import NotificacoesCard from '../notificationsComponents/NotificacoesCard'

// Map to easily access icons based on a string identifier
const socialIcons = {
   twitter: faTwitter,
   facebook: faFacebook,
   instagram: faInstagram,
   linkedin: faLinkedin,
   youtube: faYoutube,
}
const FavoriteTopicsCard = () => {
   const [followedCreators, setFollowedCreators] = useState([])
   const [expandedId, setExpandedId] = useState(null) // State to control the expanded item
   const { user } = useContext(UserContext)
   const userId = user.id

   useEffect(() => {
      const fetchFollowedCreators = async () => {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}socialRouter/followingCreators/${userId}`
            )
            setFollowedCreators(response.data)
         } catch (error) {
            console.error('Error fetching followed creators:', error)
         }
      }

      if (userId) {
         fetchFollowedCreators()
      }
   }, [userId])

   const handleUnfollow = async (creatorId) => {
      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}socialRouter/unfollow/${userId}/${creatorId}`
         )
         setFollowedCreators(
            followedCreators.filter((creator) => creator._id !== creatorId)
         )
      } catch (error) {
         console.error('Failed to unfollow creator:', error)
      }
   }

   const toggleCollapse = (id) => {
      setExpandedId(expandedId === id ? null : id)
   }

   return (
      <div className={styles.subscriptionList}>
         <h3 className={styles.subscriptionTitle}>
            A Seguir ({followedCreators.length})
         </h3>
         <div style={{ display: 'flex' }}>
            <Col>
               <ul className={styles.creatorList}>
                  {followedCreators.map((creator, index) => (
                     <li
                        key={creator._id || index}
                        className={styles.creatorItem}
                     >
                        <div
                           onClick={() => toggleCollapse(creator._id)}
                           className={styles.creatorInfo}
                        >
                           <img
                              src={
                                 creator.profilePictureUrl ||
                                 require('../../../../../assets/userfallback.jpg')
                              }
                              alt={creator.username}
                              className={styles.creatorImage}
                           />
                           <Link
                              to={`/creators/${creator.username}`}
                              className={styles.creatorName}
                           >
                              {creator.username}
                           </Link>
                           <FontAwesomeIcon
                              icon={
                                 expandedId === creator._id
                                    ? faChevronUp
                                    : faChevronDown
                              }
                              className={styles.chevronIcon}
                           />
                        </div>
                        {expandedId === creator._id && (
                           <div className={styles.expandedContent}>
                              <div className={styles.buttonGroup}>
                                 <Link to={`/creators/${creator.username}`}>
                                    <Button
                                       className={styles.smallButton}
                                       label="Visitar Página"
                                    />
                                 </Link>
                                 <Button
                                    className={styles.smallButton}
                                    label="Unfollow"
                                    onClick={() => handleUnfollow(creator._id)}
                                 />
                              </div>
                              <div className={styles.socialMediaLinks}>
                                 {creator.socialMediaLinks.map(
                                    (link, index) => (
                                       <Link
                                          key={index}
                                          to={link.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className={styles.socialIconLink}
                                       >
                                          <FontAwesomeIcon
                                             icon={
                                                socialIcons[
                                                   link.type.toLowerCase()
                                                ]
                                             }
                                          />
                                       </Link>
                                    )
                                 )}
                              </div>
                           </div>
                        )}
                     </li>
                  ))}
               </ul>
            </Col>
            <Col>
               <NotificacoesCard />
            </Col>
         </div>
      </div>
   )
}

export default FavoriteTopicsCard
