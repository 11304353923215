import React, { useState, useEffect } from 'react'
import css from './EventsPage.module.css'

import { Helmet } from 'react-helmet'
import EventList from '../../../cursos/courseComponents/EventList'
import EventDialog from '../../../cursos/courseComponents/EventDialog'

const EventsPage = () => {
   const [events, setEvents] = useState([])
   const [selectedEvent, setSelectedEvent] = useState(null)
   const [eventDialogVisible, setEventDialogVisible] = useState(false)

   useEffect(() => {
      fetchEvents()
   }, [])

   const fetchEvents = async () => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_API_URL}eventRoutes/`
         )
         if (!response.ok) throw new Error('Failed to fetch events')
         const { events } = await response.json()
         setEvents(events)
      } catch (error) {
         console.error('Error fetching events:', error)
      }
   }

   const handleEventClick = (event) => {
      setSelectedEvent(event)
      setEventDialogVisible(true)
   }

   return (
      <div>
         <Helmet>
            <title>Eventos</title>
            <meta
               name="description"
               content="Explore nossos eventos e mantenha-se atualizado."
            />
         </Helmet>
         <h2 className={css.titulos}>Eventos</h2>

         <EventList events={events} handleEventClick={handleEventClick} />

         {selectedEvent && (
            <EventDialog
               selectedEvent={selectedEvent}
               dialogVisible={eventDialogVisible}
               setDialogVisible={setEventDialogVisible}
            />
         )}
      </div>
   )
}

export default EventsPage
