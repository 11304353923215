import React from 'react'
import { Dialog } from 'primereact/dialog'
import '../livros/BookModal.css' // Reutiliza o estilo existente

const CommunityModal = ({ visible, onModalClose, community }) => {
   const customHeader = (
      <div className="book-modal-header">
         <h3>{community.name}</h3>
      </div>
   )
   console.log('community', community)
   return (
      <Dialog
         header={customHeader}
         visible={visible}
         className="book-modal"
         onHide={onModalClose}
         modal
         draggable={false}
         dismissableMask
      >
         <div className="book-modal-container">
            <div className="book-modal-content">
               {community.logo && (
                  <img
                     src={community.logo}
                     alt={community.name}
                     className="book-modal-image"
                  />
               )}
               <div className="book-modal-info">
                  <p>
                     <span className="book-modal-label">Descrição:</span>{' '}
                     {community.description || 'Não disponível'}
                  </p>
                  <p>
                     <span className="book-modal-label">Membros:</span>{' '}
                     {community.members || 'Não disponível'}
                  </p>
                  <p>
                     <span className="book-modal-label">Categoria:</span>{' '}
                     {Array.isArray(community.categories)
                        ? community.categories.join(', ')
                        : 'Não disponível'}
                  </p>
                  <p>
                     <span className="book-modal-label">Acesso:</span>{' '}
                     {Array.isArray(community.acesso)
                        ? community.acesso.join(', ')
                        : community.acesso || 'Não disponível'}
                  </p>

                  <p>
                     <span className="book-modal-label">Website:</span>{' '}
                     <a
                        href={community.communityLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="book-modal-link"
                     >
                        Visitar Comunidade
                     </a>
                  </p>
               </div>
            </div>
         </div>
      </Dialog>
   )
}

export default CommunityModal
