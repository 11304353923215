import React, { useContext } from 'react'
import { IncomeProvider } from './IncomeContext'
import { DebtsProvider } from './DebtsContext'
import ExpensesProvider from './ExpensesContext'
import { InsuranceProvider } from './InsuranceContext'
import { SavingsProvider } from './SavingsContext'
import { UserContext } from './useUserContext'

const ConditionalProvider = ({ children }) => {
   const { user } = useContext(UserContext)

   // Verifica a role do usuário
   if (user?.role === 'RegularUser') {
      return (
         <IncomeProvider>
            <DebtsProvider>
               <ExpensesProvider>
                  <InsuranceProvider>
                     <SavingsProvider>{children}</SavingsProvider>
                  </InsuranceProvider>
               </ExpensesProvider>
            </DebtsProvider>
         </IncomeProvider>
      )
   }

   // Usuários não RegularUser apenas recebem os children sem os contextos adicionais
   return <>{children}</>
}

export default ConditionalProvider
