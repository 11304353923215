import React, { useEffect, useState } from 'react'
import MainPlaylistSelector from './MainPlaylistSelector'
import EditPlaylist from '../EditarPlaylist'
import PlaylistVisibilityToggle from './PlaylistVisibilityToggle'
import css from '../Playlist.module.css'
import axios from 'axios'
const PlaylistManager = () => {
   const [isPlaylistsVisible, setIsPlaylistsVisible] = useState(false)
   const [playlists, setPlaylists] = useState([])

   const fetchPlaylists = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/playlistRoutes/userPlaylists`
         )
         setPlaylists(response.data.playlists)
         setIsPlaylistsVisible(
            response.data.contentVisibility.playlists || false
         ) // Inicializar visibilidade
      } catch (error) {
         console.error('Erro ao carregar playlists:', error)
      }
   }

   useEffect(() => {
      fetchPlaylists()
   }, [])

   return (
      <div className={css.tabContainer2}>
         <PlaylistVisibilityToggle
            isVisible={isPlaylistsVisible}
            setIsVisible={setIsPlaylistsVisible}
         />
         <MainPlaylistSelector />
         <EditPlaylist />
      </div>
   )
}

export default PlaylistManager
