// WebsitesPage.jsx
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import css from './WebsitesPage.module.css'
import { Helmet } from 'react-helmet'
import WebsiteCard from '../WebsiteCard'
import WebsiteModal from '../WebsiteModal'

const WebsitesPage = () => {
   const [websites, setWebsites] = useState([])
   const [visible, setVisible] = useState(false)
   const [modalProps, setModalProps] = useState({})
   const [error, setError] = useState(null)

   useEffect(() => {
      fetchWebsites()
   }, [])

   const fetchWebsites = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}websitesRouter/`
         )
         setWebsites(response.data)
      } catch (error) {
         console.error('Error fetching websites:', error)
         setError('Error fetching websites')
      }
   }

   const handleOpenModal = (website) => {
      setModalProps(website)
      setVisible(true)
   }

   if (error) {
      return <div className={css.error}>Error: {error}</div>
   }

   return (
      <div>
         <Helmet>
            <title>Websites</title>
            <meta
               name="description"
               content="Explore nossa coleção de websites financeiros."
            />
         </Helmet>

         <h2 className={css.titulos}>Websites</h2>
         <div className={css.websiteContainer}>
            {websites.map((website) => (
               <WebsiteCard
                  key={website._id}
                  website={website}
                  onOpenModal={handleOpenModal}
               />
            ))}
         </div>

         {visible && (
            <WebsiteModal
               visible={visible}
               onModalClose={() => setVisible(false)}
               website={modalProps}
            />
         )}
      </div>
   )
}

export default WebsitesPage
