import React from 'react'
import { Button } from 'primereact/button'
import css from './AnnouncementStyles.module.css'
import SEOHelmet from '../../../../../components/SEOHelmet/SEOHelmet'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import './DialogStyles.css'

const AnnouncementItem = React.memo(
   ({ announcement, onEdit, onDelete }) => {
      return (
         <article className={css.card}>
            <SEOHelmet
               title={announcement.title}
               description={announcement.summary}
               imageUrl={announcement.imageUrl}
            />
            <h2 className={css.cardTitle}>{announcement.title}</h2>
            <p className={css.cardText}>{announcement.text}</p>
            <div className={css.cardActions}>
               <Button
                  label="Editar"
                  className="btnEditar"
                  onClick={() => onEdit(announcement)}
               />
               <Button
                  label="Apagar"
                  className="gradientButtonDelete"
                  onClick={() => onDelete(announcement.id)}
               />
            </div>
         </article>
      )
   },
   (prevProps, nextProps) => {
      return (
         prevProps.announcement.id === nextProps.announcement.id &&
         prevProps.announcement.text === nextProps.announcement.text
      )
   }
)

export default AnnouncementItem
