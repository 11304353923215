import React, { useContext, useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import styles from './IncomeDebtRatio.module.css'
import { DebtsContext } from '../../../../../../../../../context/DebtsContext'
import { UserContext } from '../../../../../../../../../context/useUserContext'

const IncomeDebtRatio = ({ selectedMonth }) => {
   const { debts } = useContext(DebtsContext)
   const { user } = useContext(UserContext)
   const [income, setIncome] = useState(Array(12).fill(0))
   const [percentage, setPercentage] = useState(0)
   const [error, setError] = useState(null)

   // Helper to get headers (assuming this logic is needed)
   const getAuthHeaders = useCallback(() => {
      return {
         headers: {
            Authorization: `Bearer ${user?.accessToken}`,
         },
      }
   }, [user])

   // Function to fetch income data
   const fetchIncomeData = useCallback(
      async (month, year) => {
         if (income[month - 1] && income[month - 1] !== 0) {
            console.log(`Using cached income for month ${month}`)
            return
         }

         if (!user || !user.accessToken) return

         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}incomeRoutes/user/${user.id}/month/${month}/year/${year}`,
               getAuthHeaders()
            )

            // Cache the fetched income
            setIncome((prev) => {
               const newIncome = [...prev]
               newIncome[month - 1] = response.data.totalIncome || 0
               return newIncome
            })
         } catch (error) {
            console.error('Error fetching income data:', error)
            setError('Failed to fetch income data.')
         }
      },
      [user, getAuthHeaders, income]
   )

   // Define getMonthlyDebtPayments directly in IncomeDebtRatio
   const getMonthlyDebtPayments = (selectedMonth) => {
      console.log(
         `Calculating payments for month: ${selectedMonth}`,
         debts[selectedMonth]
      )

      if (!debts[selectedMonth] || debts[selectedMonth].length === 0) {
         console.log('No debts found for this month.')
         return 0
      }

      let totalPayments = 0

      debts[selectedMonth].forEach((debt) => {
         if (debt.categories && debt.categories.length > 0) {
            const monthlyTotal = debt.categories.reduce((sum, category) => {
               console.log(
                  `Adding payment for category ${category.name}: ${category.payment}`
               )
               return sum + (category.payment || 0)
            }, 0)

            console.log(`Total payment for debt ${debt._id}:`, monthlyTotal)
            totalPayments += monthlyTotal
         } else {
            console.log(
               `No valid categories with payments for debt ${debt._id}`
            )
         }
      })

      console.log('Total debt payments for month:', totalPayments)
      return totalPayments
   }

   useEffect(() => {
      const currentYear = new Date().getFullYear()

      // Fetch income data for the selected month and current year
      fetchIncomeData(selectedMonth + 1, currentYear).then(() => {
         const currentIncome = income[selectedMonth] || 0
         console.log('currentIncome', currentIncome)

         const currentDebtPayments = getMonthlyDebtPayments(selectedMonth)
         console.log('currentDebtPayments', currentDebtPayments)

         const calculatedPercentage = currentIncome
            ? ((currentDebtPayments / currentIncome) * 100).toFixed(2)
            : 0
         console.log('calculatedPercentage', calculatedPercentage)

         setPercentage(calculatedPercentage)
      })
   }, [selectedMonth, fetchIncomeData, income, debts])

   return (
      <div className={styles.box}>
         <h3 className={styles.title}>
            Percentagem de Rendimento Gasto em Dívidas
         </h3>
         <p className={styles.percentage}>{percentage}%</p>
         {error && <p className={styles.error}>{error}</p>}
      </div>
   )
}

export default IncomeDebtRatio
