import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import css from '../FinancialHealthForm.module.css' // Assuming you're using CSS modules

const FinancialHealthNav = () => {
   return (
      <div>
         <nav className={css.tabNavigation}>
            <NavLink
               to="resumoOrcamento"
               className={({ isActive }) =>
                  isActive ? css.activeTab : css.tab
               }
            >
               Resumo Orçamento
            </NavLink>
            <NavLink
               to="income"
               className={({ isActive }) =>
                  isActive ? css.activeTab : css.tab
               }
            >
               Rendimento
            </NavLink>
            <NavLink
               to="expenses"
               className={({ isActive }) =>
                  isActive ? css.activeTab : css.tab
               }
            >
               Despesas
            </NavLink>
            <NavLink
               to="debts"
               className={({ isActive }) =>
                  isActive ? css.activeTab : css.tab
               }
            >
               Dívidas
            </NavLink>
            <NavLink
               to="savings"
               className={({ isActive }) =>
                  isActive ? css.activeTab : css.tab
               }
            >
               Poupanças
            </NavLink>
            {/* <NavLink
               to="insurances"
               className={({ isActive }) =>
                  isActive ? css.activeTab : css.tab
               }
            >
               Seguros
            </NavLink> */}
         </nav>

         {/* Outlet to render the selected section */}
         <Outlet />
      </div>
   )
}

export default FinancialHealthNav
