import React, { useEffect, useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import css from '../DashboardCreatorComponents.module.css'
import { useDashboard } from '../../../../../context/DashboardContext'
import { Button } from 'primereact/button'
import Select from 'react-select'

const CreatorDetails = () => {
   const { formData, setFormData, updateFormData } = useDashboard()
   const fileInputRef = useRef(null) // Ref para o input de ficheiros
   const [topicsOptions, setTopicsOptions] = useState([])

   useEffect(() => {
      const fetchTopics = async () => {
         try {
            const response = await fetch(
               `${process.env.REACT_APP_API_URL}/users/topics`
            )
            if (response.ok) {
               const data = await response.json()
               setTopicsOptions(
                  data.map((topic) => ({ label: topic, value: topic }))
               )
            } else {
               console.error('Error fetching topics:', await response.text())
            }
         } catch (error) {
            console.error('Error fetching topics:', error)
         }
      }

      fetchTopics()
   }, [])
   // Pré-visualização da imagem ao carregar nova foto
   const handleFileChange = (event) => {
      const file = event.target.files[0]
      if (!file) return

      const objectUrl = URL.createObjectURL(file)
      setFormData((prev) => ({
         ...prev,
         profilePicturePreview: objectUrl,
         newImageFile: file, // Guardar o novo arquivo
      }))
   }
   console.log('formData', formData.id)
   // Salvar Alterações (inclui imagem e detalhes do perfil)
   const handleSave = async () => {
      const formDataToUpload = new FormData()

      // Adicionar imagem se uma nova foi selecionada
      if (formData.newImageFile) {
         formDataToUpload.append('image', formData.newImageFile)
      }

      // Preparar outros campos do formulário
      const payload = {
         id: formData.id,
         username: formData.username,
         firstname: formData.firstname,
         lastname: formData.lastname,
         email: formData.email,
         bio: formData.bio,
         role: formData.role,
         profilePictureUrl: formData.profilePictureUrl,
         topics: formData.topics || [], // Garantir que `topics` seja sempre um array
         socialMediaLinks: formData.socialMediaLinks || [], // Garantir que `socialMediaLinks` seja sempre um array
         website: formData.website || '', // Campo opcional
         publicationFrequency: formData.publicationFrequency || 'Ocasional', // Campo opcional com valor padrão
      }

      // Adicionar dados ao FormData
      Object.entries(payload).forEach(([key, value]) => {
         if (Array.isArray(value)) {
            // Serializar arrays como JSON
            formDataToUpload.append(key, JSON.stringify(value))
         } else {
            formDataToUpload.append(key, value)
         }
      })

      try {
         const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/updateCreator/${formData.id}`,
            {
               method: 'PUT',
               body: formDataToUpload,
            }
         )

         if (response.ok) {
            const updatedData = await response.json()
            setFormData(updatedData) // Atualizar o estado global com os novos dados
            console.log('Profile updated successfully:', updatedData)
         } else {
            console.error('Failed to update:', await response.text())
         }
      } catch (error) {
         console.error('Error during save:', error)
      }
   }

   // Atualizar campos de texto no formulário
   const handleChange = (e) => {
      const { name, value } = e.target
      updateFormData({ [name]: value })
   }

   // Opções de frequência de publicação
   const publicationFrequencies = [
      { label: 'Diário', value: 'Diário' },
      { label: 'Semanal', value: 'Semanal' },
      { label: 'Mensal', value: 'Mensal' },
      { label: 'Ocasional', value: 'Ocasional' },
   ]
   const handleTopicsChange = (selectedOptions) => {
      const selectedValues = selectedOptions.map((option) => option.value) // Extrai apenas os valores como strings
      updateFormData({ topics: selectedValues })
   }

   return (
      <div className={css.profileContainer}>
         <div className={css.profileImageSection}>
            <input
               id="fileInput"
               type="file"
               ref={fileInputRef}
               onChange={handleFileChange}
               style={{ display: 'none' }}
            />
            {formData.profilePicturePreview ? (
               <img
                  src={formData.profilePicturePreview}
                  alt="Pré-visualização do Perfil"
                  className={css.imageProfile}
               />
            ) : formData.profilePictureUrl ? (
               <img
                  src={formData.profilePictureUrl}
                  alt="Imagem do Perfil"
                  className={css.imageProfile}
               />
            ) : (
               <div className={css.placeholder}>Sem imagem</div>
            )}
            <Button
               className={`${css.saveButton} ${css.primaryButton}`}
               onClick={() => fileInputRef.current.click()}
            >
               Trocar Imagem
            </Button>
         </div>

         <div className={css.detailsSection}>
            <div className={css.sectionTitle}>Informações Básicas</div>
            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Username:</label>
               <InputText
                  type="text"
                  name="username"
                  value={formData.username || ''}
                  onChange={handleChange}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Nome:</label>
               <InputText
                  type="text"
                  name="firstname"
                  value={formData.firstname || ''}
                  onChange={handleChange}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Último Nome:</label>
               <InputText
                  type="text"
                  name="lastname"
                  value={formData.lastname || ''}
                  onChange={handleChange}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Email:</label>
               <InputText
                  type="text"
                  name="email"
                  value={formData.email || ''}
                  onChange={handleChange}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Bio:</label>
               <InputTextarea
                  rows={5}
                  name="bio"
                  value={formData.bio || ''}
                  onChange={handleChange}
                  placeholder="Adicione uma breve descrição sobre você"
               />
            </div>

            <div className={css.sectionTitle}>Detalhes Adicionais</div>
            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Role:</label>
               <InputText
                  type="text"
                  name="role"
                  value={formData.role || ''}
                  onChange={handleChange}
                  disabled
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Topics:</label>
               <Select
                  isMulti
                  value={topicsOptions.filter((option) =>
                     (formData.topics || []).includes(option.value)
                  )} // Filtrar os tópicos selecionados
                  options={topicsOptions}
                  onChange={handleTopicsChange}
                  placeholder="Selecione tópicos"
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Website:</label>
               <InputText
                  type="text"
                  name="website"
                  value={formData.website || ''}
                  onChange={handleChange}
                  placeholder="Adicione seu website"
               />
            </div>

            <div className={css.inputGroup1}>
               <label className={css.labelCreator}>
                  Frequência de Publicação:{' '}
               </label>
               <Dropdown
                  name="publicationFrequency"
                  value={formData.publicationFrequency || ''}
                  options={publicationFrequencies}
                  onChange={(e) =>
                     updateFormData({ publicationFrequency: e.value })
                  }
                  placeholder="Selecione a frequência"
                  style={{ marginLeft: '10px' }}
               />
            </div>

            <div className={css.inputGroup}>
               <label className={css.labelCreator}>Aderiu:</label>
               <InputText
                  type="text"
                  name="createdAt"
                  value={
                     formData.createdAt
                        ? new Date(formData.createdAt).toLocaleDateString(
                             'pt-PT'
                          )
                        : ''
                  }
                  disabled
               />
            </div>

            <Button
               label="Guardar Alterações"
               className={css.saveButton}
               onClick={handleSave}
            />
         </div>
      </div>
   )
}

export default CreatorDetails
