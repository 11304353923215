import React, { useMemo } from 'react'
import { Col } from 'reactstrap'

const SavingsProgress = ({
   savings = [],
   goals = [],
   categories = [],
   selectedMonth,
}) => {
   // Calculate total savings up to the selected month for each category
   const totalSavingsByCategory = useMemo(() => {
      const cumulativeSavings = {}

      // Iterate through savings up to the selected month
      for (let i = 0; i <= selectedMonth; i++) {
         if (!savings[i] || !savings[i].categories) continue

         savings[i].categories.forEach((cat) => {
            if (cumulativeSavings[cat.id]) {
               cumulativeSavings[cat.id] += cat.savings || 0
            } else {
               cumulativeSavings[cat.id] = cat.savings || 0
            }
         })
      }

      return cumulativeSavings
   }, [savings, selectedMonth])

   const calculateProgress = (categoryId) => {
      const actual = totalSavingsByCategory[categoryId] || 0
      const goal =
         goals.find((goal) => goal.categoryId === categoryId)?.goalAmount || 0
      return goal ? Math.min((actual / goal) * 100, 100) : 0
   }

   const getBarColor = (progress) => {
      return progress === 100 ? '#4CAF50' : '#36A2EB'
   }

   return (
      <Col xs={12}>
         {categories.map((category) => {
            const progress = calculateProgress(category.id)
            const actual = totalSavingsByCategory[category.id] || 0
            const goal =
               goals.find((goal) => goal.categoryId === category.id)
                  ?.goalAmount || 0

            return (
               <div key={category.id}>
                  <p style={{ marginTop: '10px', marginBottom: '5px' }}>
                     {category.name}: {`${actual.toFixed(2)}€ / ${goal}€`} (
                     {progress.toFixed(2)}%)
                  </p>
                  <div
                     style={{
                        backgroundColor: '#e0e0e0',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        position: 'relative',
                        height: '24px',
                     }}
                  >
                     <div
                        style={{
                           width: `${progress}%`,
                           backgroundColor: getBarColor(progress),
                           height: '100%',
                           textAlign: 'center',
                           color: 'white',
                           lineHeight: '24px',
                           position: 'absolute',
                           top: 0,
                           left: 0,
                        }}
                     >
                        {progress.toFixed(2)}%
                     </div>
                  </div>
               </div>
            )
         })}
      </Col>
   )
}

export default SavingsProgress
