import React from 'react'
import { Button } from 'primereact/button'
import { toast } from 'react-toastify'
import axios from 'axios'
import css from './Articles.module.css'
import convertContentToHTML from '../../../../../utils/convertContentToHTML'
import { handleError } from '../../../../../utils/handleError'

function ArticleItem({ article, onEditArticle, onDelete }) {
   return (
      <div className={css.articleCard}>
         {article.imageUrl && (
            <img
               src={article.imageUrl}
               alt={article.title}
               className={css.articleImage}
            />
         )}
         <div className={css.cardContent}>
            <h3>{article.title}</h3>
            <div
               dangerouslySetInnerHTML={{
                  __html: convertContentToHTML(article.content),
               }}
            />
            <div className={css.buttonGroup}>
               <Button
                  onClick={() => onEditArticle(article)}
                  className="p-button-success"
               >
                  Editar Artigo
               </Button>
               <Button
                  onClick={() => onDelete(article._id)}
                  className="p-button-danger"
               >
                  Apagar Artigo
               </Button>
            </div>
         </div>
      </div>
   )
}

export default ArticleItem
