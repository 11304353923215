import React from 'react'

const GoalsForm = () => {
   return (
      <div>
         <h2>Goals Form</h2>
         {/* Add your form fields for goals here */}
      </div>
   )
}

export default GoalsForm
