import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { Button } from 'primereact/button'
import axios from 'axios'
import { SavingsContext } from '../../../../../../../context/SavingsContext'
import MonthTabs from './components/savingsFormComponents/MonthTabs'
import SavingsFormInputs from './components/savingsFormComponents/SavingsFormInputs'
import { UserContext } from '../../../../../../../context/useUserContext'
import SavingsNav from './components/savingsFormComponents/SavingsNav'
import SavingsPieChart from './components/savingsFormComponents/SavingsPieChart'
import SavingsProgress from './components/savingsFormComponents/SavingsProgress'
import SavingsBarChart from './components/savingsFormComponents/SavingsBarChart'
import SavingsOverTimeChart from './components/savingsFormComponents/SavingsOverTimeChart'
import EmergencyFundSuggestion from './components/savingsFormComponents/EmergencyFundSuggestion'

const SavingsForm = ({ onSavingsSaved }) => {
   const { savings, setSavings, categories, updateSavings, setCategories } =
      useContext(SavingsContext)
   const [isEditing, setIsEditing] = useState(false)
   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
   const [submissionMessage, setSubmissionMessage] = useState('')
   const [errorMessage, setErrorMessage] = useState('')
   const [goals, setGoals] = useState([])
   const { user } = useContext(UserContext)
   const userId = user.id

   useEffect(() => {
      fetchInitialData()
   }, [userId])

   useEffect(() => {
      if (
         savings[selectedMonth] &&
         savings[selectedMonth].categories &&
         savings[selectedMonth].categories.some((cat) => cat.savings > 0)
      ) {
         setIsEditing(true)
      } else {
         setIsEditing(false)
      }
   }, [selectedMonth, savings])

   const fetchInitialData = async () => {
      try {
         const savingsData = await fetchYearlySavings(userId, user.accessToken)
         const userCategories = await fetchUserCategories(
            userId,
            user.accessToken
         )
         const userGoals = await fetchGoals(userId, user.accessToken)

         setSavings(savingsData)
         setCategories(userCategories)
         setGoals(userGoals)
      } catch (error) {
         setErrorMessage('Failed to fetch savings and goals')
         console.error('Error fetching initial data:', error)
      }
   }

   const fetchUserCategories = async (userId, token) => {
      const response = await axios.get(
         `${process.env.REACT_APP_API_URL}users/${userId}/savings-categories`,
         { headers: { Authorization: `Bearer ${token}` } }
      )
      return response.data || []
   }

   const fetchYearlySavings = async (userId, token) => {
      const response = await axios.get(
         `${process.env.REACT_APP_API_URL}/savingRoutes/user/${userId}/yearly-savings`,
         { headers: { Authorization: `Bearer ${token}` } }
      )
      return response.data.savings || []
   }

   const fetchGoals = async (userId, token) => {
      const response = await axios.get(
         `${process.env.REACT_APP_API_URL}/savingRoutes/user/${userId}/goals`,
         { headers: { Authorization: `Bearer ${token}` } }
      )
      return response.data || []
   }

   const handleSave = async () => {
      const currentYear = new Date().getFullYear()
      const monthData = savings[selectedMonth]

      try {
         let response
         if (monthData?._id) {
            response = await updateSavingsEntry(monthData)
         } else {
            response = await createSavingsEntry(monthData, currentYear)
         }
         updateSavings(selectedMonth, response.data.saving)
         setSubmissionMessage(
            `Savings for ${selectedMonth + 1} saved successfully!`
         )
         onSavingsSaved && onSavingsSaved()
      } catch (error) {
         console.error(
            'Error saving savings:',
            error.response ? error.response.data : error
         )
         setSubmissionMessage('An error occurred while saving the savings.')
      }
   }

   const updateSavingsEntry = async (data) => {
      return axios.put(
         `${process.env.REACT_APP_API_URL}/savingRoutes/${data._id}`,
         {
            userId,
            month: selectedMonth + 1,
            year: new Date().getFullYear(),
            categories: data.categories,
         },
         { headers: { Authorization: `Bearer ${user.accessToken}` } }
      )
   }

   const createSavingsEntry = async (data, year) => {
      return axios.post(
         `${process.env.REACT_APP_API_URL}/savingRoutes/`,
         {
            userId,
            month: selectedMonth + 1,
            year,
            categories: data.categories,
         },
         { headers: { Authorization: `Bearer ${user.accessToken}` } }
      )
   }

   return (
      <div>
         <h2>Preencha suas Poupanças</h2>

         <MonthTabs
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
         />

         <SavingsNav />
         {submissionMessage && <p>{submissionMessage}</p>}
         {errorMessage && (
            <p style={{ color: 'red', marginBottom: '20px' }}>{errorMessage}</p>
         )}
         <Row style={{ display: 'flex' }}>
            <Col xs={6}>
               <SavingsFormInputs
                  categories={categories}
                  selectedMonth={selectedMonth}
               />
               <Button onClick={handleSave} size="small">
                  {isEditing ? 'Update' : 'Save'}
               </Button>
            </Col>
            <Col xs={6}>
               <div>
                  <h3 style={{ color: 'white', marginTop: '30px' }}>
                     Progresso das Poupanças
                  </h3>
                  <SavingsProgress
                     savings={savings} // Pass the entire savings array
                     goals={goals}
                     categories={categories}
                     selectedMonth={selectedMonth} // Pass the selected month index
                  />

                  <Col style={{ marginTop: '20px', height: '300px' }}>
                     <h3 style={{ color: 'white' }}>
                        Distribuição de Poupanças
                     </h3>
                     <SavingsPieChart selectedMonth={selectedMonth} />
                  </Col>
               </div>
            </Col>
         </Row>
         <Row>
            <EmergencyFundSuggestion debts={[]} onSetGoal={() => {}} />
         </Row>
         <Row
            className="tabContainer2"
            style={{
               display: 'flex',
               alignItems: 'flex-start',
               justifyContent: 'center',
               marginTop: '30px',
            }}
         >
            <Col xs={5} style={{ marginTop: '20px', height: '400px' }}>
               <h3 style={{ color: 'white' }}>Poupanças ao Longo do Tempo</h3>
               <SavingsOverTimeChart />
            </Col>

            <Col
               xs={5}
               style={{
                  marginTop: '20px',
                  height: '400px',
                  marginLeft: '20px',
               }}
            >
               <h3 style={{ color: 'white' }}>Poupanças por Categoria</h3>
               <SavingsBarChart selectedMonth={selectedMonth} />
            </Col>
         </Row>
      </div>
   )
}

export default SavingsForm
