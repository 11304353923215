import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css' // Estilos para o Datepicker
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../../context/useUserContext'
import { Col, Row } from 'react-bootstrap'
import { useEventContext } from '../../../../../../context/EventManagerContext'
import axios from 'axios'

const EventCreationForm = ({ event, onClose }) => {
   const { user } = useContext(UserContext)
   const { fetchEvents } = useEventContext()
   const [eventData, setEventData] = useState({
      name: '', // String vazia para InputText
      description: '', // String vazia para Editor
      topic: '', // String vazia para Dropdown
      link: '', // String vazia para InputText
      date: null, // `null` para o Calendar ou DatePicker
      startTime: '', // String vazia para InputMask
      duration: null, // `null` para InputNumber
      bannerImage: null,
   })
   const [bannerImage, setBannerImage] = useState(null)
   const [isSaving, setIsSaving] = useState(false)

   const topicOptions = [
      { label: 'ETFs', value: 'ETFs' },
      { label: 'Stocks', value: 'Stocks' },
   ]

   useEffect(() => {
      if (event) {
         setEventData({
            name: event.name || '',
            description: event.description || '',
            topic: event.topic || '',
            link: event.link || '',
            date: event.date ? new Date(event.date) : null,
            startTime: event.startTime || '',
            duration: event.duration || null,
            bannerImage: null,
         })
         if (event.bannerImage) {
            setBannerImage(event.bannerImage)
         }
      } else {
         resetForm()
      }
   }, [event])

   const handleInputChange = (name, value) => {
      setEventData((prev) => ({ ...prev, [name]: value }))
   }

   const handleSubmit = async (e) => {
      e.preventDefault()

      if (!eventData.name || !eventData.date) {
         toast.error('Por favor, preencha todos os campos obrigatórios.')
         return
      }

      if (bannerImage && typeof bannerImage !== 'string') {
         const allowedTypes = ['image/jpeg', 'image/png', 'image/webp']
         const maxSize = 5 * 1024 * 1024 // 5MB

         if (!allowedTypes.includes(bannerImage.type)) {
            toast.error(
               'Formato de imagem não suportado. Use JPG, PNG ou WEBP.'
            )
            return
         }
         if (bannerImage.size > maxSize) {
            toast.error('Imagem muito grande. O tamanho máximo é 5MB.')
            return
         }
      }

      setIsSaving(true)
      const formData = new FormData()
      Object.entries(eventData).forEach(([key, value]) => {
         if (key === 'date' && value) {
            formData.append(key, value.toISOString()) // Converte a data para ISO
         } else if (value !== null && value !== undefined) {
            formData.append(key, value)
         }
      })

      formData.append('creator', user.id)

      if (bannerImage && typeof bannerImage !== 'string') {
         formData.append('bannerImage', bannerImage)
      }

      console.log('Dados enviados para a API:', [...formData.entries()])

      try {
         const method = event?._id ? 'put' : 'post'
         const url = event?._id
            ? `${process.env.REACT_APP_API_URL}/eventRoutes/${event._id}`
            : `${process.env.REACT_APP_API_URL}/eventRoutes/`

         await axios[method](url, formData, {
            headers: {
               Authorization: `Bearer ${user.accessToken}`,
            },
         })

         fetchEvents()
         toast.success(
            `Evento ${event?._id ? 'atualizado' : 'criado'} com sucesso!`
         )
         onClose()
      } catch (error) {
         console.error('Erro ao salvar evento:', error)
         toast.error(`Erro ao ${event?._id ? 'atualizar' : 'criar'} o evento.`)
      } finally {
         setIsSaving(false)
      }
   }

   const resetForm = () => {
      setEventData({
         name: '',
         description: '',
         topic: '',
         link: '',
         date: null,
         startTime: '',
         duration: null,
         bannerImage: null,
      })
      setBannerImage(null)
   }

   return (
      <form onSubmit={handleSubmit}>
         <Row className="event-form">
            <Col>
               <div className="form-field">
                  <label htmlFor="name">Nome do Evento</label>
                  <input
                     type="text"
                     id="name"
                     value={eventData.name}
                     onChange={(e) => handleInputChange('name', e.target.value)}
                     required
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="description">Descrição</label>
                  <textarea
                     id="description"
                     value={eventData.description}
                     onChange={(e) =>
                        handleInputChange('description', e.target.value)
                     }
                     style={{ height: '100px', width: '100%' }}
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="topic">Selecionar Tema</label>
                  <select
                     value={eventData.topic}
                     onChange={(e) =>
                        handleInputChange('topic', e.target.value)
                     }
                  >
                     <option value="">Selecione um tema</option>
                     {topicOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                           {option.label}
                        </option>
                     ))}
                  </select>
               </div>
               <div className="form-field">
                  <label htmlFor="link">Link do Evento</label>
                  <input
                     type="text"
                     id="link"
                     value={eventData.link}
                     onChange={(e) => handleInputChange('link', e.target.value)}
                  />
               </div>
            </Col>
            <Col>
               <div className="form-field">
                  <label htmlFor="date">Data</label>
                  <DatePicker
                     id="date"
                     selected={eventData.date}
                     onChange={(date) => handleInputChange('date', date)}
                     dateFormat="dd/MM/yyyy"
                     placeholderText="Selecione uma data"
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="startTime">Hora de Início</label>
                  <input
                     type="text"
                     id="startTime"
                     value={eventData.startTime}
                     onChange={(e) =>
                        handleInputChange('startTime', e.target.value)
                     }
                     placeholder="HH:mm"
                     required
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="duration">Duração (minutos)</label>
                  <input
                     type="number"
                     id="duration"
                     value={eventData.duration}
                     onChange={(e) =>
                        handleInputChange('duration', e.target.value)
                     }
                     required
                  />
               </div>
               <div className="form-field">
                  <label htmlFor="bannerImage">Imagem do Banner</label>
                  <input
                     type="file"
                     id="bannerImage"
                     onChange={(e) => setBannerImage(e.target.files[0])}
                     accept="image/*"
                  />
               </div>
            </Col>
         </Row>
         <button type="submit" disabled={isSaving}>
            {isSaving ? 'Salvando...' : 'Salvar'}
         </button>
      </form>
   )
}

export default EventCreationForm
