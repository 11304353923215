import React, {
   createContext,
   useState,
   useEffect,
   useContext,
   useCallback,
} from 'react'
import axios from 'axios'
import { UserContext } from './useUserContext'

export const DebtsContext = createContext()

export const DebtsProvider = ({ children }) => {
   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
   const [debts, setDebts] = useState(Array(12).fill([])) // Initialize debts for each month
   const [categories, setCategories] = useState([])
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState(null)
   const { user, isLoading } = useContext(UserContext)

   // Helper to get headers
   const getAuthHeaders = useCallback(() => {
      return {
         headers: {
            Authorization: `Bearer ${user?.accessToken}`,
         },
      }
   }, [user])

   // Fetch categories for the user
   const fetchCategories = useCallback(async () => {
      if (!user || !user.accessToken) return

      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}users/${user.id}/debt-categories`,
            getAuthHeaders()
         )
         setCategories(response.data)
      } catch (error) {
         console.error('Error fetching debt categories:', error)
         setError('Failed to fetch debt categories')
      }
   }, [user, getAuthHeaders])

   // Fetch yearly debt data for all months
   const fetchYearlyData = useCallback(async () => {
      if (!user || !user.accessToken) return

      const currentYear = new Date().getFullYear()
      const updatedDebts = Array(12).fill(null)

      setLoading(true)

      try {
         await Promise.all(
            updatedDebts.map(async (_, i) => {
               try {
                  const response = await axios.get(
                     `${process.env.REACT_APP_API_URL}debtRoutes/user/${
                        user.id
                     }/month/${i + 1}/year/${currentYear}`,
                     getAuthHeaders()
                  )
                  updatedDebts[i] = response.data || []
               } catch (error) {
                  if (error.response && error.response.status === 404) {
                     console.warn(
                        `No debt data found for month ${i + 1}, clearing data.`
                     )
                     // Clear debts for the month on 404 error
                     updatedDebts[i] = []
                  } else {
                     throw error
                  }
               }
            })
         )
         setDebts(updatedDebts)
      } catch (error) {
         console.error('Error fetching yearly debts:', error)
         setError('Failed to fetch yearly debt data')
      } finally {
         setLoading(false)
      }
   }, [user, getAuthHeaders])

   // Only fetch data if the user is logged in and their token is available
   useEffect(() => {
      if (user && user.accessToken && !isLoading) {
         fetchCategories()
         fetchYearlyData()
      }
   }, [user, isLoading, fetchCategories, fetchYearlyData])

   // Update debts function
   const updateDebts = (monthIndex, updatedData) => {
      setDebts((prev) =>
         prev.map((debt, idx) =>
            idx === monthIndex
               ? [...(Array.isArray(updatedData) ? updatedData : [updatedData])]
               : debt
         )
      )
   }

   // Update categories in the backend and locally
   const updateCategories = async (updatedCategories) => {
      if (!user || !user.accessToken) return

      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}users/${user.id}/debt-categories`,
            { categories: updatedCategories },
            getAuthHeaders()
         )
         setCategories(updatedCategories)
      } catch (error) {
         console.error('Error updating debt categories:', error)
         setError('Failed to update categories')
      }
   }

   return (
      <DebtsContext.Provider
         value={{
            debts,
            categories,
            setCategories: updateCategories,
            selectedMonth,
            setSelectedMonth,
            updateDebts,
            error, // Expose error to be handled in the consuming components
            loading, // Expose loading state to consuming components
         }}
      >
         {children}
      </DebtsContext.Provider>
   )
}
