import React, { useContext, useState, useEffect } from 'react'
import { Row } from 'reactstrap'
import DebtRanking from './DebtRanking'
import { DebtsContext } from '../../../../../../../../../context/DebtsContext'
import DebtNavigation from './DebtNavigation'
import { rankDebts } from './debtSimulations/DebtUtils' // Import the utility function

export default function DebtDestroyer() {
   const { debts, selectedMonth } = useContext(DebtsContext)
   const [disposableIncome, setDisposableIncome] = useState(0) // Test value
   const [selectedMethod, setSelectedMethod] = useState('snowball')
   const [rankedDebts, setRankedDebts] = useState([])

   // New state for total debt and total interest
   const [totalDebt, setTotalDebt] = useState(0)
   const [totalInterest, setTotalInterest] = useState(0)

   // Clean up the debts array to remove nulls
   const cleanedDebts = debts
      .filter((debt) => Array.isArray(debt) && debt.length > 0)
      .map((debtArray) => debtArray[0]) // Assuming you need the first object within non-empty arrays

   console.log('Cleaned Debts Array:', cleanedDebts)
   console.log('Cleaned rankDebts Array:', rankDebts)
   // Access the first non-null debt object (you may have more than one entry in the array)
   // Access the first non-null debt object directly
   // Access the most recent debt entry (last item in the array)
   const currentDebtEntry =
      cleanedDebts.length > 0 ? cleanedDebts[cleanedDebts.length - 1] : {}

   // Extract the categories (list of debts)
   const currentMonthDebts = currentDebtEntry.categories || []

   // Verify the extraction
   console.log('Current Debt Entry Object:', currentDebtEntry)
   console.log('Debt Categories for Current Month:', currentMonthDebts)

   // Function to simulate repayment using the imported rankDebts utility function
   const simulateRepayment = (debts, method, disposableIncome) => {
      if (!Array.isArray(debts) || debts.length === 0)
         return { ranked: [], totalInterestPaid: 0 }

      console.log('Simulating Repayment:', debts)
      const ranked = rankDebts([...debts], method)
      let totalInterestPaid = 0

      ranked.forEach((debt) => {
         let months = 0
         let balance = parseFloat(debt.remainingBalance) || 0
         const interestRate = (parseFloat(debt.interestRate) || 0) / 100 / 12
         const minPayment = parseFloat(debt.payment) || 0

         while (balance > 0) {
            const interest = balance * interestRate
            totalInterestPaid += interest
            let payment = Math.min(
               minPayment + disposableIncome,
               balance + interest
            )
            let principalPayment = payment - interest
            balance -= principalPayment

            months++
            if (months > 500) break
         }

         debt.monthsToPayOff = months
         debt.totalInterestPaid = totalInterestPaid // Keep track of interest for each debt
      })

      return { ranked, totalInterestPaid }
   }

   useEffect(() => {
      if (currentMonthDebts.length > 0) {
         // Run the repayment simulation
         const { ranked, totalInterestPaid } = simulateRepayment(
            currentMonthDebts,
            selectedMethod,
            disposableIncome
         )

         setRankedDebts(ranked)

         // Calculate total debt and update total interest from the simulation result
         const totalDebtAmount = ranked.reduce(
            (acc, debt) => acc + (parseFloat(debt.remainingBalance) || 0),
            0
         )
         setTotalDebt(totalDebtAmount)
         setTotalInterest(totalInterestPaid)
      }
   }, [currentMonthDebts, selectedMethod, disposableIncome])

   return (
      <div>
         <Row>
            <h2>Simula o pagamento das tuas dívidas</h2>

            <DebtNavigation />

            {rankedDebts.length > 0 ? (
               <DebtRanking
                  debts={rankedDebts}
                  disposableIncome={disposableIncome}
                  setDisposableIncome={setDisposableIncome}
                  totalDebt={totalDebt}
                  totalInterest={totalInterest}
               />
            ) : (
               <ul>
                  {currentMonthDebts.map((debt) => (
                     <li key={debt.id}>
                        {debt.name}: {debt.remainingBalance}€
                     </li>
                  ))}
               </ul>
            )}
         </Row>
      </div>
   )
}
