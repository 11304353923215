import React, {
   createContext,
   useState,
   useEffect,
   useCallback,
   useContext,
} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from './useUserContext'
import { DebtsContext } from './DebtsContext'

const ExpensesContext = createContext()

export const ExpensesProvider = ({ children }) => {
   const [expenses, setExpenses] = useState(Array(12).fill(null)) // Cache expenses per month
   const [income, setIncome] = useState(Array(12).fill(null)) // Cache income per month
   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
   const { debts, setDebts } = useContext(DebtsContext)
   const [submissionMessage, setSubmissionMessage] = useState('')
   const [isEditing, setIsEditing] = useState(false)
   const [categories, setCategories] = useState([])
   const [actualExpenses, setActualExpenses] = useState({})
   const [budgets, setBudgets] = useState({})
   const [recurringExpenses, setRecurringExpenses] = useState([])
   const [savingsSuggestions, setSavingsSuggestions] = useState([])
   const [whatIfChanges, setWhatIfChanges] = useState({})
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState(null)
   const { user, isLoading } = useContext(UserContext)
   const [cacheTimestamps, setCacheTimestamps] = useState(Array(12).fill(null)) // Cache expiration timestamps
   const CACHE_EXPIRATION_TIME = 3600000 // 1 hour expiration

   const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
   ]

   // Helper to get auth headers
   const getAuthHeaders = useCallback(() => {
      return {
         headers: {
            Authorization: `Bearer ${user?.accessToken}`,
         },
      }
   }, [user])

   const fetchMonthlyExpenses = useCallback(
      async (month, year) => {
         const now = Date.now()

         if (
            expenses[month - 1] &&
            cacheTimestamps[month - 1] &&
            now - cacheTimestamps[month - 1] < CACHE_EXPIRATION_TIME
         ) {
            console.log(`Using cached expenses for month ${month}`)
            return
         }

         if (!user || !user.accessToken) return
         setLoading(true)

         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}expenseRoutes/user/${user.id}/month/${month}/year/${year}`,
               getAuthHeaders()
            )

            const monthData = response.data
            console.log('monthData', monthData)

            setExpenses((prev) => {
               const newExpenses = [...prev]
               newExpenses[month - 1] = monthData
               return newExpenses
            })
            setCacheTimestamps((prev) => {
               const newTimestamps = [...prev]
               newTimestamps[month - 1] = now
               return newTimestamps
            })

            if (monthData.categories && categories.length === 0) {
               setCategories(monthData.categories)
            }
         } catch (error) {
            console.error('Error fetching expenses for month:', error)

            // Show toast if there's no existing toast for this error
            // if (!toast.isActive('expense-fetch-error')) {
            //    toast.error('Failed to load expenses data. Please try again.', {
            //       toastId: 'expense-fetch-error',
            //    })
            // }
         } finally {
            setLoading(false)
         }
      },
      [user, getAuthHeaders, categories, expenses, cacheTimestamps]
   )

   // Fetch income data with cache expiration and validation
   const fetchIncomeData = useCallback(
      async (month, year) => {
         if (income[month - 1] && income[month - 1] !== 0) {
            console.log(`Using cached income for month ${month}`)
            return
         }

         if (!user || !user.accessToken) return

         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}incomeRoutes/user/${user.id}/month/${month}/year/${year}`,
               getAuthHeaders()
            )

            setIncome((prev) => {
               const newIncome = [...prev]
               newIncome[month - 1] = response.data.totalIncome || 0
               return newIncome
            })
         } catch (error) {
            console.error('Error fetching income data:', error)

            // Show toast with a unique toastId to avoid repetition
            // if (!toast.isActive('income-fetch-error')) {
            //    toast.error('Failed to fetch income data.', {
            //       toastId: 'income-fetch-error',
            //    })
            // }
         }
      },
      [user, getAuthHeaders, income]
   )

   useEffect(() => {
      const currentYear = new Date().getFullYear()

      if (user && user.accessToken && !isLoading && selectedMonth !== null) {
         // Check if data is already cached for the current month
         const now = Date.now()
         const isDataCached =
            expenses[selectedMonth] &&
            cacheTimestamps[selectedMonth] &&
            now - cacheTimestamps[selectedMonth] < CACHE_EXPIRATION_TIME

         if (!isDataCached) {
            // Fetch data for the selected month
            fetchMonthlyExpenses(selectedMonth + 1, currentYear)
            fetchIncomeData(selectedMonth + 1, currentYear)
         }
      }
   }, [
      user,
      isLoading,
      selectedMonth,
      fetchMonthlyExpenses,
      fetchIncomeData,
      expenses,
      cacheTimestamps,
   ])
   // Sync function to ensure expenses reflect changes in debts
   useEffect(() => {
      if (debts && debts[selectedMonth]) {
         setExpenses((prev) => {
            const updatedExpenses = [...prev]
            const currentExpenses = updatedExpenses[selectedMonth] || {
               categories: [],
            }
            const debtCategories = debts[selectedMonth]

            // Ensure all category IDs are strings for consistency
            currentExpenses.categories = currentExpenses.categories.map(
               (cat) => ({
                  ...cat,
                  id: String(cat.id),
               })
            )

            // Filter existing categories that do not include 'debt'
            currentExpenses.categories = currentExpenses.categories.filter(
               (cat) =>
                  cat && cat.name && !cat.name.toLowerCase().includes('debt')
            )

            // Add debt categories, ensuring their IDs are also strings
            currentExpenses.categories.push(
               ...debtCategories.map((cat) => ({
                  ...cat,
                  id: String(cat.id),
                  subcategories: (cat.subcategories || []).map((subcat) => ({
                     ...subcat,
                     id: String(subcat.id),
                  })),
               }))
            )

            console.log('Updated categories:', currentExpenses.categories)
            updatedExpenses[selectedMonth] = currentExpenses
            return updatedExpenses
         })
      }
   }, [debts, selectedMonth])

   const handleSaveOrUpdate = async () => {
      // Get the current expense data for the selected month
      let expenseData = expenses[selectedMonth] || { categories: [] }
      const currentYear = new Date().getFullYear()

      // Check if the expenseData has categories or not
      if (!expenseData.categories || expenseData.categories.length === 0) {
         console.error('No expense data or categories available for saving')
         toast.error('No expense data or categories available')
         return
      }

      // Map only ID, amount, and budgets into categories before saving
      const updatedCategories = expenseData.categories.map((category) => {
         const isDebt = category.name.toLowerCase().includes('debt')
         if (isDebt) {
            // Sync debts context if category is debt-related
            setDebts((prevDebts) =>
               prevDebts.map((debt, idx) =>
                  idx === selectedMonth
                     ? [
                          ...debt.filter((d) => d.id !== category.id),
                          { ...category },
                       ]
                     : debt
               )
            )
         }

         return {
            id: category.id,
            name: category.name, // Ensure name is included
            subcategories: category.subcategories.map((subcat) => ({
               id: subcat.id,
               name: subcat.name, // Ensure subcategory name is included
               amount: subcat.amount, // amount from the expenseData
            })),
            budget: budgets[category.id] || 0, // Include the budget for each category
         }
      })

      console.log('updatedCategories', updatedCategories)

      try {
         if (expenseData._id) {
            // If the expense already exists for this month, we update it
            await axios.put(
               `${process.env.REACT_APP_API_URL}expenseRoutes/${expenseData._id}`,
               {
                  categories: updatedCategories, // Save IDs, names, amounts, and budgets
                  month: selectedMonth + 1,
                  year: currentYear,
                  userId: user.id,
               },
               getAuthHeaders()
            )

            // Update debts context after saving
            const debtCategories = updatedCategories.filter((cat) =>
               cat.name.toLowerCase().includes('debt')
            )
            setDebts((prevDebts) => {
               const newDebts = [...prevDebts]
               newDebts[selectedMonth] = debtCategories
               return newDebts
            })

            setSubmissionMessage(
               `Expenses for ${months[selectedMonth]} updated successfully!`
            )
         } else {
            // Otherwise, we create a new expense for this month
            const response = await axios.post(
               `${process.env.REACT_APP_API_URL}expenseRoutes`,
               {
                  categories: updatedCategories, // Save IDs, names, amounts, and budgets
                  month: selectedMonth + 1,
                  year: currentYear,
                  userId: user.id,
               },
               getAuthHeaders()
            )

            // Update expenses state with the new data
            setExpenses((prev) => {
               const newExpenses = [...prev]
               newExpenses[selectedMonth] = response.data // Save new expense data
               return newExpenses
            })

            // Update debts context after saving
            const debtCategories = updatedCategories.filter((cat) =>
               cat.name.toLowerCase().includes('debt')
            )
            setDebts((prevDebts) => {
               const newDebts = [...prevDebts]
               newDebts[selectedMonth] = debtCategories
               return newDebts
            })

            setSubmissionMessage(
               `Expenses for ${months[selectedMonth]} saved successfully!`
            )
         }
         setIsEditing(true)
      } catch (error) {
         console.error('Error saving/updating expenses:', error)
         setSubmissionMessage('An error occurred while saving the expenses.')
         toast.error('Failed to save expenses. Please try again.')
      }
   }

   const handleDelete = async () => {
      const expenseData = expenses[selectedMonth]
      if (!expenseData || !expenseData._id) return

      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}expenseRoutes/${expenseData._id}`,
            getAuthHeaders()
         )
         setExpenses((prev) => {
            const newExpenses = [...prev]
            newExpenses[selectedMonth] = null
            return newExpenses
         })
         setSubmissionMessage(
            `Expenses for ${months[selectedMonth]} deleted successfully!`
         )
         setIsEditing(false)
      } catch (error) {
         console.error('Error deleting expenses:', error)
         setSubmissionMessage('An error occurred while deleting the expenses.')
         toast.error('Failed to delete expenses. Please try again.')
      }
   }

   // Handle changes to individual expense fields
   const handleChange = (e, categoryId, subcategoryId) => {
      console.log('handleChange called', {
         categoryId,
         subcategoryId,
         value: e.target.value,
      })

      setExpenses((prev) => {
         const newExpenses = [...prev]
         const currentExpense = newExpenses[selectedMonth] || { categories: [] }

         const updatedCategories = currentExpense.categories.map((category) => {
            if (category.id === categoryId) {
               return {
                  ...category,
                  subcategories: category.subcategories.map((subcat) =>
                     subcat.id === subcategoryId
                        ? { ...subcat, amount: parseFloat(e.target.value) || 0 }
                        : subcat
                  ),
               }
            }
            return category
         })

         // Ensure categories are updated in the current month's expenses
         newExpenses[selectedMonth] = {
            ...currentExpense,
            categories: updatedCategories,
         }
         console.log('Updated expenses', newExpenses)
         return newExpenses
      })
   }

   // Handle changes to the budget for a specific category or subcategory
   const handleBudgetChange = (e, categoryId) => {
      console.log('categoryId', categoryId)
      setBudgets((prevBudgets) => ({
         ...prevBudgets,
         [categoryId]: parseFloat(e.target.value) || 0,
      }))
   }

   // Handle changes for what-if scenario fields
   const handleWhatIfChange = (e, categoryId) =>
      setWhatIfChanges({
         ...whatIfChanges,
         [categoryId]: parseFloat(e.target.value) || 0,
      })

   // Category and Subcategory Management Functions

   // Add category
   const addCategory = async (newCategory) => {
      console.log('newCategory', newCategory)
      if (!user || !user.accessToken) return

      try {
         // Make the API call to add the new category
         const response = await axios.put(
            `${process.env.REACT_APP_API_URL}users/categories/user/${user.id}`,
            { category: newCategory },
            getAuthHeaders()
         )

         // Use the response data to update the state
         const addedCategory = response.data.category // Assuming the response has the category in `data.category`

         // Update the categories state using the response data
         setCategories((prevCategories) => [
            ...prevCategories,
            {
               id: addedCategory.id,
               name: addedCategory.name,
               subcategories: addedCategory.subcategories || [],
            },
         ])

         // Optionally, clear the input field or any other state management
         // setNewCategoryName('') // if you want to clear the input
      } catch (error) {
         console.error('Error adding category:', error)
         toast.error('Failed to add category. Please try again.')
      }
   }

   // Update category
   const updateCategory = async (categoryId, updatedName) => {
      if (!user || !user.accessToken) return

      try {
         const response = await axios.put(
            `${process.env.REACT_APP_API_URL}expenseRoutes/user/${user.id}/category/${categoryId}`,
            { name: updatedName },
            getAuthHeaders()
         )
         setCategories(response.data.categories)
      } catch (error) {
         console.error('Error updating category:', error)
         toast.error('Failed to update category. Please try again.')
      }
   }

   // Delete category
   const deleteCategory = async (categoryId) => {
      if (!user || !user.accessToken) return

      try {
         const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}users/categories/user/${user.id}/${categoryId}`,
            getAuthHeaders()
         )
         setCategories(response.data.categories)
      } catch (error) {
         console.error('Error deleting category:', error)
         toast.error('Failed to delete category. Please try again.')
      }
   }

   // Add subcategory
   const addSubcategory = async (categoryId, newSubcategory) => {
      if (!user || !user.accessToken) return

      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}users/categories/user/${user.id}/category/${categoryId}/subcategory`,
            { subcategory: newSubcategory }, // Passing the new subcategory in the request body
            getAuthHeaders() // Assuming this adds the authorization token
         )
         setCategories(response.data.categories) // Updating the categories in the state
      } catch (error) {
         console.error('Error adding subcategory:', error)
         toast.error('Failed to add subcategory. Please try again.')
      }
   }

   // Update subcategory
   const updateSubcategory = async (categoryId, subcategoryId, updatedName) => {
      if (!user || !user.accessToken) return

      try {
         const response = await axios.put(
            `${process.env.REACT_APP_API_URL}expenseRoutes/user/${user.id}/category/${categoryId}/subcategory/${subcategoryId}`,
            { name: updatedName },
            getAuthHeaders()
         )
         setCategories(response.data.categories)
      } catch (error) {
         console.error('Error updating subcategory:', error)
         toast.error('Failed to update subcategory. Please try again.')
      }
   }

   // Delete subcategory
   const deleteSubcategory = async (categoryId, subcategoryId) => {
      if (!user || !user.accessToken) return

      console.log('categoryId', categoryId)
      console.log('subcategoryId', subcategoryId)
      try {
         const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}users/categories/user/${user.id}/${categoryId}/subcategory/${subcategoryId}`,
            getAuthHeaders()
         )
         setCategories(response.data.categories)
      } catch (error) {
         console.error('Error deleting subcategory:', error)
         toast.error('Failed to delete subcategory. Please try again.')
      }
   }
   useEffect(() => {
      if (user.role !== 'RegularUser') return // Apenas executa se for RegularUser

      const currentYear = new Date().getFullYear()

      if (user && user.accessToken && !isLoading && selectedMonth !== null) {
         fetchMonthlyExpenses(selectedMonth + 1, currentYear)
         fetchIncomeData(selectedMonth + 1, currentYear)
      }
   }, [user, isLoading, selectedMonth, fetchMonthlyExpenses, fetchIncomeData])
   if (isLoading || loading) {
      return <div>Loading...</div>
   }

   return (
      <ExpensesContext.Provider
         value={{
            expenses,
            categories,
            actualExpenses,
            budgets,
            recurringExpenses,
            income,
            savingsSuggestions,
            whatIfChanges,
            selectedMonth,
            submissionMessage,
            isEditing,
            setSelectedMonth,
            handleSaveOrUpdate,
            handleDelete,
            handleChange,
            handleBudgetChange,
            handleWhatIfChange,
            setCategories,
            addCategory,
            updateCategory,
            deleteCategory,
            addSubcategory,
            updateSubcategory,
            deleteSubcategory,
         }}
      >
         {children}
      </ExpensesContext.Provider>
   )
}

export const useExpenses = () => useContext(ExpensesContext)

export default ExpensesProvider
