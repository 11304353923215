import React, { useContext, useEffect, useState } from 'react'
import ArticlesGrid from './ArticlesGrid'
import EditorManager from './EditorManager'
import { toast } from 'react-toastify'
import axios from 'axios'
import { UserContext } from '../../../../../context/useUserContext'
import ArticleVisibilityToggle from './ArticleVisibilityToggle'
import css from './Articles.module.css'
import { handleError } from '../../../../../utils/handleError'
import ProgressSpinner from '../../../../../utils/ProgressSpinner'
import { Button } from 'reactstrap'

function ArticleManagement() {
   const { user } = useContext(UserContext)
   const [articles, setArticles] = useState([])
   const [editingArticle, setEditingArticle] = useState(null)
   const [isVisible, setIsVisible] = useState(true)
   const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState(null)

   useEffect(() => {
      fetchArticles()
   }, [user.id])

   const fetchArticles = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}articleRoutes/${user.id}`,
            {
               headers: { Authorization: `Bearer ${user.accessToken}` },
            }
         )
         setArticles(response.data.articles)
         toast.success('Artigos carregados com sucesso')
      } catch (error) {
         handleError(error)
         toast.error('Falhou a carregar os artigos')
         setError(error)
      } finally {
         setIsLoading(false)
      }
   }
   useEffect(() => {
      async function fetchArticlesVisibility() {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`
            )

            setIsVisible(response.data.articles)
         } catch (error) {
            handleError(error)
            toast.error('Problema a alterar a visibilidade dos artigos')
         }
      }
      fetchArticlesVisibility()
   }, [user.id])
   const deleteArticle = async (articleId) => {
      if (window.confirm('Tem a certeza que quer eliminar este artigo?')) {
         try {
            await axios.delete(
               `${process.env.REACT_APP_API_URL}articleRoutes/${articleId}`,
               {
                  headers: { Authorization: `Bearer ${user.accessToken}` },
               }
            )
            // Filter out the deleted article from the articles array
            const updatedArticles = articles.filter(
               (article) => article._id !== articleId
            )
            setArticles(updatedArticles)
            toast.success('Artigo apagado com sucesso!')
         } catch (error) {
            handleError(error)
            toast.error('Falha ao apagar o artigo')
         }
      }
   }

   const handleArticleSubmitted = (newArticle) => {
      if (editingArticle) {
         setArticles((currentArticles) =>
            currentArticles.map((article) =>
               article._id === newArticle._id ? newArticle : article
            )
         )
         toast.success('Artigo atualizado com sucesso!')
      } else {
         setArticles((currentArticles) => [newArticle, ...currentArticles])
         toast.success('Artigo adicionado com sucesso!')
      }
      setEditingArticle(null)
   }

   const handleEditArticle = (article) => {
      setEditingArticle(article) // Set current article to edit
   }

   const handleResetEditingArticle = () => {
      setEditingArticle(null) // Clear editing state
   }
   if (isLoading) {
      return <ProgressSpinner />
   }

   if (error) {
      return (
         <div>
            <p>Error loading articles: {error.message}</p>
            <Button
               label="Retry"
               onClick={fetchArticles}
               className="p-button-outlined"
            />
         </div>
      )
   }
   return (
      <div className={css.tabContainer}>
         <h1>Gestão de Artigos</h1>
         <ArticleVisibilityToggle
            isVisible={isVisible}
            setIsVisible={setIsVisible}
         />
         <EditorManager
            onArticleSubmitted={handleArticleSubmitted}
            resetEditingArticle={handleResetEditingArticle}
            articles={articles}
            editingArticle={editingArticle}
            fetchArticles={fetchArticles}
         />
         <ArticlesGrid
            articles={articles}
            onEditArticle={handleEditArticle}
            fetchArticles={fetchArticles}
            setArticles={setArticles}
            onDelete={deleteArticle}
         />
      </div>
   )
}

export default ArticleManagement
