import React, { useMemo } from 'react'

function InterestEstimator({ totalAmount, remainingBalance, interestRate }) {
   // Check if all values are valid numbers

   // Interest calculation logic
   const calculateMonthlyInterest = (balance, rate) => {
      if (balance <= 0 || rate <= 0) return 0
      const monthlyRate = rate / 12 / 100
      const monthlyInterest = balance * monthlyRate
      return monthlyInterest
   }

   const calculateYearlyInterest = (balance, rate) => {
      const monthlyInterest = calculateMonthlyInterest(balance, rate)
      return monthlyInterest * 12
   }

   const totalMonthlyInterest = useMemo(
      () => calculateMonthlyInterest(totalAmount, interestRate),
      [totalAmount, interestRate]
   )
   const totalYearlyInterest = useMemo(
      () => calculateYearlyInterest(totalAmount, interestRate),
      [totalAmount, interestRate]
   )
   const remainingMonthlyInterest = useMemo(
      () => calculateMonthlyInterest(remainingBalance, interestRate),
      [remainingBalance, interestRate]
   )
   const remainingYearlyInterest = useMemo(
      () => calculateYearlyInterest(remainingBalance, interestRate),
      [remainingBalance, interestRate]
   )

   // Logging final calculated values
   // console.log('Calculated Interests:', {
   //    totalMonthlyInterest,
   //    totalYearlyInterest,
   //    remainingMonthlyInterest,
   //    remainingYearlyInterest,
   // })
   if (isNaN(totalAmount) || isNaN(remainingBalance) || isNaN(interestRate)) {
      console.error('Invalid input data:', {
         totalAmount,
         remainingBalance,
         interestRate,
      })
      return null
   }
   return (
      <div
         style={{
            marginTop: '10px',
            backgroundColor: '#2c2c2c',
            padding: '10px',
            borderRadius: '5px',
            color: 'white',
            boxShadow: '0 0 5px #ccc',
         }}
      >
         <p>Juros Mensais: {totalMonthlyInterest.toFixed(2)}€</p>
         <p>Juros Anuais: {totalYearlyInterest.toFixed(2)}€</p>
         <p>Juros Mensais Restantes: {remainingMonthlyInterest.toFixed(2)}€</p>
         <p>Juros Anuais Restantes: {remainingYearlyInterest.toFixed(2)}€</p>
      </div>
   )
}

export default InterestEstimator
