import React from 'react'
import { Row, Col } from 'reactstrap'
import { InputText } from 'primereact/inputtext'
import styles from './Expenses.module.css'

const ExpensesDetails = ({
   categories,
   expenses,
   actualExpenses,
   budgets,
   selectedMonth,
   handleChange,
   handleBudgetChange,
   handleWhatIfChange,
}) => {
   return (
      <Row className={styles.tabContainer2}>
         {categories.map((category) => {
            const subcategories = category.subcategories || []

            // Calculate the actual expenses for this category
            const actual = subcategories.reduce(
               (sum, subcategory) =>
                  sum + (actualExpenses[subcategory.id] || 0),
               0
            )

            // Get the registered budget for this category from the expenses for the selected month
            const registeredBudget =
               expenses[selectedMonth]?.categories.find(
                  (cat) => cat.id === category.id
               )?.budget ||
               budgets[category.id] ||
               0

            return (
               <Col
                  key={category.id}
                  className={styles.categoryCard}
                  style={{
                     padding: '30px',
                     border: 'white solid 1px',
                     borderRadius: '30px',
                  }}
               >
                  <div>
                     <Col
                        style={{
                           alignContent: 'center',
                           flexDirection: 'column',
                        }}
                     >
                        <h3 className={styles.categoryHeader}>
                           {category.name}
                        </h3>
                        <div>Orçamento {category.name}:</div>
                        <InputText
                           type="number"
                           value={registeredBudget} // Pre-fill with budget value
                           onChange={(e) => {
                              console.log(
                                 `Budget changed for ${category.name}:`,
                                 e.target.value
                              )
                              handleBudgetChange(e, category.id)
                           }}
                           placeholder={`Orçamento para ${category.name}`}
                           className={styles.subcategoryInput}
                        />

                        {subcategories.length > 0 ? (
                           subcategories.map((subcategory) => {
                              // Check if expense exists for the selected month and subcategory
                              const registeredExpense =
                                 expenses[selectedMonth]?.categories
                                    .find((cat) => cat.id === category.id)
                                    ?.subcategories.find(
                                       (subcat) => subcat.id === subcategory.id
                                    )?.amount || ''

                              return (
                                 <div key={subcategory.id}>
                                    <div>{subcategory.name}:</div>
                                    <InputText
                                       type="number"
                                       name={subcategory.id}
                                       value={registeredExpense} // Populate the input with registered expense if exists
                                       onChange={(e) => {
                                          console.log(
                                             `Amount typed for ${subcategory.name} under ${category.name}:`,
                                             e.target.value
                                          )
                                          handleChange(
                                             e,
                                             category.id,
                                             subcategory.id
                                          )
                                       }} // Passing categoryId and subcategoryId
                                       placeholder={`Enter ${subcategory.name}`}
                                       className={styles.subcategoryInput}
                                    />
                                 </div>
                              )
                           })
                        ) : (
                           <div>
                              No subcategories available for {category.name}
                           </div>
                        )}
                     </Col>
                  </div>
               </Col>
            )
         })}
      </Row>
   )
}

export default ExpensesDetails
