import React, { useState } from 'react'
import WebsiteModal from './WebsiteModal'
import './WebsiteCard.css'

const WebsiteCard = ({ website }) => {
   const [visible, setVisible] = useState(false)

   const toggleModal = () => {
      setVisible(!visible)
   }

   return (
      <>
         <div onClick={toggleModal} className="website-card">
            {website.logo && (
               <img
                  src={website.logo}
                  alt={website.name}
                  className="website-logo"
               />
            )}
            <h3 className="website-name">{website.name}</h3>
         </div>
         <WebsiteModal
            visible={visible}
            onModalClose={toggleModal}
            website={website}
         />
      </>
   )
}

export default WebsiteCard
