// BrokersPage.jsx
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import css from './BrokersPage.module.css'
import { Helmet } from 'react-helmet'
import BrokerCard from '../BrokerCard'
import BrokerModal from '../BrokerModal'

const BrokersPage = () => {
   const [brokers, setBrokers] = useState([])
   const [visible, setVisible] = useState(false)
   const [modalProps, setModalProps] = useState({})
   const [error, setError] = useState(null)

   useEffect(() => {
      fetchBrokers()
   }, [])

   const fetchBrokers = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}brokerRouter/`
         )
         setBrokers(response.data)
      } catch (error) {
         console.error('Failed to fetch brokers:', error)
         setError(error)
      }
   }

   const handleOpenModal = (broker) => {
      setModalProps(broker)
      setVisible(true)
   }

   if (error) {
      return <div className={css.error}>Error: {error}</div>
   }

   return (
      <div>
         <Helmet>
            <title>Corretoras</title>
            <meta
               name="description"
               content="Explore nossa lista de corretoras financeiras."
            />
         </Helmet>

         <h2 className={css.titulos}>Corretoras</h2>
         <div className={css.brokerContainer}>
            {brokers.map((broker) => (
               <BrokerCard
                  key={broker._id}
                  broker={broker}
                  onOpenModal={handleOpenModal}
               />
            ))}
         </div>

         {visible && (
            <BrokerModal
               visible={visible}
               onModalClose={() => setVisible(false)}
               broker={modalProps}
            />
         )}
      </div>
   )
}

export default BrokersPage
