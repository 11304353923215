import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import styles from './Courses.module.css'
import ProgressSpinner from '../../../../../utils/ProgressSpinner'

const RenderHeader = ({ text }) => (
   <span className={styles.headerText}>{text}</span>
)

const ImageBodyTemplate = (rowData) => (
   <img
      src={rowData?.bannerImage}
      alt={`Cover for ${rowData?.courseName}`}
      style={{ width: '50px', height: '50px' }}
   />
)

const PriceBodyTemplate = (rowData) => `€${rowData.price.toFixed(2)}`

const ActionBodyTemplate = ({ rowData, onEdit, onDelete }) => (
   <>
      <div className={styles.actionButtons}>
         <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={() => onEdit(rowData)}
         />
         <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={() => onDelete(rowData._id)}
         />
      </div>
   </>
)

const CoursesList = ({
   courses,
   onEditCourse,
   onDeleteCourse,
   isLoading,
   error,
}) => {
   if (isLoading) {
      return <ProgressSpinner />
   }

   if (error) {
      return (
         <p className={styles.errorText}>
            Failed to load courses: {error.message}
         </p>
      )
   }

   if (!courses.length) {
      return <p className={styles.noCourses}>No courses available.</p>
   }

   return (
      <div style={{ marginBottom: '10px' }}>
         {/* Tabela para telas grandes */}
         <div
            className={`${styles.dataTableWrapper} ${styles.largeScreensOnly}`}
         >
            <DataTable value={courses} className={styles.dataTableFixed}>
               <Column
                  header={<RenderHeader text="Capa" />}
                  body={ImageBodyTemplate}
               />
               <Column
                  field="courseName"
                  header={<RenderHeader text="Nome" />}
               />
               <Column
                  header={<RenderHeader text="Preço" />}
                  body={PriceBodyTemplate}
               />
               <Column
                  field="description"
                  header={<RenderHeader text="Descrição" />}
               />
               <Column
                  field="purchaseLink"
                  header={<RenderHeader text="Comprar" />}
               />
               <Column field="status" header={<RenderHeader text="Estado" />} />
               <Column
                  body={(rowData) => (
                     <ActionBodyTemplate
                        rowData={rowData}
                        onEdit={onEditCourse}
                        onDelete={onDeleteCourse}
                     />
                  )}
                  header={<RenderHeader text="Alterar" />}
               />
            </DataTable>
         </div>

         {/* Cartões para telas pequenas */}
         <div
            className={`${styles.dataTableResponsive} ${styles.smallScreensOnly}`}
         >
            {courses.map((course) => (
               <div className={styles.dataTableCard} key={course._id}>
                  <img src={course.bannerImage} alt={course.courseName} />
                  <div className={styles.cardHeader}>
                     <strong></strong>
                     {course.courseName}
                  </div>
                  <div className={styles.cardHeader}>
                     <strong>Preço:</strong> {PriceBodyTemplate(course)}
                  </div>
                  <div className={styles.cardHeader}>
                     <strong>Descrição: </strong>
                     {course.description}
                  </div>
                  <div className={styles.cardHeader}>
                     <strong>Estado:</strong> {course.status}
                  </div>
                  <div className={styles.cardActions}>
                     <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-success"
                        onClick={() => onEditCourse(course)}
                     />
                     <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning"
                        onClick={() => onDeleteCourse(course._id)}
                     />
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default CoursesList
