import React, {
   createContext,
   useState,
   useEffect,
   useContext,
   useCallback,
} from 'react'
import axios from 'axios'
import { UserContext } from './useUserContext'

const IncomeContext = createContext()

// Define the default categories
const defaultCategories = [
   { id: 'primary', name: 'Salário' },
   { id: 'secondary', name: 'Side Hustle' },
   { id: 'cashback', name: 'Cashback' },
   { id: 'referralBonuses', name: 'Bónus de referrals' },
   { id: 'interest', name: 'Juros' },
   { id: 'dividends', name: 'Dividendos' },
   { id: 'healthReimbursements', name: 'Reembolsos saúde' },
   { id: 'giftsReceived', name: 'Presentes recebidos' },
   { id: 'saleOfUsedItems', name: 'Venda artigos usados' },
   { id: 'rentalIncome', name: 'Rendas de imóveis' },
   { id: 'royalties', name: 'Royalties' },
   { id: 'otherIncome', name: 'Outros rendimentos' },
]

const months = [
   'Janeiro',
   'Fevereiro',
   'Março',
   'Abril',
   'Maio',
   'Junho',
   'Julho',
   'Agosto',
   'Setembro',
   'Outubro',
   'Novembro',
   'Dezembro',
]

// Function to parse income values correctly
const parseIncome = (value) => {
   if (value === undefined || value === null) return 0
   return parseFloat(value.toString().replace(',', '.')) || 0
}

export const IncomeProvider = ({ children }) => {
   const [incomes, setIncomes] = useState(Array(12).fill(null))
   const [categories, setCategories] = useState(defaultCategories)
   const [notes, setNotes] = useState(Array(12).fill(''))
   const [submissionMessage, setSubmissionMessage] = useState('')
   const [errorMessage, setErrorMessage] = useState('')
   const [isEditing, setIsEditing] = useState(false)
   const { user, isLoading } = useContext(UserContext)
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState(null)

   // Helper to get auth headers
   const getAuthHeaders = useCallback(() => {
      return {
         headers: {
            Authorization: `Bearer ${user?.accessToken}`,
         },
      }
   }, [user])

   // Fetch yearly income data
   const fetchYearlyData = useCallback(async () => {
      if (!user || !user.accessToken) return

      const currentYear = new Date().getFullYear()
      const updatedIncomes = Array(12).fill(null)
      setLoading(true)

      try {
         await Promise.all(
            updatedIncomes.map(async (_, i) => {
               try {
                  const response = await axios.get(
                     `${process.env.REACT_APP_API_URL}/incomeRoutes/user/${
                        user.id
                     }/month/${i + 1}/year/${currentYear}`,
                     getAuthHeaders()
                  )
                  updatedIncomes[i] = response.data || {}
               } catch (error) {
                  if (error.response && error.response.status === 404) {
                     console.warn(
                        `No income data found for month ${i + 1}, skipping.`
                     )
                  } else {
                     throw error
                  }
               }
            })
         )
         setIncomes(updatedIncomes)
      } catch (error) {
         console.error('Error fetching yearly income data:', error)
         setError('Failed to fetch income data')
      } finally {
         setLoading(false)
      }
   }, [user, getAuthHeaders])

   // Fetch income categories
   const fetchCategories = useCallback(async () => {
      if (!user || !user.accessToken) return

      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/income-categories`,
            getAuthHeaders()
         )
         if (response.data && response.data.length > 0) {
            setCategories(response.data)
         }
      } catch (error) {
         console.error('Failed to fetch income categories:', error)
         setError('Failed to fetch income categories')
      }
   }, [user, getAuthHeaders])

   // Trigger data fetching only after the user has logged in
   useEffect(() => {
      if (user && user.accessToken && !isLoading) {
         fetchYearlyData()
         fetchCategories()
      }
   }, [user, isLoading, fetchYearlyData, fetchCategories])

   // Handle saving income data
   const handleSave = async (selectedMonth) => {
      if (!user) return // Ensure user exists before performing save
      const incomeData = incomes[selectedMonth] || {}
      const currentYear = new Date().getFullYear()

      const totalIncome = Object.keys(incomeData).reduce((total, key) => {
         const value = parseIncome(incomeData[key])
         return total + (isNaN(value) ? 0 : value)
      }, 0)

      try {
         const existingIncome = incomes[selectedMonth]
         if (existingIncome && existingIncome._id) {
            await axios.put(
               `${process.env.REACT_APP_API_URL}/incomeRoutes/${existingIncome._id}`,
               {
                  ...incomeData,
                  totalIncome,
                  month: selectedMonth + 1,
                  year: currentYear,
                  userId: user.id,
               },
               getAuthHeaders()
            )
         } else {
            const response = await axios.post(
               `${process.env.REACT_APP_API_URL}/incomeRoutes/`,
               {
                  ...incomeData,
                  totalIncome,
                  month: selectedMonth + 1,
                  year: currentYear,
                  userId: user.id,
               },
               getAuthHeaders()
            )
            setIncomes((prev) => {
               const newIncomes = [...prev]
               newIncomes[selectedMonth] = response.data
               return newIncomes
            })
         }
         setSubmissionMessage(
            `Income for ${months[selectedMonth]} saved successfully!`
         )
      } catch (error) {
         console.error('Error saving income:', error)
         setSubmissionMessage('An error occurred while saving the income.')
      }
   }

   const handleDelete = async (selectedMonth) => {
      const incomeData = incomes[selectedMonth]
      if (!incomeData || !incomeData._id) return

      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}/incomeRoutes/${incomeData._id}`,
            getAuthHeaders()
         )
         setIncomes((prev) => {
            const newIncomes = [...prev]
            newIncomes[selectedMonth] = null
            return newIncomes
         })
         setSubmissionMessage(
            `Income for ${months[selectedMonth]} deleted successfully!`
         )
         setCategories(defaultCategories)
      } catch (error) {
         console.error('Error deleting income:', error)
         setSubmissionMessage('An error occurred while deleting the income.')
      }
   }

   const handleCategoryChange = async (updatedCategories) => {
      setCategories(updatedCategories)

      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/income-categories`,
            { categories: updatedCategories },
            getAuthHeaders()
         )
      } catch (error) {
         console.error('Failed to update categories:', error)
         setError('Failed to update income categories')
      }
   }

   const handleChange = (e, selectedMonth) => {
      const { name, value } = e.target
      setIncomes((prevIncomes) => {
         const updatedIncomes = [...prevIncomes]
         if (!updatedIncomes[selectedMonth]) {
            updatedIncomes[selectedMonth] = {}
         }
         updatedIncomes[selectedMonth][name] = value
         return updatedIncomes
      })
   }

   if (isLoading) {
      return <div>Loading...</div>
   }

   return (
      <IncomeContext.Provider
         value={{
            incomes,
            categories,
            notes,
            submissionMessage,
            errorMessage,
            isEditing,
            handleSave,
            handleDelete,
            handleCategoryChange,
            handleChange,
            setNotes,
            setErrorMessage,
            setSubmissionMessage,
            setIsEditing,
            setIncomes,
            loading,
            error,
         }}
      >
         {children}
      </IncomeContext.Provider>
   )
}

export const useIncome = () => useContext(IncomeContext)
