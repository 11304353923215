import React from 'react'
import { NavLink } from 'react-router-dom'

const DebtNavigation = () => {
   const navItems = [
      {
         path: '/dashboard/regular/financialHealth/budget/debts',
         label: 'Listar Dívidas',
      },
      {
         path: '/dashboard/regular/financialHealth/budget/debts/categories',
         label: 'Gerir Categorias de Dívidas',
      },
      {
         path: '/dashboard/regular/financialHealth/budget/debts/statistics',
         label: 'Estatísticas das Dívidas',
      },
      {
         path: '/dashboard/regular/financialHealth/budget/debts/aniquiladordedividas',
         label: 'Aniquilador de Dívidas',
      },
   ]

   return (
      <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
         {navItems.map((item, index) => (
            <NavLink
               key={index}
               to={item.path}
               style={({ isActive }) => ({
                  padding: '10px 15px',
                  borderRadius: '5px',
                  textDecoration: 'none',
                  color: isActive ? 'black' : 'white',
                  backgroundColor: isActive ? 'white' : 'transparent',
                  borderBottom: isActive ? '2px solid white' : 'none',
                  marginRight: '10px',
               })}
            >
               {item.label}
            </NavLink>
         ))}
      </div>
   )
}

export default DebtNavigation
