import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useExpenses } from '../../../../../../../../../context/ExpensesContext'
import styles from './ExpensesManager.module.css' // Importing the CSS module for styling
import defaultExpenseCategories from '../../../../../../../../../utils/defaultExpenseCategories'
import { Col } from 'react-bootstrap'
import ExpensesNav from './ExpensesNav'

const ExpensesCategoryManager = () => {
   const {
      categories = [],
      addCategory,
      deleteCategory,
      addSubcategory,
      deleteSubcategory,
      setCategories,
   } = useExpenses()
   const [newCategoryName, setNewCategoryName] = useState('')
   const [loading, setLoading] = useState(false)

   // Object to store new subcategory input for each category
   const [newSubcategoryNames, setNewSubcategoryNames] = useState({})

   const handleAddCategory = async () => {
      if (newCategoryName.trim()) {
         setLoading(true)

         // Find if the new category exists in the default categories
         const defaultCategory = defaultExpenseCategories.find(
            (cat) => cat.name.toLowerCase() === newCategoryName.toLowerCase()
         )

         // If the category exists, copy its subcategories, otherwise leave empty
         const newCategory = {
            id: newCategoryName.toLowerCase().replace(/\s+/g, ''),
            name: newCategoryName,
            subcategories: defaultCategory
               ? [...defaultCategory.subcategories]
               : [], // Use default subcategories if they exist
         }

         try {
            await addCategory(newCategory)

            // Append the newly added category to the current categories state
            setCategories((prevCategories) => [...prevCategories, newCategory])

            // Reset input
            setNewCategoryName('')
         } catch (error) {
            console.error('Error adding category:', error)
         } finally {
            setLoading(false)
         }
      }
   }

   const handleDeleteCategory = async (categoryId) => {
      setLoading(true)
      try {
         console.log(`Deleting category with id: ${categoryId}`)

         await deleteCategory(categoryId)

         // Update categories state after deletion
         setCategories((prevCategories) =>
            Array.isArray(prevCategories)
               ? prevCategories.filter((category) => category.id !== categoryId)
               : []
         )

         console.log('Category successfully deleted:', categoryId)
      } catch (error) {
         console.error('Error deleting category:', error)
      } finally {
         setLoading(false)
      }
   }

   // Adding a new subcategory
   const handleAddSubcategory = async (categoryId) => {
      const newSubcategoryName = newSubcategoryNames[categoryId]?.trim()

      if (newSubcategoryName && !loading) {
         setLoading(true) // Prevent multiple clicks
         const newSubcategory = {
            id: newSubcategoryName.toLowerCase().replace(/\s+/g, ''),
            name: newSubcategoryName,
         }

         try {
            // Ensure the subcategory does not already exist before adding
            const category = categories.find((cat) => cat.id === categoryId)
            const existingSubcategory = category.subcategories.find(
               (subcat) => subcat.id === newSubcategory.id
            )

            if (existingSubcategory) {
               console.warn('Subcategory already exists')
               setLoading(false)
               return
            }

            await addSubcategory(categoryId, newSubcategory)

            // Efficiently append new subcategory to existing category
            setCategories((prevCategories) =>
               prevCategories.map((category) =>
                  category.id === categoryId
                     ? {
                          ...category,
                          subcategories: [
                             ...category.subcategories,
                             newSubcategory,
                          ],
                       }
                     : category
               )
            )

            // Reset input for the specific category
            setNewSubcategoryNames((prev) => ({
               ...prev,
               [categoryId]: '',
            }))
         } catch (error) {
            console.error('Error adding subcategory:', error)
         } finally {
            setLoading(false) // Allow adding again after the process finishes
         }
      }
   }

   // Deleting a subcategory
   const handleDeleteSubcategory = async (categoryId, subcategoryId) => {
      setLoading(true)
      try {
         await deleteSubcategory(categoryId, subcategoryId)

         // Update categories state after deletion of subcategory
         setCategories((prevCategories) =>
            prevCategories.map((category) =>
               category.id === categoryId
                  ? {
                       ...category,
                       subcategories: category.subcategories.filter(
                          (subcat) => subcat.id !== subcategoryId
                       ),
                    }
                  : category
            )
         )

         console.log('Subcategory successfully deleted:', subcategoryId)
      } catch (error) {
         console.error('Error deleting subcategory:', error)
      } finally {
         setLoading(false)
      }
   }

   // Update the new subcategory input for each category
   const handleSubcategoryInputChange = (e, categoryId) => {
      setNewSubcategoryNames((prev) => ({
         ...prev,
         [categoryId]: e.target.value,
      }))
   }

   return (
      <div className={styles.container}>
         <ExpensesNav />
         <h2>Gerir Categorias de Despesas</h2>
         <Col xs={3}>
            <h3>Adicionar nova categoria</h3>
            <InputText
               value={newCategoryName}
               onChange={(e) => setNewCategoryName(e.target.value)}
               placeholder="Nova Categoria"
               className={styles.inputField}
            />
            <Button
               onClick={handleAddCategory}
               label={loading ? 'A guardar...' : 'Adicionar Categoria'}
               className={styles.addButton}
               disabled={loading}
            />
         </Col>

         <div className={styles.categoryList}>
            {categories.map((category, categoryIndex) => (
               <div
                  className={styles.categoryCard}
                  key={`${category.id}-${categoryIndex}`}
               >
                  <div className={styles.categoryHeader}>
                     <span>{category.name}</span>
                     <Button
                        label="Apagar Categoria"
                        onClick={() => handleDeleteCategory(category.id)}
                        className={styles.deleteButton}
                        disabled={loading}
                     />
                  </div>

                  {/* Subcategory Section */}
                  <div className={styles.subcategoryList}>
                     <h4>Subcategorias</h4>
                     {category.subcategories &&
                     category.subcategories.length > 0 ? (
                        category.subcategories.map(
                           (subcategory, subcategoryIndex) => (
                              <div
                                 className={styles.subcategoryItem}
                                 key={`${subcategory.id}-${subcategoryIndex}`}
                              >
                                 <span>{subcategory.name}</span>
                                 <Button
                                    label="Apagar Subcategoria"
                                    onClick={() =>
                                       handleDeleteSubcategory(
                                          category.id,
                                          subcategory.id
                                       )
                                    }
                                    className={styles.deleteButton}
                                    disabled={loading}
                                 />
                              </div>
                           )
                        )
                     ) : (
                        <p>No subcategories yet.</p>
                     )}
                  </div>

                  {/* Add new subcategory */}
                  <div className={styles.addSubcategoryContainer}>
                     <InputText
                        value={newSubcategoryNames[category.id] || ''} // Use category-specific subcategory input
                        onChange={(e) =>
                           handleSubcategoryInputChange(e, category.id)
                        }
                        placeholder="Nova Subcategoria"
                        className={styles.inputField}
                     />
                     <Button
                        onClick={() => handleAddSubcategory(category.id)}
                        label={
                           loading ? 'A guardar...' : 'Adicionar Subcategoria'
                        }
                        className={styles.addButton}
                        disabled={loading}
                     />
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default ExpensesCategoryManager
