import React, { createContext, useState } from 'react'

// Context
export const EditorManagerContext = createContext(null)

// Provider Component
export const EditorManagerProvider = ({ children }) => {
    const [isModalOpen, setModalIsOpen] = useState(false)
    const [initialContent, setInitialContent] = useState('')
    const [editingArticle, setEditingArticle] = useState(null)

    const value = {
        isModalOpen,
        initialContent,
        editingArticle,
        setModalIsOpen,
        setInitialContent,
        setEditingArticle,
    }

    return (
        <EditorManagerContext.Provider value={value}>
            {children}
        </EditorManagerContext.Provider>
    )
}
