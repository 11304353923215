import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import UserProvider from './context/useUserContext'
import { ToastContainer } from 'react-toastify'
import { EditorManagerProvider } from './context/EditorManagerContext'
import ErrorBoundary from './utils/ErrorBoundary'
import { EventManagerProvider } from './context/EventManagerContext'
import { PlaylistProvider } from './context/PlaylistContext'
import { ActiveTabProvider } from './context/ActiveTabContext'
import { IncomeProvider } from './context/IncomeContext'
import ExpensesProvider from './context/ExpensesContext'
import { InsuranceProvider } from './context/InsuranceContext'
import { SavingsProvider } from './context/SavingsContext'
import { DebtsProvider } from './context/DebtsContext' // Use DebtsProvider instead
import 'bootstrap/dist/css/bootstrap.min.css'
import ConditionalProvider from './context/ConditionalProvider'
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
   <BrowserRouter>
      <UserProvider>
         <EventManagerProvider>
            <EditorManagerProvider>
               <ActiveTabProvider>
                  <PlaylistProvider>
                     <ConditionalProvider>
                        <ErrorBoundary>
                           <App />
                           <ToastContainer
                              position="top-right" // Display the toast in the top-right corner
                              autoClose={3000} // Automatically close after 5 seconds (5000 ms)
                              hideProgressBar={false} // Show the progress bar; set to true to hide
                              newestOnTop={true} // Ensure the latest toast appears on top
                              closeOnClick // Close the toast on click
                              pauseOnHover // Pause the timer on hover
                              draggable // Allow dragging
                              pauseOnFocusLoss // Pause the timer if the window loses focus
                              theme="dark" // Set the theme to dark
                              style={{
                                 zIndex: 99999, // Ensure it's on top of other content
                                 position: 'fixed', // Fixed positioning for consistent display
                                 top: '20px', // Slight margin from the top
                                 right: '20px', // Slight margin from the right
                                 maxWidth: '300px', // Optional: limit width for better appearance
                              }}
                           />
                        </ErrorBoundary>
                     </ConditionalProvider>
                  </PlaylistProvider>
               </ActiveTabProvider>
            </EditorManagerProvider>
         </EventManagerProvider>
      </UserProvider>
   </BrowserRouter>
)
