import React, { useState, useEffect, useMemo } from 'react'
import DebtPrioritySelector from './DebtPrioritySelector'
import DebtRankingList from './DebtRankingList'
import { Row } from 'reactstrap'
import DisposableIncomeInput from '../incomeFormComponents/DisposableIncomeInput'
import {
   rankDebts,
   simulateAmortization,
   simulateMinimumPaymentAmortization,
} from './debtSimulations/DebtUtils'
import './DebtRanking.css' // Import the CSS file for styling

function DebtRanking({
   debts,
   disposableIncome,
   setDisposableIncome,
   totalDebt,
   totalInterest,
}) {
   const [selectedMethod, setSelectedMethod] = useState('snowball')
   const [rankedDebts, setRankedDebts] = useState([])
   const [simulatedDebts, setSimulatedDebts] = useState([])
   const [monthsToRepay, setMonthsToRepay] = useState(0)
   const [totalInterestSaved, setTotalInterestSaved] = useState(0)
   const [monthlyBreakdown, setMonthlyBreakdown] = useState([])
   const [interestSavedSnowball, setInterestSavedSnowball] = useState(0)
   const [interestSavedAvalanche, setInterestSavedAvalanche] = useState(0)
   const [totalInterestPaid, setTotalInterestPaid] = useState(0)

   // Process the debts data
   const currentMonthDebts = useMemo(() => {
      return debts.map((debt) => ({
         ...debt,
         balance: debt.balance || debt.remainingBalance || debt.amount || 0,
         originalBalance: debt.originalBalance || debt.amount || 0,
         interestRate: debt.interestRate || 0,
      }))
   }, [debts])

   // Suggest repayment method based on high-interest debts
   const suggestRepaymentMethod = (debts) => {
      const hasHighInterestDebt = debts.some((debt) => debt.interestRate > 15)
      setSelectedMethod(hasHighInterestDebt ? 'avalanche' : 'snowball')
   }

   useEffect(() => {
      suggestRepaymentMethod(currentMonthDebts)
   }, [currentMonthDebts])

   function calculateInterestBasedOnMethod(
      debts,
      method,
      disposableIncome = 0
   ) {
      const debtsClone = JSON.parse(JSON.stringify(debts))

      const snowballSimulation =
         disposableIncome > 0
            ? simulateAmortization(
                 rankDebts(debtsClone, 'snowball'),
                 disposableIncome
              )
            : simulateMinimumPaymentAmortization(
                 rankDebts(debtsClone, 'snowball')
              )

      const avalancheSimulation =
         disposableIncome > 0
            ? simulateAmortization(
                 rankDebts(debtsClone, 'avalanche'),
                 disposableIncome
              )
            : simulateMinimumPaymentAmortization(
                 rankDebts(debtsClone, 'avalanche')
              )

      const totalInterestSnowball = snowballSimulation.totalInterestPaid
      const totalInterestAvalanche = avalancheSimulation.totalInterestPaid

      setInterestSavedSnowball(totalInterestAvalanche - totalInterestSnowball)
      setInterestSavedAvalanche(totalInterestSnowball - totalInterestAvalanche)

      if (method === 'avalanche') {
         return totalInterestSnowball - totalInterestAvalanche
      } else {
         return totalInterestAvalanche - totalInterestSnowball
      }
   }

   useEffect(() => {
      const ranked = rankDebts(currentMonthDebts, selectedMethod)
      setRankedDebts([...ranked])

      const simulationResult =
         disposableIncome && disposableIncome > 0
            ? simulateAmortization(ranked, disposableIncome)
            : simulateMinimumPaymentAmortization(ranked)

      const {
         paymentDetailsPerDebt,
         months,
         monthlyBreakdown,
         totalInterestPaid,
      } = simulationResult

      setSimulatedDebts(paymentDetailsPerDebt)
      setMonthsToRepay(months)

      setTotalInterestPaid(totalInterestPaid)

      const adjustedInterestSaved = calculateInterestBasedOnMethod(
         paymentDetailsPerDebt,
         selectedMethod,
         disposableIncome
      )

      setTotalInterestSaved(adjustedInterestSaved)
      setMonthlyBreakdown(monthlyBreakdown)
   }, [currentMonthDebts, selectedMethod, disposableIncome])

   return (
      <Row>
         <div style={{ display: 'flex', alignItems: 'center' }}>
            <DisposableIncomeInput
               onSetIncome={setDisposableIncome}
               size="small"
            />
            <DebtPrioritySelector
               selectedMethod={selectedMethod}
               onSelectMethod={setSelectedMethod}
            />
         </div>

         {/* Styled Total Debt and Interest Section */}
         <div className="totals-container">
            <div className="total-item">
               <h4>Total de Dívidas</h4>
               <div className="total-amount">{totalDebt.toFixed(2)}€</div>
            </div>
            <div className="total-item">
               <h4>Total de Juros Estimados</h4>
               <div className="total-amount">{totalInterest.toFixed(2)}€</div>
            </div>
         </div>

         <DebtRankingList
            rankedDebts={simulatedDebts}
            method={selectedMethod}
            months={monthsToRepay}
            interestSaved={totalInterestSaved}
            monthlyBreakdown={monthlyBreakdown}
            interestSavedSnowball={interestSavedSnowball}
            interestSavedAvalanche={interestSavedAvalanche}
         />
      </Row>
   )
}

export default DebtRanking
