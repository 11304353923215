import React, { useState } from 'react'
import IncomeForm from './FinancialHealthFormComponents/IncomeForm'
import ExpensesForm from './FinancialHealthFormComponents/ExpensesForm'
import DebtsForm from './FinancialHealthFormComponents/DebtsForm'
import SavingsForm from './FinancialHealthFormComponents/SavingsForm'
import InsurancesForm from './FinancialHealthFormComponents/InsurancesForm'
import GoalsForm from './GoalsForm'
import css from './FinancialHealthForm.module.css'

function FinancialHealthForm() {
   const [step, setStep] = useState(1)
   const [formData, setFormData] = useState({
      income: {},
      expenses: {},
      debts: {},
      savings: {},
      insurances: {},
      goals: {},
   })

   const handleNextStep = () => {
      setStep(step + 1)
   }

   const handlePrevStep = () => {
      setStep(step - 1)
   }

   const handleDataChange = (section, data) => {
      setFormData({
         ...formData,
         [section]: data,
      })
   }

   const handleSubmit = () => {
      console.log('Submitted Data: ', formData)
      // Here, you would typically send the data to the backend
   }

   return (
      <div className={css.formContainer}>
         {/* <FinancialHealthNav /> Add the navigation component */}
         <div className={`${css.formSection} ${step >= 1 ? css.active : ''}`}>
            <IncomeForm
               data={formData.income}
               onChange={(data) => handleDataChange('income', data)}
               onNext={handleNextStep}
            />
         </div>
         <div
            className={`${css.formSection} ${step >= 2 ? css.completed : ''} ${
               step === 2 ? css.active : ''
            }`}
         >
            <ExpensesForm
               data={formData.expenses}
               onChange={(data) => handleDataChange('expenses', data)}
               onNext={handleNextStep}
               onPrev={handlePrevStep}
            />
         </div>
         <div
            className={`${css.formSection} ${step >= 3 ? css.completed : ''} ${
               step === 3 ? css.active : ''
            }`}
         >
            <DebtsForm
               data={formData.debts}
               onChange={(data) => handleDataChange('debts', data)}
               onNext={handleNextStep}
               onPrev={handlePrevStep}
            />
         </div>
         <div
            className={`${css.formSection} ${step >= 4 ? css.completed : ''} ${
               step === 4 ? css.active : ''
            }`}
         >
            <SavingsForm
               data={formData.savings}
               onChange={(data) => handleDataChange('savings', data)}
               onNext={handleNextStep}
               onPrev={handlePrevStep}
            />
         </div>
         <div
            className={`${css.formSection} ${step >= 5 ? css.completed : ''} ${
               step === 5 ? css.active : ''
            }`}
         >
            <InsurancesForm
               data={formData.insurances}
               onChange={(data) => handleDataChange('insurances', data)}
               onNext={handleNextStep}
               onPrev={handlePrevStep}
            />
         </div>
         <div className={`${css.formSection} ${step === 6 ? css.active : ''}`}>
            <GoalsForm
               data={formData.goals}
               onChange={(data) => handleDataChange('goals', data)}
               onPrev={handlePrevStep}
               onSubmit={handleSubmit}
            />
         </div>
      </div>
   )
}

export default FinancialHealthForm
