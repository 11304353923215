import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../context/useUserContext'
import CoursesList from './CourseList'
import CourseForm from './CourseForm'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import css from './Courses.module.css'
import { Col, Row } from 'reactstrap'
import CourseVisibilityToggle from './CourseVisibilityToggle'
import { handleError } from '../../../../../utils/handleError'
import ProgressSpinner from '../../../../../utils/ProgressSpinner'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import './DialogStyles.css'

function CourseManagement() {
   const { user, refreshAccessToken, loading } = useContext(UserContext)
   const [courses, setCourses] = useState([])
   const [editingCourse, setEditingCourse] = useState(null)
   const [isDialogShown, setIsDialogShown] = useState(false)
   const [isCoursesVisible, setIsCoursesVisible] = useState(true)
   const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState(null)

   console.log('user.accessToken', user.accessToken)

   // Função para buscar os cursos
   const fetchCourses = async () => {
      setIsLoading(true)
      setError(null)

      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}courseRoutes/user/${user.id}`,
            {
               headers: { Authorization: `Bearer ${user.accessToken}` },
            }
         )
         setCourses(response.data)
      } catch (error) {
         if (error.response?.status === 401) {
            const tokenRefreshed = await refreshAccessToken()
            if (tokenRefreshed) {
               return fetchCourses() // Retry uma única vez após renovar o token
            } else {
               toast.error('Sua sessão expirou. Faça login novamente.')
            }
         } else {
            handleError(error)
            toast.error('Erro ao carregar os cursos.')
         }
         setError(error)
      } finally {
         setIsLoading(false)
      }
   }

   useEffect(() => {
      if (!loading && user?.id && user?.accessToken) {
         fetchCourses()
      }
   }, [loading, user])

   // Função para criar um curso
   const createCourse = async (courseData) => {
      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/courseRoutes/`,
            courseData,
            {
               headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )
         setCourses((prevCourses) => [...prevCourses, response.data.course])
         toast.success('Curso adicionado com sucesso.')
      } catch (error) {
         if (error.response?.status === 401) {
            const tokenRefreshed = await refreshAccessToken()
            if (tokenRefreshed) {
               return createCourse(courseData) // Retry após o token ser renovado
            }
         }
         handleError(error)
         toast.error('Erro ao adicionar o curso.')
      }
   }

   // Função para deletar um curso
   const onDeleteCourse = async (courseId) => {
      if (!window.confirm('Tem a certeza que quer apagar este curso?')) return
      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}/courseRoutes/${courseId}`,
            {
               headers: { Authorization: `Bearer ${user.accessToken}` },
            }
         )
         setCourses((prevCourses) =>
            prevCourses.filter((course) => course._id !== courseId)
         )
         toast.success('Curso apagado com sucesso.')
      } catch (error) {
         if (error.response?.status === 401) {
            const tokenRefreshed = await refreshAccessToken()
            if (tokenRefreshed) {
               return onDeleteCourse(courseId) // Retry após o token ser renovado
            }
         }
         handleError(error)
         toast.error('Erro ao apagar o curso.')
      }
   }

   // Função para editar um curso
   const handleUpdateCourse = async (courseId, courseData) => {
      try {
         const url = `${process.env.REACT_APP_API_URL}/courseRoutes/${courseId}`
         await axios.put(url, courseData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               Authorization: `Bearer ${user.accessToken}`,
            },
         })
         fetchCourses()
         toast.success('Curso atualizado com sucesso.')
      } catch (error) {
         if (error.response?.status === 401) {
            const tokenRefreshed = await refreshAccessToken()
            if (tokenRefreshed) {
               return handleUpdateCourse(courseId, courseData) // Retry após o token ser renovado
            }
         }
         handleError(error)
         toast.error('Erro ao atualizar o curso.')
      }
   }

   const handleAddCourse = () => {
      setEditingCourse(null)
      setIsDialogShown(true)
   }

   const handleEditCourse = (course) => {
      setEditingCourse(course)
      setIsDialogShown(true)
   }

   const onResetEditing = () => {
      setEditingCourse(null)
      setIsDialogShown(false)
   }

   if (loading || isLoading) {
      return <ProgressSpinner />
   }

   if (error) {
      return <p>Erro ao carregar os cursos: {error.message}</p>
   }

   return (
      <section className={css.tabContainer}>
         <h2 className={css.title}>Gestão de Cursos</h2>
         <div className={css.mostrarAdicionar}>
            <div className="col-sm-12 col-md-4">
               <CourseVisibilityToggle
                  isVisible={isCoursesVisible}
                  setIsVisible={setIsCoursesVisible}
               />
            </div>
            <div className="col-sm-12 col-md-8 flexAdicionar">
               <h4 className={css.title1}>Adicionar novo curso</h4>
               <Button
                  label="Adicionar"
                  onClick={handleAddCourse}
                  className="btnAdicionar"
               />
            </div>
         </div>
         <Row style={{ marginTop: '30px' }}>
            <h3 className={css.title}>Os Meus Cursos</h3>
            <CoursesList
               courses={courses}
               onEditCourse={handleEditCourse}
               onDeleteCourse={onDeleteCourse}
               onResetEditing={onResetEditing}
               isLoading={isLoading}
               error={error}
            />
            <Dialog
               header={editingCourse ? 'Editar Curso' : 'Adicionar Curso'}
               visible={isDialogShown}
               style={{ width: '50vw' }}
               onHide={onResetEditing}
               headerClassName="dialogHeader"
               dismissableMask
            >
               <CourseForm
                  existingCourse={editingCourse}
                  onClose={onResetEditing}
                  coursesCount={courses.length}
                  updateCourses={setCourses}
                  createCourse={createCourse}
                  handleUpdateCourse={handleUpdateCourse}
               />
            </Dialog>
         </Row>
      </section>
   )
}

export default CourseManagement
