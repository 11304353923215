import React, { useContext, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../context/useUserContext'
import { handleError } from '../../../../../utils/handleError'
import { ProgressSpinner } from 'primereact/progressspinner'
import css from './AnnouncementStyles.module.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import './DialogStyles.css'

export default function AnnouncementCreator({
   fetchAnnouncements,
   setAnnouncements,
   announcements,
}) {
   const { user, refreshAccessToken } = useContext(UserContext)
   const [isLoading, setIsLoading] = useState(false)
   const [formError, setFormError] = useState('')
   const canCreateAnnouncement = announcements?.length < 3
   const userId = user.id

   const formik = useFormik({
      initialValues: {
         announcement: '',
      },
      onSubmit: async (values, { resetForm }) => {
         const newAnnouncement = {
            text: values.announcement
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;'),
            creatorId: userId,
         }

         // Atualização otimista na UI
         const optimisticUpdate = [...announcements, newAnnouncement]
         setAnnouncements(optimisticUpdate)

         setIsLoading(true)
         try {
            // Renove o token, se necessário
            const tokenRefreshed = await refreshAccessToken()
            if (!tokenRefreshed) throw new Error('Token refresh failed.')

            // Certifique-se de que o token está nos headers
            const token = localStorage.getItem('token')
            if (!token) {
               throw new Error('Token não encontrado após o refresh.')
            }

            // Faça o POST
            const response = await axios.post(
               `${process.env.REACT_APP_API_URL}announcementRoutes/`,
               newAnnouncement,
               {
                  headers: { Authorization: `Bearer ${token}` },
               }
            )
            setAnnouncements(response.data)
            toast.success('Anúncio criado com sucesso!')
            resetForm()
         } catch (error) {
            console.error('Erro ao criar o anúncio:', error)
            setAnnouncements(announcements) // Reverte a atualização otimista
            handleError(error)
         } finally {
            setIsLoading(false)
         }
      },
      validate: (values) => {
         const errors = {}
         if (values.announcement.length > 255) {
            errors.announcement = 'Anúncio deve conter no máximo 255 caracteres'
         }
         if (!values.announcement) {
            errors.announcement = 'Announcement cannot be empty.'
         }
         if (/<(“[^”]*”|'[^’]*’|[^'”>])*>/.test(values.announcement)) {
            errors.announcement = 'Tags de HTML não são permitidas.'
         }
         return errors
      },
   })

   return (
      <div className={css.creatorContainer}>
         {canCreateAnnouncement ? (
            <form onSubmit={formik.handleSubmit}>
               <InputTextarea
                  id="announcement"
                  name="announcement"
                  className={css.inputTextarea}
                  value={formik.values.announcement}
                  onChange={formik.handleChange}
                  rows={3}
                  cols={40}
                  maxLength={255}
                  placeholder="O que queres anunciar?"
                  disabled={isLoading}
               />
               {formik.errors.announcement && (
                  <div className={css.errorMessage}>
                     {formik.errors.announcement}
                  </div>
               )}
               {formError && (
                  <div className={css.errorMessage}>{formError}</div>
               )}
               <div className={css.charCounter}>
                  <span>{formik.values.announcement.length}/255</span>
               </div>
               {isLoading ? (
                  <ProgressSpinner className={css.spinner} />
               ) : (
                  <Button
                     type="submit"
                     disabled={!!formik.errors.announcement}
                     label="Anunciar"
                     className="btnEditar"
                  />
               )}
            </form>
         ) : (
            <p className={css.limitMessage}>
               Atingiu o limite de 3 anúncios por utilizador.
            </p>
         )}
      </div>
   )
}
