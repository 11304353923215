import React from 'react'
import styles from '../../IncomeForm.module.css'

const MonthTabs = ({ selectedMonth, setSelectedMonth }) => {
   const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
   ]

   return (
      <div className={styles.monthTabs}>
         {months.slice(0, new Date().getMonth() + 1).map((month, index) => (
            <div
               key={index}
               className={
                  selectedMonth === index ? styles.activeTab : styles.tab
               }
               onClick={() => setSelectedMonth(index)}
            >
               {month}
            </div>
         ))}
      </div>
   )
}

export default MonthTabs
