import React from 'react'
import { Row } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import css from '../ToolIndex.module.css'

function PersonalFinanceTools() {
   return (
      <div>
         <Row className={css.tabContainer}>
            <div className={css.tabContainer}>
               <nav className={css.tabNavigation}>
                  <NavLink
                     to="/ferramentas/personalFinanceTools/emergencyfund"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Fundo de Emergência
                  </NavLink>
                  <NavLink
                     to="/ferramentas/personalFinanceTools/juroscompostos"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Juros Compostos
                  </NavLink>
                  <NavLink
                     to="/ferramentas/personalFinanceTools/savings"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Poupança
                  </NavLink>
               </nav>
            </div>
            <div className={css.tabContent}>
               <Outlet /> {/* This will render the specific tool if selected */}
            </div>
         </Row>
      </div>
   )
}

export default PersonalFinanceTools
