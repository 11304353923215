import React from 'react'
import { Button } from 'primereact/button'

const EmergencyFundSuggestion = ({ debts, onSetGoal }) => {
   const calculateEmergencyFund = () => {
      // Suggest at least 3-6 months' worth of debt payments
      const totalMonthlyDebtPayment = debts.reduce((sum, debt) => {
         return sum + ((debt.balance || 0) * (debt.interestRate / 12)) / 100
      }, 0)

      const recommendedFund = totalMonthlyDebtPayment * 3 // 3 months' worth of debt payments
      return recommendedFund.toFixed(2)
   }

   const emergencyFund = calculateEmergencyFund()

   return (
      <div
         style={{
            marginTop: '20px',
            padding: '20px',
            backgroundColor: '#fff3cd',
            borderRadius: '10px',
            color: '#856404',
         }}
      >
         <h4>Emergency Fund for Debt Payments</h4>
         <p>
            Based on your current debt levels, we recommend setting aside at
            least <strong>€{emergencyFund}</strong> as an emergency fund
            dedicated to ensuring you can make your debt payments on time, even
            in unexpected financial situations.
         </p>
         <p>
            This amount covers approximately 3 months' worth of your total debt
            payments. Consider adjusting this amount according to your risk
            tolerance and financial stability.
         </p>
         <Button
            label="Set as Savings Goal"
            onClick={() => onSetGoal('emergencyFund', emergencyFund)}
         />
      </div>
   )
}

export default EmergencyFundSuggestion
