import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styles from './PlaylistSlider.module.css'

const PlaylistSlider = ({ playlist }) => {
   const getEmbedUrl = (videoId) => {
      return `https://www.youtube.com/embed/${videoId}?autoplay=0&mute=1`
   }

   // Verifica a largura da tela para alterar o layout
   const [isMobile, setIsMobile] = useState(window.innerWidth <= 991)

   useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 991)
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
   }, [])

   if (!Array.isArray(playlist.videos) || playlist.videos.length === 0) {
      return null
   }

   return (
      <div className={styles.playlistContainer}>
         <h3 className={styles.playlistTitle}>{playlist.name}</h3>
         <Carousel
            showStatus={false}
            showThumbs={false}
            showArrows={!isMobile} // Desativa os controles no mobile
            infiniteLoop={!isMobile} // Apenas desktop terá loop
            centerMode={!isMobile} // Desativa o modo de centro no mobile
            centerSlidePercentage={isMobile ? 100 : 33} // Ajusta a largura do slide
         >
            {playlist.videos.map((videoId, idx) => (
               <div key={idx} className={styles.carouselSlide}>
                  <iframe
                     title={`video-${idx}`}
                     src={getEmbedUrl(videoId)}
                     frameBorder="0"
                     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen
                     style={{ borderRadius: '10px !important' }}
                  />
               </div>
            ))}
         </Carousel>
      </div>
   )
}

export default PlaylistSlider
