import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import EventForm from '../../../../dashBoardAdmin/components/ContentManagementComponents/WebinarsEventsComponents/EventForm'
import { UserContext } from '../../../../../../context/useUserContext'
import './BigEventsDashBoad.css'

function BigEventsDashboard() {
   const { user } = React.useContext(UserContext)
   const [events, setEvents] = useState([])
   const [currentEvent, setCurrentEvent] = useState(null)
   const [editDialogVisible, setEditDialogVisible] = useState(false)
   const [detailsDialogVisible, setDetailsDialogVisible] = useState(false)
   const [isTableVisible, setIsTableVisible] = useState(true)
   const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState(null)
   const toast = useRef(null)

   useEffect(() => {
      const handleResize = () => {
         setIsTableVisible(window.innerWidth > 991) // Alternar para cartões em telas menores que 991px
      }
      window.addEventListener('resize', handleResize)
      handleResize() // Chamada inicial para definir o estado correto
      return () => window.removeEventListener('resize', handleResize)
   }, [])

   useEffect(() => {
      if (user && user.accessToken) {
         fetchEvents()
      } else {
         console.log('User not authenticated or accessToken missing.')
      }
   }, [user])

   const fetchEvents = async () => {
      try {
         console.log('User Access Token:', user.accessToken)
         console.log(
            'API URL:',
            `${process.env.REACT_APP_API_URL}adminEvents/createdByMe`
         )

         const response = await fetch(
            `${process.env.REACT_APP_API_URL}adminEvents/createdByMe`,
            {
               method: 'GET',
               headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )

         if (!response.ok) {
            const errorMessage = await response.text()
            console.error('API Error:', errorMessage)
            throw new Error('Failed to fetch events.')
         }

         const data = await response.json()
         console.log('Fetched Events Data:', data)
         setEvents(data)
      } catch (error) {
         console.error('Error fetching events:', error)
         setError(error.message)
      }
   }

   const openNewEventDialog = () => {
      setCurrentEvent(null)
      setEditDialogVisible(true)
      setDetailsDialogVisible(false)
   }

   const onSaveEvent = async (formData) => {
      const isEdit = currentEvent != null
      const method = isEdit ? 'PUT' : 'POST'
      const endpoint = isEdit
         ? `${process.env.REACT_APP_API_URL}adminEvents/${currentEvent._id}`
         : `${process.env.REACT_APP_API_URL}adminEvents/`

      if (isEdit && currentEvent.status === 'approved') {
         formData.append('status', 'pending')
      }

      try {
         const response = await fetch(endpoint, {
            method: method,
            headers: {
               Authorization: `Bearer ${user.accessToken}`,
            },
            body: formData,
         })

         if (!response.ok) {
            throw new Error('Failed to save event')
         }

         const updatedEvent = await response.json()
         setEvents(
            events.map((e) => (e._id === updatedEvent._id ? updatedEvent : e))
         )
         closeEditDialog(false)
         setCurrentEvent(null)
      } catch (error) {
         console.error('Error saving event:', error)
         toast.current.show({
            severity: 'error',
            summary: 'Saving failed',
            detail: error.message,
            life: 3000,
         })
      }
   }

   const onRowDetails = (rowData) => {
      setCurrentEvent(rowData)
      setDetailsDialogVisible(true)
      setEditDialogVisible(false)
   }

   const onRowEdit = (rowData) => {
      const formattedData = {
         ...rowData,
         start: new Date(rowData.startTime).toISOString(),
         end: new Date(rowData.endTime).toISOString(),
         locationType: rowData.locationType,
         address: rowData.address,
         onlineLink: rowData.onlineLink,
         registrationLink: rowData.registrationLink,
         coverImage: rowData.coverImage,
         eventCreatorName: rowData.eventCreatorName,
         eventCreator: rowData.eventCreator,
         eventCategory: rowData.eventCategory,
         expectedAttendees: rowData.expectedAttendees,
         accessibilityOptions: rowData.accessibilityOptions || [],
         contactEmail: rowData.contactEmail,
         contactPhone: rowData.contactPhone,
         termsAccepted: rowData.termsAccepted,
         // Ensure all fields expected by EventForm are correctly set here
      }

      setCurrentEvent(formattedData)
      setEditDialogVisible(true) // Ensure this dialog is visible
      setDetailsDialogVisible(false)
   }

   const onRowDelete = async (rowData) => {
      if (rowData.status === 'approved') {
         // Handle deletion request for approved events
         if (
            window.confirm(
               `Request deletion for the approved event titled "${rowData.title}"? This will need admin approval.`
            )
         ) {
            try {
               const requestOptions = {
                  method: 'PUT', // Correct method for updating status
                  headers: {
                     'Content-Type': 'application/json',
                     Authorization: `Bearer ${user.accessToken}`,
                  },
                  body: JSON.stringify({ status: 'deletion requested' }),
               }
               const response = await fetch(
                  `${process.env.REACT_APP_API_URL}adminEvents/requestDeletion/${rowData._id}`,
                  requestOptions
               )
               if (!response.ok) throw new Error('Failed to request deletion')
               toast.current.show({
                  severity: 'info',
                  summary: 'Deletion Requested',
                  detail: 'Deletion request has been sent to admin.',
                  life: 3000,
               })
               fetchEvents() // Refresh the event list to reflect the status change
            } catch (error) {
               console.error('Error requesting deletion:', error)
               toast.current.show({
                  severity: 'error',
                  summary: 'Request Failed',
                  detail: 'Failed to send deletion request.',
                  life: 3000,
               })
            }
            return // Ensure function execution stops here after handling approved event
         }
      } else if (rowData.status === 'pending') {
         // Handle direct deletion for pending events
         if (
            window.confirm(
               `Are you sure you want to delete the pending event titled "${rowData.title}"?`
            )
         ) {
            try {
               const requestOptions = {
                  method: 'DELETE',
                  headers: {
                     'Content-Type': 'application/json',
                     Authorization: `Bearer ${user.accessToken}`,
                  },
               }
               const response = await fetch(
                  `${process.env.REACT_APP_API_URL}adminEvents/${rowData._id}`,
                  requestOptions
               )
               if (!response.ok) throw new Error('Failed to delete the event')
               setEvents(events.filter((event) => event._id !== rowData._id)) // Update local state
               toast.current.show({
                  severity: 'success',
                  summary: 'Event Deleted',
                  detail: 'The pending event has been successfully deleted.',
                  life: 3000,
               })
            } catch (error) {
               console.error('Error deleting event:', error)
               toast.current.show({
                  severity: 'error',
                  summary: 'Deletion Failed',
                  detail: 'Error deleting event.',
                  life: 3000,
               })
            }
         }
      } else {
         // Inform the user that deletion is not allowed for other statuses
         toast.current.show({
            severity: 'warn',
            summary: 'Deletion Not Allowed',
            detail:
               'Deletion or requests for deletion are not allowed for this event status.',
            life: 3000,
         })
      }
   }

   const statusBodyTemplate = (rowData) => {
      let statusLabel = rowData.status
      let styleClass = ''

      switch (rowData.status) {
         case 'pending':
            statusLabel = 'Pending Approval'
            styleClass = 'p-tag p-tag-warning'
            break
         case 'approved':
            statusLabel = 'Approved'
            styleClass = 'p-tag p-tag-success'
            break
         case 'deletion requested':
            statusLabel = 'Deletion Requested'
            styleClass = 'p-tag p-tag-danger'
            break
         case 'rejected':
            statusLabel = 'Rejected'
            styleClass = 'p-tag p-tag-danger'
            break
         default:
            statusLabel = 'Unknown'
            styleClass = 'p-tag p-tag-secondary'
            break
      }

      return <span className={styleClass}>{statusLabel}</span>
   }
   const closeEditDialog = () => {
      setCurrentEvent(null)
      setEditDialogVisible(false)
   }

   const closeDetailsDialog = () => {
      setCurrentEvent(null)
      setDetailsDialogVisible(false)
   }
   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actionButtonGroup">
            <Button
               icon="pi pi-info-circle"
               onClick={() => onRowDetails(rowData)}
               tooltip="View Details"
               className="p-button-text"
            />
            <Button
               label="Edit"
               icon="pi pi-pencil"
               onClick={() => onRowEdit(rowData)}
               className="p-button-rounded p-button-success p-mr-2"
            />
            <Button
               label="Delete"
               icon="pi pi-trash"
               onClick={() => onRowDelete(rowData)}
               className="p-button-rounded p-button-warning"
            />
         </div>
      )
   }

   console.log('events', events)

   return (
      <div className="dashboardContainer">
         <Toast ref={toast} />

         <div className="dashboardHeader">
            <h2 className="dashboardTitle">Gestão de Eventos</h2>
            <Button
               label="Adicionar Evento"
               icon="pi pi-plus"
               onClick={openNewEventDialog}
               className="btnAdicionarEvento"
            />
         </div>

         {isLoading ? (
            <p className="loadingText">Carregando...</p>
         ) : isTableVisible ? (
            <div className="dataTableWrapper">
               <DataTable value={events} responsiveLayout="scroll">
                  <Column
                     field="title"
                     header="Título"
                     body={(rowData) => (
                        <div className="title">{rowData.title}</div>
                     )}
                     sortable
                  ></Column>
                  <Column
                     field="startTime"
                     header="Data"
                     body={(rowData) => (
                        <div className="col-date left-aligned">
                           {new Date(rowData.startTime).toLocaleDateString(
                              'pt-PT',
                              {
                                 day: '2-digit',
                                 month: '2-digit',
                                 year: 'numeric',
                              }
                           )}
                        </div>
                     )}
                     sortable
                  ></Column>

                  <Column
                     field="status"
                     header="Estado"
                     body={statusBodyTemplate}
                  ></Column>
                  <Column
                     header="Ações"
                     body={actionBodyTemplate}
                     className="col-actions"
                  ></Column>
               </DataTable>
            </div>
         ) : (
            <div className="cardsContainer">
               {events.map((event) => (
                  <div key={event._id} className="eventCard">
                     <img
                        src={
                           event.coverImage || 'https://via.placeholder.com/150'
                        }
                        alt={event.title}
                        className="eventImage"
                     />
                     <div className="cardContent">
                        <h3 className="eventTitle">{event.title}</h3>
                        <p className="eventDate">
                           <strong>Data:</strong>{' '}
                           {new Date(event.startTime).toLocaleDateString(
                              'pt-PT',
                              {
                                 day: '2-digit',
                                 month: '2-digit',
                                 year: 'numeric',
                              }
                           )}
                        </p>
                        <p className="eventDescription">
                           <strong>Descrição: </strong> {event.description}
                        </p>
                        <div className="actionButtons">
                           {actionBodyTemplate(event)}
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         )}

         {error && <p className="errorText">{error}</p>}

         <Dialog
            header={
               <span style={{ color: 'white' }}>
                  {currentEvent
                     ? 'Editar Detalhes do Evento'
                     : 'Criar Novo Evento'}
               </span>
            }
            visible={editDialogVisible}
            onHide={closeEditDialog}
            className="white-text-dialog"
            modal
            dismissableMask
            style={{ width: '50vw', color: 'white' }}
         >
            <EventForm
               eventData={currentEvent}
               onSave={onSaveEvent}
               onCancel={closeEditDialog}
            />
         </Dialog>
         <Dialog
            header={
               <span
                  style={{ color: 'white', textAlign: 'center', width: '100%' }}
               >
                  {currentEvent ? currentEvent.title : 'Detalhes do Evento'}
               </span>
            }
            visible={detailsDialogVisible}
            style={{ width: '50vw' }}
            className="white-text-dialog"
            onHide={closeDetailsDialog}
            modal
            dismissableMask
         >
            {currentEvent && (
               <div>
                  <h2>{currentEvent.title}</h2>
                  <img
                     src={currentEvent.coverImage}
                     alt="Event Cover"
                     style={{
                        margin: '0 auto',
                        display: 'block',
                        height: '350px',
                     }}
                  />
                  <p>
                     <strong>Descrição:</strong> {currentEvent.description}
                  </p>
                  <p>
                     <strong>Hora de Início:</strong>{' '}
                     {new Date(currentEvent.startTime).toLocaleString()}
                  </p>
                  <p>
                     <strong>Hora de Término:</strong>{' '}
                     {new Date(currentEvent.endTime).toLocaleString()}
                  </p>
                  <p>
                     <strong>Criador:</strong> {currentEvent.eventCreatorName}
                  </p>
                  <p>
                     <strong>Localização:</strong>{' '}
                     {currentEvent.locationType === 'online'
                        ? 'Online'
                        : currentEvent.address}
                  </p>
               </div>
            )}
         </Dialog>
      </div>
   )
}

export default BigEventsDashboard
