// courseFormSchema.js
import * as yup from 'yup'

export const validationSchema = yup.object({
   courseName: yup.string().required('Título do curso é obrigatório'),
   description: yup.string().required('Descrição é obrigatória'),
   price: yup
      .number()
      .min(0, 'Preço tem de ser superior a 0')
      .required('Preço é obrigatório'),
   topic: yup.string().required('Tópico é obrigatório'),
   bannerImage: yup.mixed().required('A imagem de capa é obrigatória'),
   purchaseLink: yup.string().url('Introduza um URL válido'),
   status: yup.string().oneOf(['draft', 'published'], 'Estado é inválido'),
})

export const getInitialValues = (existingCourse) => ({
   courseName: existingCourse?.courseName || '',
   description: existingCourse?.description || '',
   price: existingCourse?.price || 0,
   topic: existingCourse?.topic || '',
   bannerImage: null,
   purchaseLink: existingCourse?.purchaseLink || '',
   status: existingCourse?.status || 'draft',
})
