import React, { useState, useContext } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { UserContext } from '../../../../../../../../../context/useUserContext'
import axios from 'axios'
import SavingsNav from './SavingsNav'
import { SavingsContext } from '../../../../../../../../../context/SavingsContext'
import styles from './CategoryManager.module.css' // Import the CSS file
import { Col, Row } from 'reactstrap'

const CategoryManager = () => {
   const { user } = useContext(UserContext)
   const { categories, setCategories } = useContext(SavingsContext)
   const [newCategoryName, setNewCategoryName] = useState('')
   const [editingCategory, setEditingCategory] = useState(null)
   const [loading, setLoading] = useState(false)
   const [errorMessage, setErrorMessage] = useState('')
   const [successMessage, setSuccessMessage] = useState('')

   const addCategory = async () => {
      if (!newCategoryName.trim()) {
         setErrorMessage('Category name cannot be empty.')
         return
      }

      if (
         categories.some(
            (cat) => cat.name.toLowerCase() === newCategoryName.toLowerCase()
         )
      ) {
         setErrorMessage('Category name must be unique.')
         return
      }

      const newCategoryId = (categories.length + 1).toString()
      const newCategory = {
         id: newCategoryId,
         name: newCategoryName,
         savings: 0,
         goal: 0,
      }

      setCategories([...categories, newCategory])
      setNewCategoryName('')
      setErrorMessage('')

      await handleSaveCategories()
   }

   const renameCategory = async (id, name) => {
      if (!name.trim()) {
         setErrorMessage('Category name cannot be empty.')
         return
      }

      setLoading(true)
      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/savings-categories/rename`,
            { id, name },
            {
               headers: {
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )

         setCategories(
            categories.map((cat) => (cat.id === id ? { ...cat, name } : cat))
         )
         setErrorMessage('')
      } catch (error) {
         setErrorMessage('Error renaming category. Please try again.')
         console.error('Error renaming category:', error)
      } finally {
         setLoading(false)
      }
   }

   const deleteCategory = async (id) => {
      setLoading(true)
      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/savings-categories/${id}`,
            {
               headers: {
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )

         setCategories(categories.filter((cat) => cat.id !== id))
         setErrorMessage('')
         setSuccessMessage('Category deleted successfully!')
         setTimeout(() => setSuccessMessage(''), 3000)
      } catch (error) {
         setErrorMessage(
            'An error occurred while deleting the category. Please try again.'
         )
         console.error('Error deleting category:', error)
      } finally {
         setLoading(false)
      }
   }

   const handleSaveCategories = async () => {
      setLoading(true)
      try {
         await axios.put(
            `${process.env.REACT_APP_API_URL}users/${user.id}/savings-categories`,
            { categories },
            {
               headers: {
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )
         setSuccessMessage('Categories saved successfully!')
         setTimeout(() => setSuccessMessage(''), 3000)
      } catch (error) {
         setErrorMessage(
            'An error occurred while saving the categories. Please try again.'
         )
         console.error('Error saving categories:', error)
      } finally {
         setLoading(false)
      }
   }

   return (
      <div className={styles.container}>
         <SavingsNav />

         <h1 className="title">Gerir Categorias de Poupanças</h1>
         {loading && <p>Loading...</p>}
         {errorMessage && <p className="error-message">{errorMessage}</p>}
         {successMessage && <p className="success-message">{successMessage}</p>}

         <div className={styles.inputContainer}>
            <Col xs={3}>
               <InputText
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  placeholder="Nova Categoria"
                  className={styles.inputField}
               />
               <Button
                  onClick={addCategory}
                  label="Adicionar Categoria"
                  className={styles.addButton}
                  disabled={loading}
               />
            </Col>
         </div>

         <ul className={styles.categoryList}>
            {categories.map((category) => (
               <li className={styles.category} key={category.id}>
                  {editingCategory === category.id ? (
                     <InputText
                        value={category.name}
                        onChange={(e) =>
                           renameCategory(category.id, e.target.value)
                        }
                        onBlur={() => setEditingCategory(null)}
                        onKeyPress={(e) => {
                           if (e.key === 'Enter') setEditingCategory(null)
                        }}
                        className={styles.editInput}
                     />
                  ) : (
                     <span
                        className={styles.categoryName}
                        onClick={() => setEditingCategory(category.id)}
                     >
                        {category.name}
                     </span>
                  )}
                  <Button
                     label="Excluir"
                     onClick={() => deleteCategory(category.id)}
                     className={styles.deleteButton}
                     disabled={loading}
                  />
               </li>
            ))}
         </ul>

         <Row>
            <Col xs={3}>
               <Button
                  onClick={handleSaveCategories}
                  label="Save Categories"
                  className={styles.saveButton}
                  disabled={loading || !categories.length}
               />
            </Col>
         </Row>
      </div>
   )
}

export default CategoryManager
