import React, { useContext, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { TabPanel, TabView } from 'primereact/tabview'
import css from '../CoursesPage.module.css'
import 'primereact/resources/themes/saga-blue/theme.css' // Tema do PrimeReact
import 'primereact/resources/primereact.min.css' // Estilos básicos
import { Button } from 'primereact/button'
import { UserContext } from '../../../context/useUserContext'
import { useNavigate } from 'react-router-dom'

const EventDialog = ({ selectedEvent, dialogVisible, setDialogVisible }) => {
   const { user } = useContext(UserContext)
   const navigate = useNavigate()
   const [activeIndex, setActiveIndex] = useState(0) // For tab switching
   const [visitorDialogVisible, setVisitorDialogVisible] = useState(false)

   const closeModal = () => {
      setDialogVisible(false)
      document.body.style.overflow = 'unset'
   }

   const handleInscriptionClick = () => {
      if (!user || user.role === 'visitor') {
         // Exibe o diálogo para visitantes
         setVisitorDialogVisible(true)
      } else {
         // Redireciona para o link do evento
         window.open(selectedEvent.link, '_blank')
      }
   }

   // Helper function to remove HTML tags
   const stripHTML = (html) => {
      if (!html) return ''
      return html.replace(/<[^>]*>?/gm, '')
   }

   const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(dateString).toLocaleDateString(undefined, options)
   }

   const handleTabChange = (e) => {
      setActiveIndex(e.index)
   }

   return (
      <div className="eventModal">
         <Dialog
            visible={dialogVisible}
            onHide={closeModal}
            header={
               <div className={css.customHeader}>{selectedEvent?.name}</div>
            }
            modal
            dismissableMask
            className="customDialog"
            style={{ width: '70vw' }}
         >
            <TabView
               activeIndex={activeIndex}
               onTabChange={handleTabChange}
               className="tabView"
            >
               {/* Tab for Event Details */}
               <TabPanel header="Detalhes" className="tabPanel">
                  <div className="courseDetails">
                     <div>
                        <img
                           src={
                              selectedEvent.bannerImage ||
                              '/placeholder-image.png'
                           }
                           alt={selectedEvent.name}
                           className="courseImage"
                        />
                     </div>
                     <div className="eventDetailsCentrar">
                        <div>
                           <p>
                              <b className="paragrafos">Descrição: </b>
                              {stripHTML(selectedEvent.description)}
                           </p>
                           <p>
                              <b className="paragrafos">Data: </b>
                              {formatDate(selectedEvent.date)}
                           </p>
                           <p>
                              <b className="paragrafos">Criador: </b>{' '}
                              {selectedEvent.creator?.username}
                           </p>
                           <p>
                              <b className="paragrafos">Inscrever: </b>
                              <a
                                 onClick={handleInscriptionClick}
                                 style={{ color: 'white', cursor: 'pointer' }}
                              >
                                 Clicar aqui
                              </a>
                           </p>
                        </div>
                     </div>
                  </div>
               </TabPanel>

               {/* Add additional tabs if needed */}
            </TabView>
         </Dialog>

         {/* Dialog for visitors */}
         <Dialog
            visible={visitorDialogVisible}
            onHide={() => setVisitorDialogVisible(false)}
            header="Acesso Restrito"
            dismissableMask
            style={{ width: '50vw' }}
         >
            <div style={{ padding: '20px' }}>
               <p style={{ color: 'white' }}>
                  Para acessar esta funcionalidade, é necessário fazer login.
               </p>
               <div
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     gap: '10px',
                  }}
               >
                  <Button
                     onClick={() => navigate('/login')}
                     className={css.following}
                     label="Login"
                  />
                  <Button
                     onClick={() => setVisitorDialogVisible(false)}
                     className={css.gradientButton}
                     label="Cancelar"
                  />
               </div>
            </div>
         </Dialog>
      </div>
   )
}

export default EventDialog
