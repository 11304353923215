import { useState, useEffect, useContext, useCallback } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../context/useUserContext'

const useWelcomeVideo = (videoType) => {
   const { user } = useContext(UserContext)
   const userId = user.id

   const [videoLink, setVideoLink] = useState('')
   const [currentVideo, setCurrentVideo] = useState(null)
   const [welcomeVideoVisible, setWelcomeVideoVisible] = useState(true)
   const [userVideos, setUserVideos] = useState([])

   // Fetch video visibility
   const fetchVideoVisibility = useCallback(async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/${userId}/visibility`
         )
         if (response.status === 200) {
            setWelcomeVideoVisible(response.data.welcomeVideo)
         }
      } catch (error) {
         console.error('Failed to fetch video visibility:', error)
         toast.error('Failed to load video visibility settings.')
      }
   }, [userId])

   // Fetch welcome video
   const fetchWelcomeVideo = useCallback(async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}welcomeVideos/${userId}/mainVideo`
         )
         if (response.status === 200 && response.data) {
            setCurrentVideo(response.data)
         }
      } catch (error) {
         toast.error('Falha ao carregar o vídeo de boas-vindas.')
      }
   }, [userId])

   // Fetch user videos
   const fetchUserVideos = useCallback(async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}welcomeVideos/${userId}/userVideos`
         )
         if (response.data) {
            const filteredVideos = response.data.filter(
               (video) => video.videoType === videoType
            )
            setUserVideos(filteredVideos)

            const selectedVideo = filteredVideos.find(
               (video) => video.isSelected
            )
            if (selectedVideo) {
               setCurrentVideo(selectedVideo)
               setVideoLink(selectedVideo.videoLink)
            } else if (filteredVideos.length > 0) {
               setCurrentVideo(filteredVideos[0])
               setVideoLink(filteredVideos[0].videoLink)
            } else {
               setCurrentVideo(null)
               setVideoLink('')
            }
         }
      } catch (error) {
         console.error('Failed to load videos:', error)
         toast.error('Failed to load videos.')
      }
   }, [userId, videoType])

   // Toggle video visibility
   const toggleVideoVisibility = useCallback(async () => {
      try {
         const newVisibility = !welcomeVideoVisible
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${userId}/visibility`,
            {
               contentVisibility: { welcomeVideo: newVisibility },
            }
         )
         setWelcomeVideoVisible(newVisibility)
         toast.success(
            'Visibilidade do vídeo de boas-vindas atualizada com sucesso!'
         )
      } catch (error) {
         toast.error('Falha ao alterar a visibilidade do vídeo de boas-vindas.')
      }
   }, [welcomeVideoVisible, userId])

   useEffect(() => {
      fetchWelcomeVideo()
      fetchVideoVisibility()
      fetchUserVideos()
   }, [fetchWelcomeVideo, fetchVideoVisibility, fetchUserVideos])

   return {
      videoLink,
      setVideoLink,
      currentVideo,
      setCurrentVideo,
      welcomeVideoVisible,
      toggleVideoVisibility,
      userVideos,
      setUserVideos,
      fetchUserVideos,
      userId,
   }
}

export default useWelcomeVideo
