import React, { useState } from 'react'
import InvestmentPieChart from './InvestmentPieChart'
import InvestmentBarChart from './InvestmentBarChart'
import {
   Chart as ChartJS,
   ArcElement,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js'
import { Col, Row, Container } from 'react-bootstrap'

ChartJS.register(
   ArcElement,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend
)

const FireLength = () => {
   const [target, setTarget] = useState(1000000)
   const [startingAmount, setStartingAmount] = useState(20000)
   const [returnRate, setReturnRate] = useState(6)
   const [compoundFrequency, setCompoundFrequency] = useState('monthly') // Set to monthly by default
   const [additionalContribution, setAdditionalContribution] = useState(1000)
   const [contributionTiming, setContributionTiming] = useState('beginning')
   const [results, setResults] = useState(null)

   const calculateInvestment = () => {
      const annualRate = returnRate / 100
      const compoundPeriods = compoundFrequency === 'annually' ? 1 : 12
      const periodRate = annualRate / compoundPeriods
      let months = 0
      let currentBalance = startingAmount
      let totalContributions = 0

      const accumulationSchedule = []

      while (currentBalance < target) {
         if (contributionTiming === 'beginning') {
            currentBalance += additionalContribution
            totalContributions += additionalContribution
         }

         const interest = currentBalance * periodRate
         currentBalance += interest

         if (contributionTiming === 'end') {
            currentBalance += additionalContribution
            totalContributions += additionalContribution
         }

         months += 1

         // Every 12 months (1 year), add to the accumulation schedule
         if (months % 12 === 0) {
            accumulationSchedule.push({
               year: months / 12,
               contributions: totalContributions,
               interest: currentBalance - startingAmount - totalContributions,
               balance: currentBalance,
            })
         }
      }

      setResults({
         monthsToTarget: months.toFixed(2),
         yearsToTarget: (months / 12).toFixed(2),
         endBalance: target.toLocaleString(),
         startingAmount: startingAmount.toLocaleString(),
         totalContributions: totalContributions.toLocaleString(),
         totalInterest: (
            currentBalance -
            startingAmount -
            totalContributions
         ).toLocaleString(),
         accumulationSchedule:
            accumulationSchedule.length > 0 ? accumulationSchedule : [],
      })
   }

   return (
      <Container>
         <h2>Investment Calculator</h2>
         <Row
            style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-around',
            }}
         >
            {/* Align the calculator and the bar chart side by side */}
            <Col md={6}>
               <div>
                  <label>Your Target</label>
                  <input
                     type="number"
                     value={target}
                     onChange={(e) => setTarget(Number(e.target.value))}
                  />
               </div>
               <div>
                  <label>Starting Amount</label>
                  <input
                     type="number"
                     value={startingAmount}
                     onChange={(e) => setStartingAmount(Number(e.target.value))}
                  />
               </div>
               <div>
                  <label>Return Rate (%)</label>
                  <input
                     type="number"
                     value={returnRate}
                     onChange={(e) => setReturnRate(Number(e.target.value))}
                  />
               </div>
               <div>
                  <label>Compound</label>
                  <select
                     value={compoundFrequency}
                     onChange={(e) => setCompoundFrequency(e.target.value)}
                  >
                     <option value="annually">Annually</option>
                     <option value="monthly">Monthly</option>
                  </select>
               </div>
               <div>
                  <label>Additional Contribution</label>
                  <input
                     type="number"
                     value={additionalContribution}
                     onChange={(e) =>
                        setAdditionalContribution(Number(e.target.value))
                     }
                  />
               </div>
               <div>
                  <label>Contribute at the</label>
                  <select
                     value={contributionTiming}
                     onChange={(e) => setContributionTiming(e.target.value)}
                  >
                     <option value="beginning">beginning</option>
                     <option value="end">end</option>
                  </select>
                  <label>of each month/year</label>
               </div>
               <button onClick={calculateInvestment}>Calculate</button>
            </Col>

            <Col md={6}>
               {/* Display the bar chart next to the calculator */}
               {results && (
                  <InvestmentBarChart
                     accumulationSchedule={results.accumulationSchedule}
                  />
               )}
            </Col>
         </Row>

         {results && (
            <Row
               className="mt-4"
               style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
               }}
            >
               {/* Align the results and pie chart below */}
               <Col md={6}>
                  <h3>Results</h3>
                  <p>
                     You will need to invest {results.yearsToTarget} years (or{' '}
                     {results.monthsToTarget} months) to reach the target of{' '}
                     {target.toLocaleString()}.
                  </p>
                  <p>End Balance: {results.endBalance}</p>
                  <p>Starting Amount: {results.startingAmount}</p>
                  <p>Total Contributions: {results.totalContributions}</p>
                  <p>Total Interest: {results.totalInterest}</p>
               </Col>

               <Col md={6} style={{ marginTop: '100px' }}>
                  {/* Display the pie chart next to the results */}
                  <InvestmentPieChart
                     startingAmount={parseFloat(
                        results.startingAmount.replace(/,/g, '')
                     )}
                     totalContributions={parseFloat(
                        results.totalContributions.replace(/,/g, '')
                     )}
                     totalInterest={parseFloat(
                        results.totalInterest.replace(/,/g, '')
                     )}
                  />
               </Col>
            </Row>
         )}
      </Container>
   )
}

export default FireLength
