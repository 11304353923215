import React, { Suspense, useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import styles from '../Playlist.module.css'

import PlaylistSlider from '../PlaylistSlider'
import { usePlaylist } from '../../../../../../context/PlaylistContext'

const UserPlaylistsList = () => {
   const { allPlaylists, fetchPlaylists, isLoading } = usePlaylist()
   const [fetchError, setFetchError] = useState(false)

   useEffect(() => {
      const loadPlaylists = async () => {
         try {
            const success = await fetchPlaylists()
            console.log('Playlists carregadas com sucesso:', success)
            if (!success) {
               setFetchError(true)
            }
         } catch (error) {
            console.error('Erro ao carregar playlists no componente:', error)
            setFetchError(true)
         }
      }
      loadPlaylists()
   }, [fetchPlaylists])

   if (isLoading) {
      return <p>A carregar as playlists...</p>
   }

   if (fetchError) {
      return <p>Houve erro no carregamento das playlists.</p>
   }

   if (!allPlaylists.length) {
      return (
         <div className={styles.noPlaylistsMessage}>
            <h2>Não existem Playlists.</h2>
            <p>
               Ainda não criou nenhuma playlist. Comece por criar a sua primeira
               playlist!
            </p>
         </div>
      )
   }

   return (
      <div>
         <h2 className={styles.headerTitle}>As minhas Playlists</h2>
         <Row>
            {allPlaylists.map((playlist, index) => (
               <Col xs={12} key={index}>
                  <Suspense fallback={<div>A carregar playlist...</div>}>
                     <PlaylistSlider playlist={playlist} />
                  </Suspense>
               </Col>
            ))}
         </Row>
      </div>
   )
}

export default UserPlaylistsList
