import React, { useContext, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { InputText } from 'primereact/inputtext'
import { SavingsContext } from '../../../../../../../../../context/SavingsContext'

const SavingsFormInputs = ({ categories, selectedMonth }) => {
   const { setSavings, savings } = useContext(SavingsContext)

   useEffect(() => {
      initializeSavingsForMonth(selectedMonth)
   }, [selectedMonth, categories])

   const initializeSavingsForMonth = (month) => {
      setSavings((prevSavings) => {
         const updatedSavings = [...prevSavings]

         // Only initialize if the month data does not already exist
         if (!updatedSavings[month] || !updatedSavings[month].categories) {
            updatedSavings[month] = {
               month: month + 1,
               categories: categories.map((cat) => ({
                  ...cat,
                  id: String(cat.id), // Normalize ID to string
                  savings: 0,
               })),
            }
         }
         return updatedSavings
      })
   }

   const handleChange = (e) => {
      const { name, value } = e.target
      const [, categoryId] = name.split('-')
      const categoryIdStr = String(categoryId) // Ensure ID is a string

      console.log('Changing value for categoryId:', categoryIdStr, 'to:', value)

      setSavings((prevSavings) => {
         const updatedSavings = [...prevSavings]

         if (
            !updatedSavings[selectedMonth] ||
            !updatedSavings[selectedMonth].categories
         ) {
            console.error(
               `Selected month ${selectedMonth} is not initialized properly.`
            )
            return prevSavings
         }

         // Log all category IDs for this month and categories array for debugging
         console.log(
            'IDs in selectedMonth savings:',
            updatedSavings[selectedMonth].categories.map((cat) =>
               String(cat.id)
            )
         )

         // Find the category by ID
         const categoryIndex = updatedSavings[
            selectedMonth
         ].categories.findIndex((cat) => String(cat.id) === categoryIdStr)

         if (categoryIndex !== -1) {
            updatedSavings[selectedMonth].categories[categoryIndex].savings =
               parseFloat(value) || 0
         } else {
            console.error(`Category with ID ${categoryIdStr} not found.`)
         }

         console.log(
            'Updated savings:',
            updatedSavings[selectedMonth].categories
         )
         return updatedSavings
      })
   }

   const selectedMonthSavings = savings[selectedMonth]?.categories || []

   return (
      <Row
         className="tabContainer2"
         style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
         <Col xs={4}>
            {categories.map((categoryData) => {
               const monthData = selectedMonthSavings.find(
                  (cat) => String(cat.id) === String(categoryData.id)
               )
               return (
                  <div key={categoryData.id} style={{ marginBottom: '1rem' }}>
                     <label>{categoryData.name}</label>
                     <InputText
                        type="number"
                        name={`category-${categoryData.id}-savings`}
                        value={monthData?.savings || 0}
                        onChange={handleChange}
                        style={{ width: '100%', marginTop: '5px' }}
                     />
                  </div>
               )
            })}
         </Col>
      </Row>
   )
}

export default SavingsFormInputs
