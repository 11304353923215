import React, { useContext } from 'react'
import css from './Avatar.module.css'
import defaultAvatar from '../../assets/default-avatar.png'
import { UserContext } from '../../context/useUserContext'

const Avatar = ({ imageUrl, role }) => {
   const avatarToShow = imageUrl || defaultAvatar

   const borderColor =
      role === 'CreatorUser'
         ? 'gold'
         : role === 'AdminUser'
         ? 'red'
         : role === 'RegularUser'
         ? 'blue'
         : 'gray'

   return (
      <div
         className={css.avatar}
         style={{
            border: `2px solid ${borderColor}`,
            borderRadius: '50%',
         }}
      >
         <img
            src={avatarToShow}
            alt={`${role} Avatar`}
            className={css.avatarImage}
         />
      </div>
   )
}

export default Avatar
