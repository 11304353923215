import React from 'react'
import { useExpenses } from '../../../../../../../../../context/ExpensesContext'
import './WhatIfScenarios.css' // Assuming you're applying the provided CSS classes here
import ExpensesNav from './ExpensesNav'

const WhatIfScenarios = () => {
   const {
      categories = [], // Ensure categories is not undefined
      handleWhatIfChange,
      whatIfChanges, // Make sure to include whatIfChanges from useExpenses
   } = useExpenses()

   return (
      <div className="what-if-scenarios">
         <ExpensesNav />

         <h3>Simulador de Cortes nos Custos</h3>

         <div className="tabContainer2">
            {categories.length === 0 ? (
               <p>No categories available.</p>
            ) : (
               categories.map((category) => (
                  <div key={category.id} className="category-card">
                     <h4 className="category-header">{category.name}</h4>
                     {category.subcategories.map((sub) => (
                        <div key={sub.id} className="subcategory-row">
                           <label>{sub.name}</label>
                           <input
                              type="number"
                              name={`whatIf-${sub.id}`}
                              className="subcategory-input"
                              value={whatIfChanges[sub.id] || ''}
                              onChange={(e) => handleWhatIfChange(e, sub.id)}
                              placeholder="What if amount"
                           />
                        </div>
                     ))}
                  </div>
               ))
            )}
         </div>
      </div>
   )
}

export default WhatIfScenarios
