import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import css from '../../../DashboardRegular.module.css'
import { Row, Col } from 'reactstrap'

const FinancialHealthDashboard = () => {
   return (
      <Row className={css.tabContainer}>
         <Col>
            <nav className={css.tabNavigation}>
               <NavLink
                  to="financialHealthForm"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Sáude Financeira
               </NavLink>
               <NavLink
                  to="budget"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
                  end={false} // This ensures the NavLink remains active for subroutes
               >
                  Orçamento
               </NavLink>

               <NavLink
                  to="fire"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  FIRE
               </NavLink>
               <NavLink
                  to="investimentos"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Investimentos
               </NavLink>
            </nav>

            {/* Outlet for rendering selected financial data section */}
            <Outlet />
         </Col>
      </Row>
   )
}

export default FinancialHealthDashboard
