import React from 'react'
import styles from '../../IncomeForm.module.css'

const MonthTabs = ({ selectedMonth, setSelectedMonth }) => {
   const currentMonth = new Date().getMonth()
   const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
   ]
   const renderedTabs = []

   for (let i = 0; i <= currentMonth; i++) {
      renderedTabs.push(
         <div
            key={i}
            className={selectedMonth === i ? styles.activeTab : styles.tab}
            onClick={() => setSelectedMonth(i)}
         >
            {months[i]}
         </div>
      )
   }
   return <div className={styles.monthTabs}>{renderedTabs}</div>
}

export default MonthTabs
