import React, { useContext } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../../context/useUserContext'
import { handleError } from '../../../../../utils/handleError'
import css from './FileItem.module.css'
import { Col } from 'reactstrap'
const FileVisibilityToggle = ({ isVisible, setIsVisible }) => {
   const { user } = useContext(UserContext)

   const toggleVisibility = async () => {
      const newVisibility = !isVisible
      try {
         await axios.patch(
            `${process.env.REACT_APP_API_URL}users/${user.id}/visibility`,
            { contentVisibility: { files: newVisibility } },
            { headers: { Authorization: `Bearer ${user.accessToken}` } }
         )
         setIsVisible(newVisibility)
         toast.success('Visibility of files has been successfully updated!')
      } catch (error) {
         handleError(error)
         toast.error('Failed to update file visibility.')
      }
   }

   return (
      <div className={css.visibilityToggleContainer}>
         <Col sm={9} md={2}>
            Mostrar Ficheiros:
         </Col>
         <Col md={1}>
            <input
               type="checkbox"
               checked={isVisible}
               onChange={toggleVisibility}
            />
         </Col>
      </div>
   )
}

export default FileVisibilityToggle
