import React, { useState, useEffect } from 'react'
import InvestimentosForm from './InvestimentosForm'
import EvolucaoNetWorth from './EvolucaoNetWorth'
import axios from 'axios'

export default function Investimentos() {
   const [investments, setInvestments] = useState([])

   useEffect(() => {
      const fetchInvestments = async () => {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}/investments`
            )
            setInvestments(response.data)
         } catch (error) {
            console.error('Failed to fetch investments', error)
         }
      }

      fetchInvestments()
   }, [])

   const handleInvestmentsChange = (newInvestments) => {
      setInvestments(newInvestments)
   }

   return (
      <div>
         <h2>Investimentos</h2>
         <InvestimentosForm onInvestmentsChange={handleInvestmentsChange} />
         <EvolucaoNetWorth investments={investments} />
      </div>
   )
}
