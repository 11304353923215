export const getBarChartOptions = () => ({
   plugins: {
      legend: {
         labels: {
            color: 'white',
         },
      },
      tooltip: {
         callbacks: {
            label: function (tooltipItem) {
               const total = tooltipItem.dataset.data.reduce(
                  (sum, value) => sum + value,
                  0
               )
               const value = tooltipItem.raw
               const percentage = ((value / total) * 100).toFixed(2)
               return `${tooltipItem.label}: ${percentage}% (${value})`
            },
         },
      },
      datalabels: {
         color: 'white',
         formatter: (value) => value,
         anchor: 'end',
         align: 'end',
         offset: 5,
         textAlign: 'center',
         borderWidth: 1,
         borderRadius: 4,
         borderColor: 'white',
         padding: 5,
      },
   },
   scales: {
      x: {
         ticks: {
            color: 'white',
         },
      },
      y: {
         ticks: {
            color: 'white',
            callback: function (value) {
               return value + '%' // Display percentage on the y-axis
            },
         },
         beginAtZero: true,
         suggestedMax: 100,
      },
   },
})

export const getLineChartOptions = () => ({
   plugins: {
      legend: {
         labels: {
            color: 'white',
         },
      },
      tooltip: {
         callbacks: {
            label: function (tooltipItem) {
               return `€${tooltipItem.raw.toFixed(2)}`
            },
         },
      },
   },
   scales: {
      x: {
         ticks: {
            color: 'white',
         },
      },
      y: {
         ticks: {
            color: 'white',
            callback: function (value) {
               return `€${value}`
            },
         },
         beginAtZero: true,
      },
   },
})

export const getPieChartOptions = () => ({
   plugins: {
      legend: {
         labels: {
            color: 'white',
         },
      },
      tooltip: {
         callbacks: {
            label: function (tooltipItem) {
               return `€${tooltipItem.raw.toFixed(2)}`
            },
         },
      },
   },
})
