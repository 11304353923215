import React, { useState, useEffect, useContext } from 'react'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { MultiSelect } from 'primereact/multiselect'
import { Button } from 'primereact/button'
import axios from 'axios'
import moment from 'moment'
import { UserContext } from '../../../../../context/useUserContext' // Ensure the path is correct
import SubscriptionCard from './SubscriptionCard'
import { Col, Row } from 'reactstrap'

const EditableUserInfoCard = () => {
   const { user, updateUserProfilePicture, updateUserContext } =
      useContext(UserContext)
   const [editData, setEditData] = useState({ ...user })
   const [imagePreviewUrl, setImagePreviewUrl] = useState(
      user.profilePicture || null
   )
   const [selectedFile, setSelectedFile] = useState(null)
   const topics = [
      { label: 'ETFs', value: 'ETFs' },
      { label: 'Ações', value: 'Ações' },
      { label: 'Reits', value: 'Reits' },
      { label: 'Cryptos', value: 'Cryptos' },
      { label: 'Finanças Pessoais', value: 'Finanças Pessoais' },
      { label: 'Poupança', value: 'Poupança' },
      { label: 'Imobiliário', value: 'Imobiliário' },
      { label: 'Obrigações', value: 'Obrigações' },
      { label: 'Fundos mútuos', value: 'Fundos mútuos' },
      { label: 'Empreendedorismo', value: 'Empreendedorismo' },
      { label: 'Futuros e Opções', value: 'Futuros e Opções' },
      { label: 'Trading', value: 'Trading' },
   ]

   useEffect(() => {
      if (user) {
         const dateOfBirth = user.dateOfBirth
            ? new Date(user.dateOfBirth)
            : null
         setEditData({ ...user, dateOfBirth })
         setImagePreviewUrl(user.profilePictureUrl || null)
      }
   }, [user])

   const handleChange = (e) => {
      const { name, value } = e.target
      if (name === 'dateOfBirth') {
         setEditData({ ...editData, dateOfBirth: e.value })
      } else {
         setEditData({ ...editData, [name]: value })
      }
   }

   const handleFileChange = (e) => {
      const file = e.target.files[0]
      setSelectedFile(file)
      if (file) {
         const reader = new FileReader()
         reader.onloadend = () => {
            setImagePreviewUrl(reader.result)
         }
         reader.readAsDataURL(file)
      }
   }

   const handleSave = async () => {
      const payload = {
         ...editData,
         dateOfBirth: editData.dateOfBirth
            ? moment(editData.dateOfBirth).toISOString() // Ensure the format is compatible with your backend
            : null,
      }

      try {
         if (selectedFile) {
            const uploadResult = await handleProfilePictureUpload(selectedFile)
            if (uploadResult && uploadResult.data) {
               payload.profilePicture = uploadResult.data.url
            }
         }

         const response = await axios.put(
            `${process.env.REACT_APP_API_URL}users/updateRegular/${user.id}`,
            payload
         )

         if (response.data) {
            updateUserContext({ ...user, ...payload })
            updateUserProfilePicture(payload.profilePicture)
         }
      } catch (error) {
         console.error('Error updating user:', error)
      }
   }

   const handleProfilePictureUpload = async (file) => {
      const formData = new FormData()
      formData.append('profilePicture', file)
      formData.append('userId', user.id)

      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}users/upload/profilePicture`,
            formData,
            {
               headers: { 'Content-Type': 'multipart/form-data' },
            }
         )

         const newProfilePictureUrl = response.data.url
         setImagePreviewUrl(newProfilePictureUrl)
         updateUserProfilePicture(newProfilePictureUrl)

         // Update the editData state to include the new profile picture URL
         setEditData((prevData) => ({
            ...prevData,
            profilePicture: newProfilePictureUrl,
         }))
      } catch (error) {
         console.error('Error uploading profile picture:', error)
      }
   }

   return (
      <Row style={{ display: 'flex' }}>
         <Col xs={6} className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12">
               <label htmlFor="profilePicture">Foto de Perfil</label>
               {imagePreviewUrl && (
                  <div
                     style={{
                        width: '150px',
                        height: '150px',
                        border: '1px solid #ddd',
                        marginTop: '10px',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${imagePreviewUrl})`,
                        backgroundPosition: 'center center',
                     }}
                  />
               )}
               <input type="file" accept=".webp" onChange={handleFileChange} />
            </div>
            <div className="p-field p-col-12">
               <label htmlFor="username">Username</label>
               <InputText
                  id="username"
                  name="username"
                  value={editData.username || ''}
                  onChange={handleChange}
               />
            </div>
            <div className="p-field p-col-12">
               <label htmlFor="firstname">Nome</label>
               <InputText
                  id="firstname"
                  name="firstname"
                  value={editData.firstname || ''}
                  onChange={handleChange}
               />
            </div>
            <div className="p-field p-col-12">
               <label htmlFor="lastname">Último Nome</label>
               <InputText
                  id="lastname"
                  name="lastname"
                  value={editData.lastname || ''}
                  onChange={handleChange}
               />
            </div>
            <div className="p-field p-col-12">
               <label htmlFor="email">Email</label>
               <InputText
                  id="email"
                  name="email"
                  value={editData.email || ''}
                  onChange={handleChange}
               />
            </div>
            <div className="p-field p-col-12">
               <label htmlFor="dateOfBirth">Data de Nascimento</label>
               <Calendar
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={editData.dateOfBirth}
                  onChange={handleChange}
                  dateFormat="dd/mm/yy"
                  mask="99/99/9999"
                  style={{ color: 'black' }}
                  showIcon
               />
            </div>
            <div className="p-field p-col-12">
               <label htmlFor="city">Cidade</label>
               <InputText
                  id="city"
                  name="city"
                  value={editData.city || ''}
                  onChange={handleChange}
                  placeholder="Enter city"
               />
            </div>
            <div className="p-field p-col-12">
               <label htmlFor="topics">Temas Favoritos</label>
               <MultiSelect
                  id="topics"
                  name="topics"
                  value={editData.topics}
                  options={topics}
                  onChange={handleChange}
                  optionLabel="label"
                  placeholder="Select temas"
                  style={{ color: 'black' }}
               />
            </div>
            <div className="p-field p-col-12">
               <Button label="Save" onClick={handleSave} />
            </div>
         </Col>
         <Col xs={6}>
            <SubscriptionCard />
         </Col>
      </Row>
   )
}

export default EditableUserInfoCard
