import React from 'react'
import { Helmet } from 'react-helmet'

const SEOHelmet = ({ title, description, imageUrl }) => (
   <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
   </Helmet>
)

export default SEOHelmet
