import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { SavingsContext } from '../../../../../../../../../context/SavingsContext'
import { getLineChartOptions } from './chartConfig'
import { UserContext } from '../../../../../../../../../context/useUserContext'
import axios from 'axios'

const SavingsOverTimeChart = () => {
   const { savings, categories } = useContext(SavingsContext)
   const { user } = useContext(UserContext)
   const userId = user?.id
   const [savingsByYear, setSavings] = useState([])
   const [error, setError] = useState(null)

   useEffect(() => {
      const fetchYearlyData = async () => {
         try {
            const url = `${process.env.REACT_APP_API_URL}/savingRoutes/user/${userId}/yearly-savings`
            console.log('Fetching from URL:', url)
            const response = await axios.get(url, {
               headers: {
                  Authorization: `Bearer ${user.accessToken}`,
               },
            })
            console.log('API Response:', response.data)
            setSavings(response.data.savings)
         } catch (err) {
            console.error('Error fetching yearly savings data:', err)
            setError('Failed to fetch savings data')
         }
      }

      fetchYearlyData()
   }, [userId])

   const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
   ]

   const totalSavings = useMemo(
      () =>
         savingsByYear.map((monthSavings) =>
            categories.reduce(
               (sum, category) =>
                  sum +
                  (monthSavings.categories.find((cat) => cat.id === category.id)
                     ?.savings || 0),
               0
            )
         ),
      [savings, categories]
   )

   const chartData = {
      labels: months,
      datasets: [
         {
            label: 'Total Savings Over Time',
            data: totalSavings,
            fill: false,
            borderColor: '#36A2EB',
            tension: 0.1,
         },
      ],
   }

   return <Line data={chartData} options={getLineChartOptions()} />
}

export default SavingsOverTimeChart
