import React, { useContext } from 'react'
import { Row } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import { UserContext } from '../../context/useUserContext'
import css from './ToolIndex.module.css'

function ToolTypes() {
   const { user } = useContext(UserContext)
   const isAuthenticated = user?.role && user.role !== 'visitor'

   return (
      <div>
         <Row className={css.tabContainer}>
            <div className={css.tabContainer}>
               <nav className={css.tabNavigation}>
                  <NavLink
                     to="/ferramentas/investingTools/etf"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Ferramentas de Análise
                  </NavLink>

                  <NavLink
                     to="/ferramentas/personalFinanceTools"
                     className={({ isActive }) =>
                        isActive ? css.activeTab : css.tab
                     }
                  >
                     Ferramentas Finanças Pessoais
                  </NavLink>
               </nav>
            </div>
            <div className={css.tabContent}>
               {isAuthenticated ? (
                  <Outlet />
               ) : (
                  <>
                     <div className={css.blurContent}>
                        <Outlet />
                     </div>
                     <div className={css.overlay}>
                        Por favor, faça login para acessar esta ferramenta.
                     </div>
                  </>
               )}
            </div>
         </Row>
      </div>
   )
}

export default ToolTypes
