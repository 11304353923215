import React, { useState, useContext, useEffect } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { DebtsContext } from '../../../../../../../../../context/DebtsContext'
import { UserContext } from '../../../../../../../../../context/useUserContext'
import axios from 'axios'
import DebtNavigation from './DebtNavigation'
import './DebtCategoryManager.css' // Import custom CSS file
import { Col, Row } from 'reactstrap'

const DebtCategoryManager = () => {
   const { categories, setCategories } = useContext(DebtsContext)
   const [newCategoryName, setNewCategoryName] = useState('')
   const [newSubcategoryNames, setNewSubcategoryNames] = useState({})
   const { user } = useContext(UserContext)
   const userId = user.id

   useEffect(() => {
      if (userId) {
         fetchCategories()
      }
   }, [userId])

   const fetchCategories = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}users/${userId}/debt-categories`,
            {
               headers: {
                  Authorization: `Bearer ${user.accessToken}`,
               },
            }
         )
         setCategories(response.data)
      } catch (error) {
         console.error('Error fetching debt categories:', error)
      }
   }

   const handleAddCategory = () => {
      if (newCategoryName.trim()) {
         const newCategory = {
            id: Date.now().toString(),
            name: newCategoryName,
            debt: 0,
            subcategories: [],
         }
         setCategories([...categories, newCategory])
         setNewCategoryName('')
      }
   }

   const handleDeleteCategory = (categoryId) => {
      const updatedCategories = categories.filter(
         (category) => category.id !== categoryId
      )
      setCategories(updatedCategories)
   }

   return (
      <div className="container">
         <DebtNavigation />
         <h2>Gerir Categorias de Dívidas</h2>
         <div className="categoryList">
            {categories.map((category) => (
               <div className="categoryCard" key={category.id}>
                  <div className="categoryHeader">
                     <span>{category.name}</span>
                     <Button
                        label="Delete Category"
                        onClick={() => handleDeleteCategory(category.id)}
                        className="deleteButton"
                     />
                  </div>
               </div>
            ))}
         </div>
         <Row>
            <Col className="addCategoryContainer" xs={4}>
               <InputText
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  placeholder="Nova Categoria"
                  className="inputField"
               />
               <Button
                  onClick={handleAddCategory}
                  label="Adicionar Categoria"
                  className="addButton"
               />
            </Col>
         </Row>
      </div>
   )
}

export default DebtCategoryManager
