import React, { useContext, useEffect, useState } from 'react'
import { Switch } from '@mui/material'
import axios from 'axios'

import { Button } from 'primereact/button'
import { Col } from 'reactstrap'
import { UserContext } from '../../../../../context/useUserContext'

const NotificationSettings = ({ creatorId }) => {
   const [settings, setSettings] = useState({
      notificationsEnabled: true,
      contentPreferences: {
         newPlaylists: true,
         newCourses: true,
         newArticles: true,
         newEvents: true,
         newFiles: true,
         newAnnouncements: true,
      },
      emailNotifications: true,
      pushNotifications: true,
      emailLanguage: 'en',
   })
   const [isLoading, setIsLoading] = useState(true)
   const [error, setError] = useState(null)
   const { user } = useContext(UserContext)
   const userId = user.id

   const contentPreferencesLabels = {
      newPlaylists: 'Lista de reprodução ',
      newCourses: 'Cursos',
      newArticles: 'Artigos',
      newEvents: 'Eventos',
      newFiles: 'Ficheiros',
      newAnnouncements: 'Anúncios',
   }

   useEffect(() => {
      const fetchSettings = async () => {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}notification/preferences?userId=${userId}&creatorId=${creatorId}`
            )
            if (response.data) {
               setSettings(response.data)
            }
            setError(null)
         } catch (error) {
            console.error('Error fetching notification settings:', error)
            setError('Failed to fetch notification settings')
         }
         setIsLoading(false)
      }

      fetchSettings()
   }, [userId, creatorId])

   const handleToggle = (settingName) => {
      setSettings((prevSettings) => ({
         ...prevSettings,
         [settingName]: !prevSettings[settingName],
      }))
   }

   const handleToggleContentType = (contentType) => {
      setSettings((prevSettings) => ({
         ...prevSettings,
         contentPreferences: {
            ...prevSettings.contentPreferences,
            [contentType]: !prevSettings.contentPreferences[contentType],
         },
      }))
   }

   const handleToggleAll = (enabled) => {
      const updatedPreferences = Object.keys(
         settings.contentPreferences || {}
      ).reduce((acc, key) => {
         acc[key] = enabled
         return acc
      }, {})

      setSettings((prevSettings) => ({
         ...prevSettings,
         contentPreferences: updatedPreferences,
         notificationsEnabled: enabled,
      }))
   }

   const handleSubmit = async () => {
      try {
         await axios.post(
            `${process.env.REACT_APP_API_URL}notification/updatePreferences`,
            {
               userId,
               creatorId,
               preferences: settings,
            }
         )
         alert('Settings updated successfully')
      } catch (error) {
         console.error('Error updating notification settings:', error)
         alert('Failed to update settings')
      }
   }

   if (isLoading) {
      return <p>Loading settings...</p>
   }

   if (error) {
      return <p>{error}</p>
   }

   return (
      <div>
         <h1>Preferências de Notificações</h1>
         <div style={{ display: 'flex' }}>
            <Col>
               <div>
                  <label>Ativar todas as notificações:</label>
                  <Switch
                     checked={settings.notificationsEnabled}
                     onChange={() =>
                        handleToggleAll(!settings.notificationsEnabled)
                     }
                  />
               </div>
               <h2>Preferências por conteúdo</h2>
               {settings.contentPreferences &&
                  Object.keys(settings.contentPreferences).map(
                     (contentType) => (
                        <div key={contentType}>
                           <label>
                              {contentPreferencesLabels[contentType]}
                           </label>
                           <Switch
                              checked={settings.contentPreferences[contentType]}
                              onChange={() =>
                                 handleToggleContentType(contentType)
                              }
                              disabled={!settings.notificationsEnabled}
                           />
                        </div>
                     )
                  )}
            </Col>
            <Col>
               <h2>Preferências de envio</h2>
               <div>
                  <label>Notificações por Email:</label>
                  <Switch
                     checked={settings.emailNotifications}
                     onChange={() => handleToggle('emailNotifications')}
                  />
               </div>
               <div>
                  <label>Notificações Push:</label>
                  <Switch
                     checked={settings.pushNotifications}
                     onChange={() => handleToggle('pushNotifications')}
                  />
               </div>
            </Col>
         </div>
         <Button onClick={handleSubmit}>Guardar Preferências</Button>
      </div>
   )
}

export default NotificationSettings
