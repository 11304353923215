import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import css from '../../../../../DashboardRegular.module.css'
import { Row, Col } from 'reactstrap'

const FireManager = () => {
   return (
      <Row className={css.tabContainer2}>
         <Col>
            <nav className={css.tabNavigation}>
               <NavLink
                  to="anosFire"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  Duração do Investimento
               </NavLink>
               <NavLink
                  to="fire-regra-4"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  FIRE - Regra 4%
               </NavLink>
               <NavLink
                  to="fire-dividendos"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  FIRE - Dividendos
               </NavLink>
               <NavLink
                  to="fire-graphs"
                  className={({ isActive }) =>
                     isActive ? css.activeTab : css.tab
                  }
               >
                  FireGraphs
               </NavLink>
            </nav>

            {/* Outlet for rendering selected FIRE section */}
            <Outlet />
         </Col>
      </Row>
   )
}

export default FireManager
