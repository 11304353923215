import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { UserContext } from '../../context/useUserContext'

const PrivateRoute = () => {
   const { user, loading } = React.useContext(UserContext)
   const location = useLocation()

   const isDashboardRoute = location.pathname.startsWith('/dashboard/')

   if (loading) return <div>Loading...</div>

   // Verificar se o usuário é visitante e está tentando acessar uma rota protegida
   if (isDashboardRoute && (!user || user.role === 'visitor')) {
      return <Navigate to="/login" />
   }

   return <Outlet />
}

export default PrivateRoute
