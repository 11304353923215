import React from 'react'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

// Register core Chart.js components and the ChartDataLabels plugin globally
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const InvestmentPieChart = ({
   startingAmount,
   totalContributions,
   totalInterest,
}) => {
   const data = {
      labels: ['Starting Amount', 'Total Contributions', 'Interest'],
      datasets: [
         {
            label: 'Investment Breakdown',
            data: [startingAmount, totalContributions, totalInterest],
            backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
            hoverBackgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
         },
      ],
   }

   const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
         legend: {
            labels: {
               color: '#FFFFFF', // Set legend labels color to white
               font: {
                  size: 12, // Adjust font size if needed
               },
            },
         },
         datalabels: {
            color: '#FFFFFF', // Set data label color to white
            formatter: (value, ctx) => {
               let total = ctx.chart.data.datasets[0].data.reduce(
                  (acc, curr) => acc + curr,
                  0
               )
               let percentage = ((value / total) * 100).toFixed(2) + '%'
               return percentage // Return percentage instead of raw value
            },
            font: {
               size: 14,
            },
         },
         title: {
            display: false,
         },
      },
   }

   return (
      <div style={{ width: '500px', height: '350px' }}>
         <Pie data={data} options={options} />
      </div>
   )
}

export default InvestmentPieChart
