import React, { useContext, useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { UserContext } from '../../../../../../../../../context/useUserContext'
import axios from 'axios'
import { getPieChartOptions } from './chartConfig'

const SavingsPieChart = ({ selectedMonth }) => {
   const { user } = useContext(UserContext)
   const userId = user?.id
   const [savingsByMonth, setSavingsByMonth] = useState([])
   // const selectedMonth = new Date().getMonth()

   useEffect(() => {
      const fetchSavingsData = async () => {
         try {
            const url = `${process.env.REACT_APP_API_URL}/savingRoutes/user/${userId}/yearly-savings`
            const response = await axios.get(url, {
               headers: {
                  Authorization: `Bearer ${user.accessToken}`,
               },
            })
            console.log('API Response:', response)

            // Extract savings for the selected month
            const selectedMonthSavings =
               response.data.savings[selectedMonth]?.categories || []
            console.log('selectedMonthSavings:', selectedMonthSavings)

            if (selectedMonthSavings.length > 0) {
               setSavingsByMonth(selectedMonthSavings)
            } else {
               console.warn(
                  `No savings data found for month: ${selectedMonth + 1}`
               )
               setSavingsByMonth([]) // Set an empty array if no data is found
            }
         } catch (err) {
            console.error('Error fetching savings data:', err)
         }
      }

      fetchSavingsData()
   }, [userId, selectedMonth, user.accessToken])

   const chartData = {
      labels: savingsByMonth.map((category) => category.name),
      datasets: [
         {
            label: 'Savings Distribution',
            data: savingsByMonth.map((category) => category.savings || 0),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            borderColor: '#ffffff',
            borderWidth: 1,
         },
      ],
   }

   return savingsByMonth.length > 0 ? (
      <Pie data={chartData} options={getPieChartOptions()} />
   ) : (
      <p>No savings data available for this month.</p>
   )
}

export default SavingsPieChart
