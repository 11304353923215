import React from 'react'
import { Row, Col } from 'reactstrap'
import css from './DashboardCreatorComponents.module.css'
import CreatorDetails from './perfilComponents/CreatorDetails'
import SocialMediaLinks from './perfilComponents/SocialMediaLinks'

const CreatorInfo = () => {
   return (
      <div className={css.creatorInfoContainer}>
         <h2 className={css.pageTitle}>Informações do Criador</h2>
         <Row>
            <Col xs={12} lg={6} className={css.creatorDetails}>
               <CreatorDetails />
            </Col>
            <Col xs={12} lg={6} className={css.socialMedia}>
               <SocialMediaLinks />
            </Col>
         </Row>
      </div>
   )
}

export default CreatorInfo
