import React from 'react'
import { InputText, InputTextarea, InputNumber, Button } from 'primereact'
import ProgressSpinner from '../../../../../utils/ProgressSpinner'
import useCourseForm from './useCourseForm'
const CourseForm = ({
   existingCourse,
   onClose,
   createCourse,
   handleUpdateCourse,
}) => {
   const topicOptions = [
      'ETFs',
      'Ações',
      'REITs',
      'Cripto Moedas',
      'Finanças Pessoais',
      'Poupança',
      'Imobiliário',
      'Obrigações',
      'Fundos mútuos',
      'Empreendedorismo',
      'Futuros e Opções',
      'Trading',
   ]

   const statusOptions = ['published', 'draft']

   const formik = useCourseForm(
      existingCourse,
      createCourse,
      handleUpdateCourse,
      onClose
   )

   return (
      <form onSubmit={formik.handleSubmit} className="formDialog">
         {formik.isSubmitting && <ProgressSpinner />}
         <div className="dialogContent">
            <div className="eventImageContainer">
               <img
                  src={existingCourse?.bannerImage || 'default-image-path'}
                  alt="Imagem do Curso"
                  className="eventCover"
               />
            </div>
            <div className="contentPading">
               <div className="formRow">
                  <label htmlFor="courseName" className="formLabel">
                     Nome do Curso
                  </label>
                  <InputText
                     id="courseName"
                     name="courseName"
                     value={formik.values.courseName}
                     onChange={formik.handleChange}
                     className="formInput"
                     required
                  />
               </div>
               <div className="formRow">
                  <label htmlFor="description" className="formLabel">
                     Descrição
                  </label>
                  <InputTextarea
                     id="description"
                     name="description"
                     value={formik.values.description}
                     onChange={formik.handleChange}
                     className="formTextarea"
                     required
                     rows={3}
                  />
               </div>
               <div className="formRow">
                  <label htmlFor="price" className="formLabel">
                     Preço
                  </label>
                  <InputNumber
                     id="price"
                     name="price"
                     value={formik.values.price}
                     onValueChange={(e) =>
                        formik.setFieldValue('price', e.value)
                     }
                     mode="currency"
                     currency="EUR"
                     className="formInput"
                     required
                  />
               </div>
               <div className="formRow">
                  <label htmlFor="topic" className="formLabel">
                     Escolher Tópico
                  </label>
                  <select
                     id="topic"
                     name="topic"
                     value={formik.values.topic}
                     onChange={formik.handleChange}
                     className="formSelect"
                  >
                     {topicOptions.map((option) => (
                        <option key={option} value={option}>
                           {option}
                        </option>
                     ))}
                  </select>
               </div>
               <div className="formRow">
                  <label htmlFor="bannerImage" className="formLabel">
                     Imagem da Capa
                  </label>
                  <input
                     type="file"
                     accept="image/*"
                     name="bannerImage"
                     onChange={(event) =>
                        formik.setFieldValue(
                           'bannerImage',
                           event.currentTarget.files[0]
                        )
                     }
                     className="formInputFile"
                  />
               </div>
               <div className="formRow">
                  <label htmlFor="purchaseLink" className="formLabel">
                     Link para Comprar
                  </label>
                  <InputText
                     id="purchaseLink"
                     name="purchaseLink"
                     value={formik.values.purchaseLink}
                     onChange={formik.handleChange}
                     className="formInput"
                  />
               </div>
               <div className="formRow">
                  <label htmlFor="status" className="formLabel">
                     Escolher Estado
                  </label>
                  <select
                     id="status"
                     name="status"
                     value={formik.values.status}
                     onChange={formik.handleChange}
                     className="formSelect"
                  >
                     {statusOptions.map((option) => (
                        <option key={option} value={option}>
                           {option}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <div className="dialogActions">
               <Button
                  type="submit"
                  label="Submeter Curso"
                  className="submitButton"
               />
               <Button
                  type="button" // Garantir que o botão não dispara o submit
                  label="Cancelar"
                  className="cancelButton"
                  onClick={onClose} // Apenas fecha o modal
               />
            </div>
         </div>
      </form>
   )
}

export default CourseForm
