import React from 'react'
import { Pie } from 'react-chartjs-2'

const CurrentMonthExpensePieChart = ({ data }) => {
   return (
      <div>
         <h3>Despesas do Mês Atual</h3>
         <Pie data={data} />
      </div>
   )
}

export default CurrentMonthExpensePieChart
