import React from 'react'
import { InputText } from 'primereact/inputtext'

const IncomeInputGroup = ({ label, name, value, onChange }) => {
   const handleInputChange = (e) => {
      const { value } = e.target
      // Allow only numeric input
      if (!isNaN(value) && /^\d*\.?\d*$/.test(value)) {
         onChange(e)
      }
   }

   return (
      <div>
         <div>{label}:</div>
         <InputText
            type="text" // Keep the type as 'text' to allow better control over input validation
            name={name}
            value={value || ''}
            onChange={handleInputChange}
         />
      </div>
   )
}

export default IncomeInputGroup
