import axios from 'axios'

const api = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   withCredentials: true,
   headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
   },
})

api.interceptors.response.use(
   (response) => response,
   async (error) => {
      const originalRequest = error.config
      if (error.response?.status === 401 && !originalRequest._retry) {
         originalRequest._retry = true
         try {
            const { data } = await axios.post(
               `${process.env.REACT_APP_API_URL}/authRoutes/refresh-token`,
               {},
               { withCredentials: true }
            )
            const { accessToken } = data
            localStorage.setItem('token', accessToken)
            api.defaults.headers.common[
               'Authorization'
            ] = `Bearer ${accessToken}`
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`
            return api(originalRequest)
         } catch (refreshError) {
            localStorage.clear()
            window.location.href = '/login'
         }
      }
      return Promise.reject(error)
   }
)

export default api
