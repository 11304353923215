import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import axios from 'axios'
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   BarElement,
} from 'chart.js'

// Register required elements
ChartJS.register(
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   BarElement
)

const EvolucaoNetWorth = ({ investments, fireGoal }) => {
   const [netWorthData, setNetWorthData] = useState([])
   const [annualGoal, setAnnualGoal] = useState(25000) // Default annual goal
   const [currentNetWorth, setCurrentNetWorth] = useState(0)

   useEffect(() => {
      const fetchNetWorthData = async () => {
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}investments/networth`
            )
            const data = response.data
            setNetWorthData(data.netWorthData)
            setCurrentNetWorth(data.currentNetWorth)
            setAnnualGoal(data.annualGoal)
         } catch (error) {
            console.error('Failed to fetch net worth data', error)
         }
      }

      fetchNetWorthData()
   }, [])

   const handleGoalChange = async (newGoal) => {
      setAnnualGoal(newGoal)
      try {
         await axios.post(
            `${process.env.REACT_APP_API_URL}/investments/setGoal`,
            { annualGoal: newGoal }
         )
      } catch (error) {
         console.error('Failed to set goal', error)
      }
   }

   const data = {
      labels: netWorthData.map((item) => item.date),
      datasets: [
         {
            label: 'Evolução de Net Worth',
            data: netWorthData.map((item) => item.value),
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 2,
            pointRadius: 3,
         },
      ],
   }

   const progressBarData = {
      labels: ['Net Worth Atual', 'FIRE Goal'],
      datasets: [
         {
            label: 'Progress',
            data: [currentNetWorth, fireGoal || 0],
            backgroundColor: ['rgba(75,192,192,1)', 'rgba(192,75,75,1)'],
            maxBarThickness: 30,
         },
      ],
   }

   return (
      <div>
         <h3>Net Worth Tracker</h3>
         <div style={{ marginBottom: '20px' }}>
            <label>
               Objetivo anual de Net Worth:
               <input
                  type="number"
                  value={annualGoal}
                  onChange={(e) => handleGoalChange(Number(e.target.value))}
               />
            </label>
         </div>

         <div>
            <h3>NET WORTH ATUAL</h3>
            <h1>{currentNetWorth} €</h1>
            <p>Variação YTD</p>
         </div>

         <div>
            <h3>Progresso para objetivo anual</h3>
            <div>
               <div
                  style={{
                     width: `${(currentNetWorth / annualGoal) * 100}%`,
                     backgroundColor: 'green',
                     height: '20px',
                  }}
               />
               <div
                  style={{
                     width: `${100 - (currentNetWorth / annualGoal) * 100}%`,
                     backgroundColor: 'grey',
                     height: '20px',
                  }}
               />
            </div>
         </div>

         {fireGoal && (
            <div>
               <h3>Progresso para FIRE</h3>
               <div>
                  <div
                     style={{
                        width: `${(currentNetWorth / fireGoal) * 100}%`,
                        backgroundColor: 'green',
                        height: '20px',
                     }}
                  />
                  <div
                     style={{
                        width: `${100 - (currentNetWorth / fireGoal) * 100}%`,
                        backgroundColor: 'grey',
                        height: '20px',
                     }}
                  />
               </div>
            </div>
         )}

         <div style={{ marginTop: '20px' }}>
            <h3>Evolução de Net Worth</h3>
            <Line data={data} />
         </div>
      </div>
   )
}

export default EvolucaoNetWorth
