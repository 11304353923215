import React, { useContext, useState, useEffect } from 'react'
import { Row } from 'reactstrap'
import { Button } from 'primereact/button'
import DebtDetails from './components/debtsFormComponents/DebtDetails'
import MonthTabs from './components/debtsFormComponents/MonthTabs'
import { DebtsContext } from '../../../../../../../context/DebtsContext'
import axios from 'axios'
import { UserContext } from '../../../../../../../context/useUserContext'
import { useForm } from 'react-hook-form'
import DebtNavigation from './components/debtsFormComponents/DebtNavigation'
import IncomeDebtRatio from './components/debtsFormComponents/IncomeDebtRatio'

function DebtsForm({ onDebtSaved }) {
   const { debts, categories, selectedMonth, setSelectedMonth, updateDebts } =
      useContext(DebtsContext)
   const [submissionMessage, setSubmissionMessage] = useState('')
   const [isEditing, setIsEditing] = useState(false)
   const { user } = useContext(UserContext)
   const userId = user.id

   const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
   ]

   const { control, getValues, reset } = useForm({
      defaultValues: {
         debts: categories.map(() => ({
            amount: '',
            remainingBalance: '',
            interestRate: '',
            payment: '',
            dueDate: null,
         })),
      },
   })

   useEffect(() => {
      const fetchDebtData = async () => {
         const currentYear = new Date().getFullYear()

         try {
            const response = await axios.get(
               `${
                  process.env.REACT_APP_API_URL
               }debtRoutes/user/${userId}/month/${
                  selectedMonth + 1
               }/year/${currentYear}`,
               {
                  headers: {
                     Authorization: `Bearer ${user.accessToken}`,
                  },
               }
            )

            if (response?.data?.length && response.data[0]?.categories) {
               const debtCategories = response.data[0].categories.map(
                  (category) => ({
                     amount: category.amount || '',
                     remainingBalance: category.remainingBalance || '',
                     interestRate: category.interestRate || '',
                     payment: category.payment || '',
                     dueDate: category.dueDate
                        ? new Date(category.dueDate)
                        : null,
                  })
               )

               reset({ debts: debtCategories })
               setIsEditing(true)
            } else {
               reset({
                  debts: categories.map(() => ({
                     amount: '',
                     remainingBalance: '',
                     interestRate: '',
                     payment: '',
                     dueDate: null,
                  })),
               })
               updateDebts(selectedMonth, []) // Clear debts for the selected month
               setIsEditing(false)
               console.warn('No categories found for the selected month/year.')
            }
         } catch (error) {
            if (error.response && error.response.status === 404) {
               reset({
                  debts: categories.map(() => ({
                     amount: '',
                     remainingBalance: '',
                     interestRate: '',
                     payment: '',
                     dueDate: null,
                  })),
               })
               updateDebts(selectedMonth, []) // Clear debts for the selected month
               setIsEditing(false)
               console.warn('No debt data found for the selected month.')
            } else {
               console.error('Error fetching debt data:', error)
            }
         }
      }

      fetchDebtData()
   }, [selectedMonth, userId, reset, categories, user.accessToken, updateDebts])

   const handleSave = async () => {
      const debtData = debts[selectedMonth]
      console.log('handleSave - Debt Data before Transformation:', debtData)

      const currentYear = new Date().getFullYear()

      try {
         const formData = getValues()
         const categoriesData = categories.map((category, index) => ({
            id: category.id,
            name: category.name,
            debt: category.debt || 0,
            amount: formData.debts[index]?.amount || 0,
            remainingBalance: formData.debts[index]?.remainingBalance || 0,
            interestRate: formData.debts[index]?.interestRate || 0,
            payment: formData.debts[index]?.payment || 0,
            dueDate: formData.debts[index]?.dueDate || null,
         }))

         console.log(
            'handleSave - Transformed Categories Data:',
            categoriesData
         )

         if (isEditing) {
            await axios.put(
               `${process.env.REACT_APP_API_URL}debtRoutes/${debtData._id}`,
               {
                  ...debtData,
                  categories: categoriesData,
                  month: selectedMonth + 1,
                  year: currentYear,
                  userId,
               },
               {
                  headers: {
                     Authorization: `Bearer ${user.accessToken}`,
                  },
               }
            )
         } else {
            const response = await axios.post(
               `${process.env.REACT_APP_API_URL}debtRoutes/`,
               {
                  ...debtData,
                  categories: categoriesData,
                  month: selectedMonth + 1,
                  year: currentYear,
                  userId,
               },
               {
                  headers: {
                     Authorization: `Bearer ${user.accessToken}`,
                  },
               }
            )

            console.log('handleSave - Response from Server:', response.data)

            updateDebts(selectedMonth, response.data)
         }

         setSubmissionMessage(
            `Debts for ${months[selectedMonth]} saved successfully!`
         )
         if (onDebtSaved) onDebtSaved()
      } catch (error) {
         console.error('handleSave - Error:', error)
         handleError(error)
      }
   }

   const handleDelete = async () => {
      const debtData = debts[selectedMonth]
      if (!debtData || !debtData._id) return

      try {
         await axios.delete(
            `${process.env.REACT_APP_API_URL}debtRoutes/${debtData._id}`
         )
         updateDebts(selectedMonth, null)
         setSubmissionMessage(
            `Debts for ${months[selectedMonth]} deleted successfully!`
         )
         if (onDebtSaved) onDebtSaved()
      } catch (error) {
         console.error('handleDelete - Error:', error)
         handleError(error)
      }
   }

   const handleError = (error) => {
      if (!error.response) {
         setSubmissionMessage(
            'Network error: Please check your internet connection and try again.'
         )
      } else if (error.response.status === 401) {
         setSubmissionMessage(
            'Unauthorized: Please log in to perform this action.'
         )
      } else if (error.response.status === 404) {
         setSubmissionMessage('Error: The requested resource was not found.')
      } else if (error.response.status === 500) {
         setSubmissionMessage('Server error: Please try again later.')
      } else if (error.response.status === 400) {
         setSubmissionMessage('Validation error: Please check the input data.')
      } else {
         setSubmissionMessage('An error occurred: Please try again.')
      }
      console.error('handleError - Error Response:', error.response)
   }

   return (
      <>
         <h2>Manage Your Debts</h2>
         <MonthTabs
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
         />
         <DebtNavigation />
         {submissionMessage && <p>{submissionMessage}</p>}

         <IncomeDebtRatio selectedMonth={selectedMonth} />

         <Row style={{ display: 'flex' }}>
            <DebtDetails
               categories={categories}
               control={control}
               getValues={getValues}
            />
         </Row>
         <Button
            onClick={handleSave}
            size="small"
            style={{ marginTop: '20px' }}
         >
            {isEditing ? 'Atualizar' : 'Guardar'}
         </Button>
         {isEditing && (
            <Button onClick={handleDelete} size="small" className="ml-2">
               Apagar
            </Button>
         )}
      </>
   )
}

export default DebtsForm
