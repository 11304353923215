import React from 'react'
import { Dropdown, Button } from 'primereact'
import { Col } from 'reactstrap'
import '../DialogStyles.css'
import { usePlaylist } from '../../../../../../context/PlaylistContext'

const MainPlaylistSelector = () => {
   const {
      mainPlaylistId, // ID da playlist principal
      setMainPlaylistId, // Função para definir o ID localmente
      handleSetMainPlaylist, // Função para atualizar a playlist principal no servidor
      allPlaylists, // Lista de todas as playlists
   } = usePlaylist()

   // Gerar opções para o Dropdown
   const playlistOptions = allPlaylists.map((playlist) => ({
      label: playlist.name,
      value: playlist.id, // Usar o ID como valor
   }))

   return (
      <div>
         <Col>
            <Dropdown
               value={mainPlaylistId} // Valor atual do Dropdown
               options={playlistOptions} // Opções disponíveis
               onChange={(e) => setMainPlaylistId(e.value)} // Define o novo ID localmente
               placeholder="Selecionar playlist principal"
            />
            <Button
               label="Marcar como principal"
               size="small"
               className="btnAdicionar1"
               onClick={() => handleSetMainPlaylist(mainPlaylistId)} // Atualiza no servidor
               style={{ marginLeft: '10px' }}
            />
         </Col>
      </div>
   )
}

export default MainPlaylistSelector
