import React from 'react'
import { Dialog } from 'primereact/dialog'
import '../livros/BookModal.css' // Reutiliza o estilo existente
import { FaSpotify, FaApple, FaGoogle, FaPodcast, FaLink } from 'react-icons/fa' // Ícones das plataformas

const PodcastModal = ({ visible, onModalClose, podcast }) => {
   const customHeader = (
      <div className="book-modal-header">
         <h3>{podcast.name}</h3>
      </div>
   )

   // Função para determinar o ícone da plataforma
   const getPlatformIcon = (platform) => {
      switch (platform.toLowerCase()) {
         case 'spotify':
            return <FaSpotify className="platform-icon" />
         case 'apple':
         case 'apple podcasts':
            return <FaApple className="platform-icon" />
         case 'google':
         case 'google podcasts':
            return <FaGoogle className="platform-icon" />
         case 'podcast':
            return <FaPodcast className="platform-icon" />
         default:
            return <FaLink className="platform-icon" />
      }
   }

   return (
      <Dialog
         header={customHeader}
         visible={visible}
         className="book-modal"
         onHide={onModalClose}
         modal
         draggable={false}
         dismissableMask
      >
         <div className="book-modal-container">
            <div className="book-modal-content">
               {podcast.logo && (
                  <img
                     src={podcast.logo}
                     alt={podcast.name}
                     className="book-modal-image"
                  />
               )}
               <div className="book-modal-info">
                  <p>
                     <span className="book-modal-label">Descrição:</span>{' '}
                     {podcast.description || 'Não disponível'}
                  </p>
                  <p>
                     <span className="book-modal-label">Host:</span>{' '}
                     {podcast.host || 'Não disponível'}
                  </p>
                  <p>
                     <span className="book-modal-label">Categoria:</span>{' '}
                     {podcast.category || 'Não disponível'}
                  </p>
                  <p>
                     <span className="book-modal-label">Plataformas:</span>
                     {podcast.links && podcast.links.length > 0 ? (
                        <ul className="platform-links">
                           {podcast.links.map((link, index) => (
                              <li key={index} className="platform-link">
                                 <a
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="book-modal-link"
                                 >
                                    {getPlatformIcon(link.platform)}{' '}
                                    <span className="platform-name">
                                       {' '}
                                       {link.platform}
                                    </span>
                                 </a>
                              </li>
                           ))}
                        </ul>
                     ) : (
                        'Não disponível'
                     )}
                  </p>
               </div>
            </div>
         </div>
      </Dialog>
   )
}

export default PodcastModal
