import React from 'react'
import { Bar } from 'react-chartjs-2'

export default function BudgetVsActualChart({
   budgetData,
   actualData,
   labels,
}) {
   const chartData = {
      labels: labels,
      datasets: [
         {
            label: 'Orçamento',
            data: budgetData,
            backgroundColor: 'rgba(75, 192, 192, 0.5)', // Light green
         },
         {
            label: 'Real',
            data: actualData,
            backgroundColor: 'rgba(54, 162, 235, 0.5)', // Light blue
         },
      ],
   }

   const options = {
      indexAxis: 'y',
      scales: {
         x: {
            ticks: { color: 'white' },
         },
         y: {
            ticks: { color: 'white' },
         },
      },
      plugins: {
         legend: {
            labels: {
               color: 'white',
            },
         },
      },
   }

   return (
      <div>
         <h3>Orçamento vs Real</h3>
         <Bar data={chartData} options={options} />
      </div>
   )
}
