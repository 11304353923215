import React from 'react'
import { Dialog } from 'primereact/dialog'
import styles from './BrokerModal.module.css'

const BrokerModal = ({ visible, onModalClose, broker }) => {
   return (
      <Dialog
         header={broker.name}
         visible={visible}
         className={styles.darWith}
         onHide={onModalClose}
         modal
         dismissableMask
         draggable={false}
      >
         <div className={styles.paddedContainer2}>
            <img
               src={broker.logo}
               alt={broker.nome}
               className={styles.brokerImg}
            />
            <h2
               style={{
                  color: 'white',
                  fontSize: '1.5rem',
                  marginBottom: '10px',
               }}
            >
               {broker.nome}
            </h2>
            <table className={styles.table}>
               <tbody>
                  <tr>
                     <td>
                        <b>Descrição:</b>
                     </td>
                     <td>{broker.description}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Comissão transação:</b>
                     </td>
                     <td>{broker.comissaoTransacao}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Taxa câmbio:</b>
                     </td>
                     <td>{broker.taxaCambio}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Manutenção conta:</b>
                     </td>
                     <td>{broker.manutencao}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Depósito mínimo:</b>
                     </td>
                     <td>{broker.depositoMinimo}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Comissão depósito/levantamento:</b>
                     </td>
                     <td>{broker.comissaoDepositoLevantamento}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Juros não investido:</b>
                     </td>
                     <td>{broker.jurosGerados}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Garantia dos ativos:</b>
                     </td>
                     <td>{broker.garantiaAtivos}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Garantia não investido:</b>
                     </td>
                     <td>{broker.garantiaDinheiro}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Ações fracionadas:</b>
                     </td>
                     <td>{broker.acoesFracionadas ? 'Sim' : 'Não'}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Formulário W-8BEN:</b>
                     </td>
                     <td>{broker.formularioW8ben ? 'Sim' : 'Não'}</td>
                  </tr>
                  <tr>
                     <td>
                        <b>Regulamentação:</b>
                     </td>
                     <td>
                        <a
                           href={broker.regulamentacao}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Visitar
                        </a>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <b>Website:</b>
                     </td>
                     <td>
                        <a
                           href={broker.brokerLink}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Visitar
                        </a>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </Dialog>
   )
}

export default BrokerModal
