import React, { useEffect, useState } from 'react'
import axios from 'axios'
import css from './CommunitiesPage.module.css'
import { Helmet } from 'react-helmet'
import CommunityModal from '../conteudos/comunidades/CommunityModal'

const CommunitiesPage = () => {
   const [communities, setCommunities] = useState([])
   const [visible, setVisible] = useState(false)
   const [modalProps, setModalProps] = useState({})
   const [error, setError] = useState(null)

   useEffect(() => {
      fetchCommunities()
   }, [])

   const fetchCommunities = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}communityRoutes/`
         )
         console.log('Fetched Communities:', response.data)
         setCommunities(response.data)
      } catch (error) {
         console.error('Error fetching communities:', error)
         setError('Error fetching communities')
      }
   }

   const handleOpenModal = (community) => {
      setModalProps(community)
      setVisible(true)
   }

   if (error) {
      return <div className={css.error}>Error: {error}</div>
   }

   return (
      <div>
         <Helmet>
            <title>Comunidades</title>
            <meta
               name="description"
               content="Explore nossa coleção de comunidades financeiras."
            />
         </Helmet>

         <h2 className={css.titulos}>Comunidades</h2>
         <div className={css.communityContainer}>
            {communities.map((community) => (
               <div
                  key={community._id}
                  className={css.communityCard}
                  onClick={() => handleOpenModal(community)}
               >
                  <img
                     className={css.communityLogo}
                     src={community.logo}
                     alt={`Cover of ${community.name}`}
                  />
                  <div className={css.communityName}>{community.name}</div>
                  <div className={css.communityMembers}>
                     {community.members || '0'} Membros
                  </div>
               </div>
            ))}
         </div>

         {visible && (
            <CommunityModal
               visible={visible}
               onModalClose={() => setVisible(false)}
               community={modalProps}
            />
         )}
      </div>
   )
}

export default CommunitiesPage
