import React, { useEffect, useState } from 'react'
import axios from 'axios'
import css from './PodcastsPage.module.css'

import { Helmet } from 'react-helmet'
import PodcastModal from '../conteudos/podcasts/PodcastModal'

const PodcastsPage = () => {
   const [podcasts, setPodcasts] = useState([])
   const [visible, setVisible] = useState(false)
   const [modalProps, setModalProps] = useState({})
   const [error, setError] = useState(null)

   useEffect(() => {
      fetchPodcasts()
   }, [])

   const fetchPodcasts = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}podcastsRoutes/`
         )
         console.log('Fetched Podcasts:', response.data)
         setPodcasts(response.data)
      } catch (error) {
         console.error('Error fetching podcasts:', error)
         setError('Error fetching podcasts')
      }
   }

   const handleOpenModal = (podcast) => {
      setModalProps(podcast)
      setVisible(true)
   }

   if (error) {
      return <div className={css.error}>Error: {error}</div>
   }

   return (
      <div>
         <Helmet>
            <title>Podcasts</title>
            <meta
               name="description"
               content="Explore nossa coleção de podcasts sobre diversos tópicos financeiros."
            />
         </Helmet>

         <h2 className={css.titulos}>Podcasts</h2>
         <div className={css.podcastContainer}>
            {podcasts.map((podcast) => (
               <div
                  key={podcast._id}
                  className={css.podcastCard}
                  onClick={() => handleOpenModal(podcast)}
               >
                  <img
                     className={css.podcastLogo}
                     src={podcast.logo}
                     alt={`Cover of ${podcast.name}`}
                  />
                  <div className={css.podcastName}>{podcast.name}</div>
                  <div className={css.podcastHost}>{podcast.host}</div>
               </div>
            ))}
         </div>

         {visible && (
            <PodcastModal
               visible={visible}
               onModalClose={() => setVisible(false)}
               podcast={modalProps}
            />
         )}
      </div>
   )
}

export default PodcastsPage
